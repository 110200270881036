import React from 'react';

const PageHeading = ({heading}) => ( 
    <div className="sticky top-0 z-999">
    <div className="shadow py-1 bg-white w-full ">
        <h2 className='px-2 mr-4 py-2 my-2 font-bold text-lg border-l-4 border-violet-400'>
                {heading}
            </h2>
            </div>
        </div>
     );
export default PageHeading;