import React, {useEffect, useState} from 'react';
import env from 'react-dotenv';
import axios from 'axios';
import {Link} from 'react-router-dom';

import PageHeading from '../../components/headings/pageHeading';

const CometchatGroups = () => {

    const [groups,setGroups] =  useState(null);
    const [groupMeta, setGroupMeta] =  useState(null);

    const loadGroups = async()=>{
        console.log(env)
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/cometchat/groups`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
        setGroups(response.data.data)
        setGroupMeta(response.data.meta);
        console.log(response, groupMeta)

        }
        catch(e){
            console.log(e);
        }
    }

    useEffect(async()=>{
        loadGroups();
     },[])

    return ( <div>
        <PageHeading heading="Channels" />

        <div className="flex flex-wrap">
            {groups && groups.map(data=>(
                <Link to={`/cometchat/channel/${data.guid}`}><div className="group-card bg-yellow-400 shadow rounded px-2 py-2 mx-2 my-2 lg:px-5">
                    <div className="font-bold">{data.name}</div>
                    <div className="flex">
                        <div className="bg-green-600 px-1 my-1 mr-1 text-white rounded">{data.onlineMembersCount}</div>
                        <div className="bg-red-600 px-1 my-1 mr-1 text-white rounded">{data.membersCount}</div>
                    </div>
                </div></Link>
            ))}
        </div>
    </div> );
}
 
export default CometchatGroups;