import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Buffer} from 'buffer';

// import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from '../../components/headings/pageHeading';
import moment from 'moment';
import Modal from '../../components/modal/modal';


const Testimonials = ({roomData}) => {

    // const [rooms, setRooms] =  useState(null);
    const [formData, setForm] = useState({
        
    });
    // const { clubId } = useParams();
    const [stories, setStories] = useState([]);
    const [notificationData, setNotificationData] = useState(null);
    const [disableCreateButton, setDisableCreateButton] =  useState(false);
    const [deleteRoomIndex, setDeleteRoomIndex] = useState(null);
    
    // const [roomsList, setRoomsList] = useState([]);


    
    const clearAlert= ()=>{
        setNotificationData(null);
    }

    const parseCoupons =(value)=>{

        if(!formData.tags) {console.log("Error"); return;}

        let tagList =value?value.split(','):"";
        

        tagList = tagList.map(coupon=>coupon.trim());
        tagList = tagList.filter(coupons=> coupons);

        console.log(tagList)
        setForm(prev=>({
            ...prev,
            tagList,
            
        }))

        // setNotificationData({message: "Parsed data", type:'good'})
    }



    const loadStories = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/admin/stories`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setStories(response.data.stories)
            console.log( response.data);


        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(loadStories, [])

    /* eslint-disable  no-param-reassign */
    const onImageChange = (event) => {

        const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {

    // Initiate the JavaScript Image object.
    const image = new Image();

    // Set the Base64 string return from FileReader as source.
    image.src = e.target.result;

    // Validate the File Height and Width.
    image.onload = function () {
        const {height, width} = this;
        console.log(height, width)
        
         if (event.target.files && event.target.files[0]) {
                setForm(prev => ({
                    ...prev, 
                imageUrl: URL.createObjectURL(event.target.files[0])
              }));
            }
        


    };
    };

       }
       const onImageChange2 = (event) => {

        console.log("data from 2", event)

        const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {

    // Initiate the JavaScript Image object.
    const image = new Image();

    // Set the Base64 string return from FileReader as source.
    image.src = e.target.result;

    // Validate the File Height and Width.
    image.onload = function () {
        const {height, width} = this;
        console.log(height, width)
        
         if (event.target.files && event.target.files[0]) {
                setForm(prev => ({
                    ...prev, 
                imageUrl2: URL.createObjectURL(event.target.files[0])
              }));
            }
        


    };
    };

       }

       useEffect(()=>{
        if(roomData)
        setForm({...roomData, isUpdate: true})
    },[roomData])

    //    const addRooms= ()=>{
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp.push({})
    //         return temp;
    //     })
    //    }
    
    // const roomsInputChange = (event, index) =>{
    //     const {name, value, type} = event.target;

    // if(type==='radio'){
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp[index][name.replace(index.toString(),"")] = value

    //         return temp;
    //     })
    //     return;
    // }
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp[index][name] = value

    //         return temp;
    //     })
    
    //     console.log(rooms);
    // }

    // useEffect(async()=>{
    //     loadStories()
    // }, [])

    const formChanges = (e) => {
        const {name, value, files} = e.target;

        setForm(prev => ({
            ...prev,
            [name]: value
        }))
        if(name==='image' || name==='image2'){
            setForm(prev => ({
                ...prev,
                [name]: files[0]
            }))
            console.log(name)
            if(name=='image2')
            {onImageChange2(e); console.log(name)}
            else
            {onImageChange(e)}
        }

        if(name==='tags')parseCoupons(value)

        console.log(e, name, value, formData)
    }
/* eslint-disable prefer-const */ 
    // const deleteQuestion = async(index)=>{
    //     console.log(index)
    //     setRooms(prev=>{
    //         let temp = [...prev];
    //         temp.splice(index, 1)
    //         // temp = temp.filter((data, indexTemp)=>{
    //         //     console.log(indexTemp, index, index!==indexTemp)
    //         //     return index!==indexTemp
    //         // })
    //         return temp
    //     })

          
    // }


const onSubmit =async ()=>{

    try{
        setDisableCreateButton(true);
        const reqFormData = new FormData();
        
        if(!formData){
            setNotificationData({message: 'Enter some data', type:'bad'}); setDisableCreateButton(false); return;
        }
        /* eslint-disable no-unneeded-ternary */
        const {text, image, image2} = formData;
        if(!text){
            setNotificationData({message: 'Text  is required', type:'bad'}); setDisableCreateButton(false); return;
        }
        if(!image){
            setNotificationData({message: 'Image is required', type:'bad'}); setDisableCreateButton(false); return;
        }
      
        if(!image2){
            setNotificationData({message: 'Image 2 is required', type:'bad'}); setDisableCreateButton(false); return;
        }
      


        reqFormData.append('text', text);
        reqFormData.append('upload', image);
        reqFormData.append('upload', image2);
        
        

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)
        const url = `${process.env.REACT_APP_BASE_URL}/admin/placement_stories`;
        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Rooms created', type:'good'})
        await loadStories()
    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
        }
        setDisableCreateButton(false);
        // setForm({})
    }

const deleteRoom =async ()=>{

        try{
         
            const token = localStorage.getItem('authKey');
    
            
            const url = `${process.env.REACT_APP_BASE_URL}/admin/stories/${stories[deleteRoomIndex]._id}`;
            const response = await axios.delete(url, {headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        } )
    
    
        if(response.status===200){
    
            setNotificationData({message: 'Room Delted', type:'good'})
            await loadStories()
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    
                    setNotificationData({message: error.response.data.error, type:'bad'})
                }
                console.log(error)
            }
            setDisableCreateButton(false);
            setDeleteRoomIndex(null)
        }


    return ( 

        <div className="Testimonialss w-full">

            <PageHeading heading="Create Rooms" />
        
            <div className="flex">
            
            <div className="flex flex-col">
                    <label htmlFor="image" className='px-4 py-2'> User Image</label>
                    <div className="px-4 py-1"><input type="file" name='image' className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Enter Clubs name" accept="image/*" onChange={formChanges} /></div>
                    </div> 

                    {formData && formData.imageUrl && <img className="mt-2 h-24 px-5" src={formData.imageUrl} alt='Clubs name' />}

            </div>

            <div className="flex">
            
            <div className="flex flex-col">
                    <label htmlFor="image" className='px-4 py-2'> Company Image</label>
                    <div className="px-4 py-1"><input type="file" name='image2' className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Enter Clubs name" accept="image/*" onChange={formChanges} /></div>
                    </div> 

                    {formData && formData.imageUrl2 && <img className="mt-2 h-24 px-5" src={formData.imageUrl2} alt='Clubs name' />}

            </div>

            {/* <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="startsAt" className='px-4 py-2'>Start time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='startTime' value={formData.startTime} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>

            
            <div className="flex flex-col">
                    <label htmlFor="endsAt" className='px-4 py-2'>End Time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='endTime' value={formData.endTime} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>

            <div className="flex flex-col">
                    <label htmlFor="maximumParticipants" className='px-4 py-2'>Maximum participents (opt)</label>
                    <div className="px-4 py-1"><input type='number' name='seatsAvailable' value={formData.seatsAvailable} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Maximum participents" onChange={formChanges} /></div>
            </div>
            
            </div> */}

            {/* <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="createdBy" className='px-4 py-2'>Host mobile number</label>
                    <div className="px-4 py-1"><input type="text" name='hostNumber' value={formData.hostNumber} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="UserId" onChange={formChanges} /></div>
            </div>
            <div className="flex flex-col">
                    <label htmlFor="clubId" className='px-4 py-2'>Link(opt)</label>
                    <div className="px-4 py-1"><input type="text" name='link' value={formData.link} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Google meet / zoom link" onChange={formChanges} /></div>
            </div>
            {/* <div className="flex flex-col">
            <label htmlFor="isOpen" className='px-4 py-2  text-lg'> Open / Closed Room </label>
            <div className="px-4 ">
                <select name='isOpen' value={formData.isOpen} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={formChanges}>
                <option  disabled selected >Select type of Room</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                </select>
                </div>
            </div> */}
            {/* <div className="flex flex-col">
                    <label htmlFor="duration" className='px-4 py-2'>Description</label>
                    <div className="px-4 py-1"><input type="text" name='description' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Duration in Minutes" onChange={formChanges} /></div>
            </div> 
            </div> */}

            <div className="flex w-full">

            <div className="flex flex-col">
            <label htmlFor="description" className='px-4 py-2  text-lg'> Testimonial </label>
            <div className="px-4 "><textarea name='text' value={formData.text} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="description" onChange={formChanges} /></div>
            </div>
{/* 
            <div className="flex flex-col">
            <label htmlFor="tags" className='px-4 py-2  text-lg'> skills (Seperate by comma) </label>
            <div className="px-4 "><textarea name='skills' value={formData.skills} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter skills seperated by comma" onChange={formChanges} /></div>
            </div>
            <div className="flex flex-col">
            <label htmlFor="tags" className='px-4 py-2  text-lg'> Tags (Seperate by comma)</label>
            <div className="px-4 "><textarea name='tags' value={formData.tags} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter tags seperated by comma" onChange={formChanges} /></div>
            </div> */}


            </div>

            <div>

            <div className="flex pb-5">
                {/* <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2" onClick={addRooms} >Add Question</button> </div> */}

                {!formData.isUpdate && <div className="px-4  mb-0 mt-auto ml-auto mr-0"><button type="submit" className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 " onClick={onSubmit} disabled={disableCreateButton}>Create story</button> </div>}
                
            </div>

            <div>
                <table className=' bg-slate-400 px-4 mx-4 overflow-scroll'>
                    <thead className=' bg-slate-800 text-white'>
                        <th>Story</th>
                        <th>User image</th>
                        <th>Compay Image</th>
                        <th>Delete</th>
                       
                    </thead>
                
            <tbody>
            {stories.map((data, index)=>
                        <tr className={`${ index%2==0?' bg-slate-300 ': " bg-slate-200 "} overflow-scroll`}>
                            <td className='py-2'>{data.story}</td>
                            
                            <td className='px-2 w-24'><img src={data.userImage} /></td>
                            <td className='px-2 w-24'><img src={data.companyImage} /></td>

                            <td> <button className='bg-red-500 text-white px-2 py-2 rounded shadow' onClick={()=>setDeleteRoomIndex(index)}> Delete</button></td>
                        </tr>                    
                    ) }
                    </tbody>
                    </table>
            </div>
               
            </div>
            {deleteRoomIndex!=null && <Modal description={`Do you want to delete  ${stories[deleteRoomIndex].story}?`} onCancel={()=>setDeleteRoomIndex(null)} onSuccess={deleteRoom} />}
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
        </div>
     );
}
 
export default Testimonials;