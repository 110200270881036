import React, { useEffect, useState } from "react";
import PageHeading from "../../components/headings/pageHeading";
import axios from "axios";
import { dateCustom } from "../../service/date";
import BanModal from "./modal/banModal";
import DeleteModal from "./modal/deletePostModal";
import PostModal from "./modal/postDetailedView";
import BoostUpvote from "./modal/boostUpvote";
import Multiselect from "multiselect-react-dropdown";
import { getAvatar } from "./avatar";

const BeepsyPost = () => {
  const [postList, setPostList] = useState({ posts: [], next: false });
  const [collegeList, setCollegeList] = useState({});
  const [showBan, setShowBan] = useState(null);
  const [deletePost, setDeletePost] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showBoostUpvote, setShowBoostUpvote] = useState(false);

  const [selectedColleges, setSelectedColleges] = useState([]);
  const [colleges, setColleges] = useState([]);

  const getallColleges = async () => {
    const url = `${process.env.REACT_APP_BASE_URL22}/admin/colleges`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.data) {
      setColleges(response.data.accounts);
    }
  };

  const clearBan = () => {
    setShowBan(null);
  };

  const LoadFeed = async (skip) => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/feed`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.post(
      url,
      {
        colleges: selectedColleges,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        params: {
          skip: skip != 0 ? postList.posts.length : 0,
        },
      }
    );

    if (response.data) {
      setPostList((prev) => {
        return {
          ...response.data.userList,
          posts:
            skip != 0
              ? [...prev.posts, ...response.data.userList.posts]
              : [...response.data.userList.posts],
        };
      });
    }
  };

  const LoadFeedRefresh = async () => {
    const url = `${process.env.REACT_APP_BASE_URL22}/admin/feed`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.post(
      url,
      {
        colleges: selectedColleges,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: postList.posts.length,
        },
      }
    );

    if (response.data) {
      setPostList((prev) => {
        return {
          ...response.data.userList,
          posts: [...response.data.userList.posts],
        };
      });
    }
  };

  useEffect(LoadFeed, []);

  const onSelect = (event) => {
    setSelectedColleges(getIds(event));
  };

  const onRemove = (event) => {
    setSelectedColleges(getIds(event));
  };

  const getIds = (events) => {
    return events.map((clg) => clg._id);
  };

  useEffect(getallColleges, []);
  useEffect(() => LoadFeed(0), [selectedColleges]);

  return (
    <div id="beepsy-post">
      <PageHeading heading={"Post"} />

      <div className="w-96 bg-white m-4">
        <Multiselect
          label="Select Colleges"
          options={colleges} // Options to display in the dropdown
          onSelect={onSelect} // Function will trigger on select event
          onRemove={onRemove} // Function will trigger on remove event
          displayValue="collegeName" // Property name to display in the dropdown options
        />
      </div>

      {postList.posts.map((postData, index) => (
        <div className="post w-full text-left" key={postData._id}>
          <div className="postacrd p-4 flex bg-white mx-4 my-2">
            <div className="image w-1/12">
              <img
                src={postData.file}
                alt=""
                onClick={() => {
                  setSelectedPost(postData);
                }}
              />
            </div>

            <div className="body flex flex-col w-11/12 mx-4">
              <div className="head flex my-1 items-center	">
                <div className="user flex w-8/12">
                  <div className="image w-10 h-10 place-self-center rounded-full ">
                    <img
                      className="rounded-full mr-2"
                      src={getAvatar(postData?.userInfo?.profileInfo.avatar)}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex">
                      {" "}
                      <div className="name">{postData.userInfo?.profileInfo?.name}</div>
                      {postData.userInfo?.blockedTill && (
                        <div className="name bg-red-500 rounded-full mx-2 text-xs px-2 text-white self-center">
                          {new Date(postData.userInfo?.blockedTill).getFullYear() > 2024
                            ? "Ban"
                            : dateCustom(postData.userInfo?.blockedTill, "DD MMM hh:mm")}
                        </div>
                      )}
                    </div>
                    <div className="clg">{postData.userInfo?.campusInfo?.collegeAlias}</div>
                  </div>
                </div>

                <div className="time w-2/12">{dateCustom(postData.timeStamp, "DD MMM hh:mm")}</div>

                <div className="activity w-2/12 flex justify-between	">
                  <button
                    className="delete basis-1/3 text-green-600"
                    onClick={() => {
                      setSelectedPost(postData);
                    }}
                  >
                    View
                  </button>
                  <button
                    className="ban basis-1/3 text-red-600"
                    onClick={() => setShowBan(postData.userInfo)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>
                  </button>
                  <button
                    className="delete basis-1/3 text-red-600"
                    onClick={() => setDeletePost(postData)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>

                  <div className="count ml-auto mr-0 basis-1/3">#{index + 1}</div>
                </div>
              </div>

              <div className="flex">
                <div
                  className="description w-10/12 mx-4 whitespace-pre-wrap"
                  onClick={() => {
                    setSelectedPost(postData);
                  }}
                >
                  <div className="text-xs bg-violet-400 inline p-1 rounded-full text-white">
                    {postData.type}
                  </div>
                  <div className="italic font-semibold">{postData.title}</div>
                  <div>{postData.type != "Poll" && postData.body}</div>
                  <div>
                    {postData.type == "Poll" && (
                      <div>
                        <div className="question flex">
                          {" "}
                          <div className="font-bold">Q : </div> <div>{postData.body.question}</div>
                        </div>
                        <div className="question">
                          <div>
                            {postData.body.answer.map((answer, index) => (
                              <div className="flex">
                                <div className="count">{index + 1} : </div>
                                <div>{answer}</div>
                                <div className="mx-2  px-1 rounded-full bg-yellow-400">
                                  {postData.pollCount[`Option${index}`]}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="votes comments flex flex-col w-2/12">
                  <button className="upvote flex" onClick={() => setShowBoostUpvote(postData._id)}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
                      />
                    </svg>
                    <div className="Upvote">Upvote</div>
                    <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                      {postData.upvote_count}
                    </div>
                  </button>
                  <div className="upvote flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                      />
                    </svg>
                    <div className="Downvote">Downvote</div>
                    <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                      {postData.downvote_count}
                    </div>
                  </div>
                  <div
                    className="upvote flex "
                    onClick={() => {
                      setSelectedPost(postData);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                      />
                    </svg>

                    <div className="">Comment</div>
                    <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                      {postData.comment_count}
                    </div>
                  </div>
                  <div className=" flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>

                    <div className="">Report</div>
                    <div>
                      <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                        {postData.reportedCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {postList.next && (
        <div className="mx-auto text-center">
          <button
            className=" mx-auto bg-violet-500 text-white shadow p-2 rounded inline-block"
            onClick={LoadFeed}
          >
            Load more
          </button>
        </div>
      )}

      {showBan && (
        <BanModal clearBan={clearBan} userInfo={showBan} LoadFeedRefresh={LoadFeedRefresh} />
      )}
      {deletePost && (
        <DeleteModal
          clear={() => setDeletePost(null)}
          postData={deletePost}
          accept={() => {}}
          LoadFeedRefresh={LoadFeedRefresh}
        />
      )}
      {selectedPost && (
        <PostModal
          clear={() => setSelectedPost(null)}
          postData={selectedPost}
          accept={() => {}}
          LoadFeedRefresh={LoadFeedRefresh}
        />
      )}
      {showBoostUpvote && (
        <BoostUpvote
          clear={() => setShowBoostUpvote(null)}
          postId={showBoostUpvote}
          LoadFeedRefresh={LoadFeedRefresh}
        />
      )}
    </div>
  );
};

export default BeepsyPost;
