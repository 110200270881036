import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';
import InputModal from '../../components/modal/inputModal';


const CollegeRequest = () => {
    /* eslint-disable no-underscore-dangle */

    const [requestList, setRequestList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [hasNext, setHasnext] = useState(false);
    const [showAddStream, setShowAddStream] =  useState(false);
    const [streamInput, setStreamInput] =  useState(""); // textbox if the college id is not there
    const [selectedStream, setSelectedStream] = useState(null);
    const [selectedCollegeId, setSelectedCollegeId] = useState(null);
    const [selectedCollege, setSelectedCollege] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const [declineData, setDeclineData] =  useState(null);
    const [declineReason, setDeclineReason] =  useState(null);

    const [collegeSearchList, setCollegeSearchList] = useState([]);
    const [collegeSearchTerm, setCollegeSearchTerm] = useState("");
    // const [selectedCollege, setSelectedCollege] = useState(null);

    const clearAlert= ()=>{
        setAlertData(null);
    }

    const loadDeclineData = ()=>{
        setDeclineData(selectedRequest);
    }

    const clearDeclineData =  ()=>{
        setDeclineData(null)
        setDeclineReason("")
    }

    const searchCollege = async(searchTerm, type='')=>{

        setCollegeSearchTerm(searchTerm);

        try{
        
        const url = `${process.env.REACT_APP_BASE_URL}/admin-users/colleges`;

        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`,   
        },
        params:{
            name:searchTerm,
            limit: 200,
            skip: type==='more'?setCollegeSearchList.length:0
        }   
    } )

    if(response.status===200){
        setAlertData({message: 'College list updated', type:'good'});
        if(type==='more')
        setCollegeSearchList(prev=>[...prev, ...response.data.suggestions]);
        else{
        setCollegeSearchList(response.data.colleges);
        }
    }
        }catch(error){
            console.log(error)
            if(error.name ==='AxiosError'){
                
                if(error.response.data)
                setAlertData({message: error.response.data.error, type:'bad'})
                else setAlertData({message:'Unable to get colleges', type:'bad'})
            }
            else
            setAlertData({message:'Unable to get colleges', type:'bad'})
        }
    

    }


    const loadRequest = async(index, limit)=>{

        setSelectedIndex(null)
        setShowAddStream(false);
        setStreamInput("")
        setSelectedStream(null)
        setSelectedCollegeId(null)
        setSelectedCollege(null)
        setSelectedRequest(null)
        setSelectedRequestId(null)
        setDeclineData(null)
        setDeclineReason(null)
    

        const url = `${process.env.REACT_APP_BASE_URL}/requests/pending`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`
        },
        params:{
            index, limit
        }
    })

        console.log(response);
    
        
        if(response && response.data){
            
            if(index ===0)
            setRequestList(response.data.requests);
            else
            setRequestList(prev => [...prev, ...response.data.requests]);

            setHasnext(response.data.hasNext);
        }
    }

    const LoadCollegeData = async(collegeId)=>{

        const url = `${process.env.REACT_APP_BASE_URL}/requests/college/${collegeId}`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`
        }})

        console.log(response);

        if(response && response.data){
        setSelectedCollege(response.data.clgData);
        }
    }

    useEffect(()=>{
        loadRequest(0, 20)
    },[])

    const loadMore =  ()=>{
        const index = requestList.length;
        loadRequest(index, 20)
    }

    const viewAddStream = ()=>{
        setShowAddStream(true);
    }

    const onResonChange = (e)=>{
        setDeclineReason(e.target.value);
    }

    const selectRequest = (index)=>{ 

        setSelectedIndex(null)
        setShowAddStream(false);
        setStreamInput("")
        setSelectedStream(null)
        setSelectedCollegeId(null)
        setSelectedCollege(null)
        setSelectedRequest(null)
        setSelectedRequestId(null)
        setCollegeSearchList([])
        setCollegeSearchTerm("")
        setDeclineData(null)
        setDeclineReason(null)
    


        const request  = requestList[index];
        setSelectedCollegeId( requestList[index]._id);
        setSelectedIndex(index);

        if(request.type==='College Addition'){
            searchCollege(request.alias)
        }

        setSelectedRequest(request);
        setSelectedRequestId(request._id);
        console.log(selectedRequestId);

        console.log(requestList[index], selectedCollegeId);
        if(request.type==="Course Addition")
            LoadCollegeData(requestList[index].collegeId);
    }

    const submitForStream = async()=>{

        try{
        const {_id: requestId, raisedBy, collegeId} = selectedRequest
        
        const collegeIdSelected = collegeId??selectedCollege._id;

        const url = `${process.env.REACT_APP_BASE_URL}/requests/streams/${raisedBy}/${requestId}`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.post(url, {
            collegeId: collegeIdSelected, 
            stream: selectedStream
        }, {headers: {
            Authorization: `Bearer ${token}`
        }})

        console.log(response);


        if(response.status===200){
            setAlertData({message: 'Course Added for user', type:'good'});      
            // LoadData()
            // const preSelectedIndex = selectedIndex;
            const limit = requestList.length;
            await loadRequest(0, limit);
            // selectRequest(preSelectedIndex)
        }
 

        }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Error While adding the request', type:'bad'})
                }
                else
                setAlertData({message:'Error While adding the request', type:'bad'})
            }
    }

    const declineRequest = async()=>{
        try{
            const {_id: requestId, raisedBy} = selectedRequest
    
            const url = `${process.env.REACT_APP_BASE_URL}/requests/decline/${raisedBy}/${requestId}`;
        
            const token = localStorage.getItem('authKey');
    
            const response = await axios.patch(url, {
                message: declineReason
            }, {headers: {
                Authorization: `Bearer ${token}`
            }})
    
            console.log(response);
    
    
            if(response.status===200){
                setAlertData({message: 'Request Declined', type:'good'});      
                clearAlert();
                const limit = requestList.length;


                await loadRequest(0, limit);
            }
     
    
            }catch(error){
                    console.log(error)
                    if(error.name ==='AxiosError'){
                        
                        if(error.response.data)
                        setAlertData({message: error.response.data.error, type:'bad'})
                        else setAlertData({message:'Error While decline the request', type:'bad'})
                    }
                    else
                    setAlertData({message:'Error While decline the request', type:'bad'})
                }

    }

    const selectctCollegeOnCollegeRequest = async(college)=>{

        // selectedCollege(null)
        await LoadCollegeData(college._id)
        // setSelectedCollege(college)
    }

    return ( 
        <div className="wrap w-full">
            <PageHeading heading="Requests" />

            
            <div className="flex flex-cols mt-4 h-fit">
            
            {/* Left side or full screen view */}
            <div className={`grid h-100 overflow-y-scroll gap-4  ${((selectedIndex || selectedIndex===0) &&requestList.length >selectedIndex)?"w-1/2 grid-cols-2 pr-2 border-r-2 border-black":"w-full grid-cols-4"}`}>
                {requestList && requestList.map((data, index)=><div className={`${index===selectedIndex?'bg-indigo-300':'bg-yellow-400'} px-2 py-2`}>
                    <div className="heading">
                    <div className="flex border-b-1 border-black" style={{borderBottomWidth :'1px'}}>
                            <img src={data.userInfo && data.userInfo.avatar} alt="Avatar" className="h-8 mr-2 rounded-full my-auto" />
                                <div className="flex flex-col w-11/12">
                                    <div className="font-bold">{data.userInfo&& data.userInfo && data.userInfo.name}</div>
                                    <div className="text-xs truncate w-11/12" title={data.raisedBy}>{data.raisedBy}</div>
                                </div>
                            </div>
                    </div>
                    <div className="">
                        
                        <div className="font-bold">
                            {data.type}
                        </div>
                        <div className="">
                            {data.collegeName}
                        </div>
                        <div className="">
                            {data.stream}
                        </div>
                    </div>
                    <button className="ml-auto mr-auto  bg-black rounded shadow px-1 text-white my-1" type="button" onClick={()=>selectRequest(index)}>Sel</button>
                </div>)}

                {hasNext && <button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={loadMore}>LoadMore</button>}
            </div>
            {/*  Right side college and course request handling */}
            {((selectedIndex || selectedIndex===0) &&requestList.length >selectedIndex)  && <div className='mx-2 w-1/2'>
                
                    
                    {requestList[selectedIndex].type==="Course Addition" && selectedCollege && <div className="mx-2">
                        
                        <div className="flex">
                        <div className='bg-indigo-300 px-2 py-2 my-4 rounded inline'>Course Addition</div>
                        <div className="px-2 py-2  mr-0 ml-auto"> <button className="bg-black rounded shadow px-1 text-white" type="button" onClick={loadDeclineData}>Decline Request</button></div>
                        </div>

                        <div className="bg-slate-100 px-1 py-1">

                        <div className="flex flex-col w-11/12">
                            <div className="font-bold">{selectedRequest.userInfo&& selectedRequest.userInfo && selectedRequest.userInfo.name}</div>
                            {/* <div className="text-xs truncate w-11/12" title={selectedRequest.raisedBy}>{selectedRequest.raisedBy}</div> */}
                        </div>
                        <div className="">
                            {selectedRequest.collegeName}
                        </div>
                        <div className="">
                            {selectedRequest.stream}
                        </div>

                        </div>

                        
                        <div>
                            <div className="flex flex-cols mt-2">
                                <div className='text-lg font-bold'> {selectedCollege.collegeName} -{selectedCollege.alias}</div>
                                
                            </div>

                            { (!selectedStream) &&
                            <>
                            <div className="flex flex-col mx-2 my-1">

                                { selectedCollege.streams.map((streamName) =>
                                    <div className="px-2 py-1 hover:bg-slate-200 flex">
                                        <div>{streamName}</div>
                                        <button className=" bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={()=>setSelectedStream(streamName)}>Select</button>
                                    </div>
                                ) }

                            </div>

                            <div className="">

                            {(!showAddStream) &&  
                                
                                <button className="bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={viewAddStream}>Add Stream</button>
                                }

                                {showAddStream && 
                                    <div className="flex px-4 py-2 flex">
                                        <div>
                                        <input type="text" name='stream' value={streamInput} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Stream Name" onChange={e=>setStreamInput(e.target.value)} />
                                        </div>
                                        <button className=" bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={()=>setSelectedStream(streamInput)}>Add</button>
                                    </div>
                                }
                            </div></>
                            }
                            { (selectedStream) &&
                            <div className="flex flex-col">
                            <div className=""> Selected course: <span className="font-bold">{selectedStream}</span> </div>
                            <button className=" bg-yellow-400 rounded shadow p-2  mr-2 ml-auto" type="button" onClick={()=>submitForStream()}>Submit</button>
                            </div>}
                            
                        </div>
                        </div>}

                        
                    {requestList[selectedIndex].type==="College Addition" && <div className='mx-2 w-full'>
                    <div className="flex">
                        <div className='bg-indigo-300 px-2 py-2 my-4 rounded inline'>College Addition</div>
                        <div className="px-2 py-2  mr-0 ml-auto"> <button className="bg-black rounded shadow px-1 text-white" type="button" onClick={loadDeclineData}>Decline Request</button></div>
                        </div>

                        <div className="bg-slate-100 w-full px-2">
                        <div className="flex flex-col w-11/12">
                            <div className="">{selectedRequest.userInfo&& selectedRequest.userInfo && selectedRequest.userInfo.name}</div>
                            {/* <div className="text-xs truncate w-11/12" title={selectedRequest.raisedBy}>{selectedRequest.raisedBy}</div> */}
                        </div>
                        <div className="font-bold">
                            {`${selectedRequest.collegeName} - ${selectedRequest.alias}`}
                        </div>
                        <div className="">
                            {selectedRequest.stream}
                        </div>
                        </div>

                        <div className="my-2">
                        {!selectedCollege && 
                        
                        <div className=" flex w-full">

                        <input type="text" name='stream' value={collegeSearchTerm} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Stream Name" onChange={e=>searchCollege(e.target.value)} />
                        <div className='mr-0 ml-auto py-2'>
                        <Link to="/college/add" className='p-2 bg-gray-200 mx-2 hover:bg-gray-300' target='new'>Create new</Link>
                        </div>
                        </div>
                        }
                        

                            
                        { !selectedCollege && collegeSearchList && collegeSearchList.map(data=>
                            
                            <div className="flex my-1 hover:bg-slate-100 mx-1">
                            <div className="px-2 py-2 ">
                                {`${data.collegeName}-${data.alias}`}
                            </div>

                            <button className="bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={()=>selectctCollegeOnCollegeRequest(data)}>Sel</button>
                            </div>
                            
                            )}


                        { selectedCollege && 
                        <div>
                            <div className="font-bold">
                                Selected college details
                            </div>
                            <div className="flex flex-cols mt-2">
                                <div className='text-lg font-bold'> {selectedCollege.collegeName} -{selectedCollege.alias}</div>
                                
                            </div>

                            { (!selectedStream) &&
                            <>
                            <div className="flex flex-col mx-2 my-1">

                                { selectedCollege.streams.map((streamName) =>
                                    <div className="px-2 py-1 hover:bg-slate-200 flex">
                                        <div>{streamName}</div>
                                        <button className=" bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={()=>setSelectedStream(streamName)}>Select</button>
                                    </div>
                                ) }

                            </div>

                            <div className="">

                            {(!showAddStream) &&  
                                
                                <button className="bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={viewAddStream}>Add Stream</button>
                                }

                                {showAddStream && 
                                    <div className="flex px-4 py-2 flex">
                                        <div>
                                        <input type="text" name='stream' value={streamInput} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Stream Name" onChange={e=>setStreamInput(e.target.value)} />
                                        </div>
                                        <button className=" bg-black rounded shadow px-1 text-white mr-0 ml-auto" type="button" onClick={()=>setSelectedStream(streamInput)}>Add</button>
                                    </div>
                                }
                            </div></>
                            }
                            { (selectedStream) &&
                            <div className="flex flex-col">
                            <div className=""> Selected course: <span className="font-bold">{selectedStream}</span> </div>
                            <button className=" bg-yellow-400 rounded shadow p-2  mr-2 ml-auto" type="button" onClick={()=>submitForStream()}>Submit</button>
                            </div>}
                            
                        </div>
                    }


                        </div>
                        



                    </div>}

                 </div> }

                 {declineData && <InputModal description={`Do you want to decline ${declineData.userInfo.name} request?`} onSuccess={declineRequest} onCancel={clearDeclineData} onReason={onResonChange} /> }
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}

            </div>
        </div>
     );
}
 
export default CollegeRequest;