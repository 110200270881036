import React , {useState, useEffect} from 'react';
import {Buffer} from 'buffer';
import axios from 'axios';

import AlertWithClose from '../../components/alert/alertWithClose';
import Modal from '../../components/modal/modal';
import PageHeading from '../../components/headings/pageHeading';

const CreateRewards = () => {
    
    const [rewardData, setRewardData] = useState({})
    const [notificationData, setNotificationData] = useState(null)
    const [rewardList, setRewardList] =  useState(null);
    const [deleteData, setDeleteData] =  useState(null);
    

    const onImageChange = (event, name) => {
        if (event.target.files && event.target.files[0]) {
            setRewardData(prev => ({
                ...prev, 
            [`${name}Url`]: URL.createObjectURL(event.target.files[0])
          }));
        }
       }

       const parseCoupons =(value)=>{

        if(!rewardData.couponListRaw) {console.log("Error"); return;}

        let couponList =value?value.split(','):"";
        

        couponList = couponList.map(coupon=>coupon.trim());
        couponList = couponList.filter(coupons=> coupons);

        console.log(couponList)
        setRewardData(prev=>({
            ...prev,
            couponList,
            totalCount: couponList.length
        }))

        setNotificationData({message: "Parsed data", type:'good'})
    }

       const onFormDataChange=(e)=>{
        const {name, value, type, files} = e.target;

        if(type==='file'){
            setRewardData(prev=>({
                ...prev,
                [name]:files[0]
            }))    
            onImageChange(e, name);
        }else
        setRewardData(prev=>({
            ...prev,
            [name]:value
        }))

        if(name==='couponListRaw')parseCoupons(value)

        console.log(rewardData,e);
    }

    const clearAlert= ()=>{
        setNotificationData(null);
    }
    
    const loadReward = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/reward`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setRewardList(response.data.rewards )
            console.log(rewardList);


        }
        catch (e) {
            console.log(e);
        }
    }

    const submitHandler = async (e)=>{
        // onClick={submitHandler}
        e.preventDefault();
        console.log(rewardData, e)

        try{
        const url = `${process.env.REACT_APP_BASE_URL}/admin-users/reward`;
        const reqFormData = new FormData();
        
        /* eslint-disable no-unneeded-ternary */
        const coupListTemp = rewardData.couponList? rewardData.couponList: []
        const couponListBase64 = Buffer.from(JSON.stringify(coupListTemp)).toString('base64');
        let images = [rewardData.image1, rewardData.image2, rewardData.image3]
        images = images.filter(image=>image);

        console.log(images, "Images")

        reqFormData.append('name', rewardData.name);
        reqFormData.append('brandId', rewardData.brandId);
        
        reqFormData.append('type', rewardData.type);
        reqFormData.append('isUnlimited', rewardData.isUnlimited==='unlimited'? true: false);
        reqFormData.append('isUnique', rewardData.isUnique==='unique'? true: false);
        reqFormData.append('totalCount', rewardData.totalCount);
        reqFormData.append('couponList', couponListBase64);
        reqFormData.append('link', rewardData.link);
        reqFormData.append('image1', rewardData.image1);
        reqFormData.append('image2',  rewardData.image2);
        reqFormData.append('image3',  rewardData.image3);

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)

        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Reward created', type:'good'})
        loadReward()
    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
        }

    }

    

    useEffect(async()=>{
        await loadReward()
    },[])

    
    /* eslint-disable no-underscore-dangle */
    const loadDeleteData = (index)=>{
        setDeleteData(rewardList[index]);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
    }

    const changeRewardState =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/reward/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.post(url, {state:!deleteData.available},{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setNotificationData({message: 'Reward State changed', type:'good'});

            loadReward()
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    
                    setNotificationData({message: error.response.data.error, type:'bad'})
                }
                else
                setNotificationData({message:'Unable to change reward state', type:'bad'})
            }
        
            clearDeleteData();

    }

    // const deleteReward =async ()=>{

    //     try{
    //         const url = `${process.env.REACT_APP_BASE_URL}/admin-users/reward/${deleteData._id}`;
    
    //         const token = localStorage.getItem('authKey');
    
    //         const response = await axios.delete(url, {headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     } )
    
    //     if(response.status===200){
    //         setNotificationData({message: 'Brand deleted', type:'good'});

    //         loadReward()
    //     }
    //         }catch(error){
    //             if(error.name ==='AxiosError'){
                    
    //                 setNotificationData({message: error.response.data.error, type:'bad'})
    //             }
    //             else
    //             setNotificationData({message:'Unable to delete brand', type:'bad'})
    //         }
        
    //         clearDeleteData();

    // }


    return ( 
        <div className="coupons w-full relative">
            <PageHeading heading='Reward' />
            <div>

            {/* Row 1 */}
            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Reward Name </label>
            <div className="px-4 "><input type="text" name='name' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter reward name"  onChange={onFormDataChange}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="brandId" className='px-4 py-2  text-lg'> Brand Id </label>
            <div className="px-4 "><input type="text" name='brandId' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter brand ID"  onChange={onFormDataChange}/></div>
            </div>

            {/* <div className="flex flex-col">
            <label htmlFor="cost" className='px-4 py-2  text-lg'> Cost </label>
            <div className="px-4 "><input type='number' name='cost' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="No of EB coins"  onChange={onFormDataChange}/></div>
            </div> */}

            </div>
            {/* Row 2 */}
            <div className="flex">
                
            <div className="flex flex-col">
            <label htmlFor="type" className='px-4 py-2  text-lg'> Type </label>
            <div className="px-4 ">
                <select name='type' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select type of Reward</option>
                <option value="coupon">Coupon</option>
                <option value="product">Product</option>
                </select>
                </div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="isUnlimited" className='px-4 py-2  text-lg'> Limited / Unlimited</label>
            <div className="px-4 ">
                <select name='isUnlimited' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select</option>
                <option value="limited">Limited</option>
                <option value="unlimited">Unlimited</option>
                </select>
                </div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="isUnique" className='px-4 py-2  text-lg'> Coupon Type</label>
            <div className="px-4 ">
                <select name='isUnique' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select type </option>
                <option value="standard">Standard</option>
                <option value="unique">Unique</option>
                </select>
                </div>
            </div>

            </div>
            {/* Row 3 */}
            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="link" className='px-4 py-2  text-lg'> Link </label>
            <div className="px-4 "><input type="text" name='link' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter link" onChange={onFormDataChange} /></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="totalCount" className='px-4 py-2  text-lg'> Coupon / Product count </label>
            <div className="px-4 "><input type='number' value={rewardData.totalCount} name='totalCount' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter coupon / Product count" onChange={onFormDataChange} /></div>
            </div>

            {/* <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Cost </label>
            <div className="px-4 "><input type="text" name='Brand Id' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="No of EB coins"  /></div>
            </div> */}

            </div>

            {/* row 4 */}
            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="couponListRaw" className='px-4 py-2  text-lg'> Coupons </label>
            <div className="px-4 "><textarea name='couponListRaw' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Paste coupons seperated by comma" onChange={onFormDataChange} /></div>
            </div>

            <div className="px-4 pt-10 pb-3 w-96"><button type="submit" className="bg-violet-400 text-white font-medium shadow-md hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded text-center my-3 " onClick={parseCoupons}>Parse coupons</button> </div>

            {rewardData.couponList && <div className="px-4 ml-16 py-3 h-32 overflow-y-auto">
                    {rewardData.couponList.map(coupon=> (<div>{coupon}</div>))}
                </div>}
            </div>
            
            {/* Row 5 */}
            <div className="flex">
            <div className="px-4 py-3"><input type="file" name='image1' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" accept="image/*" onChange={onFormDataChange} /></div>
            <div className="px-4 py-3"><input type="file" name='image2' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" accept="image/*" onChange={onFormDataChange} /></div>
            <div className="px-4 py-3"><input type="file" name='image3' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" accept="image/*" onChange={onFormDataChange}  /></div>
            </div>

            <div className="flex">

            {rewardData && rewardData.image1Url && <div className="px-10 w-full py-3 w-96"><img className="h-24" src={rewardData.image1Url} alt='Brand name' /></div>}
            {rewardData && rewardData.image2Url && <div className="ml-5 px-10 w-full py-3 w-96"><img className="h-24" src={rewardData.image2Url} alt='Brand name' /></div>}
            {rewardData && rewardData.image3Url && <div className="ml-5 px-10 w-full py-3 w-96"><img className="h-24" src={rewardData.image3Url} alt='Brand name' /></div>}
            </div>

            <div className="flex">
            <div className="px-4 py-3"><button type="submit" className="bg-violet-400 text-white font-medium shadow-md hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-96  " onClick={submitHandler} >Add Reward</button> </div>
            </div>

            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
            </div>

            <table className="text-sm text-left text-slate-500 mx-5 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Limited / Unlimited
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    count
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    cost
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    Brand
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Link
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Expired
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            {rewardList && rewardList.map((reward, index) =>
                                <tr className={`bg-white border-b hover:bg-slate-200 ${index%2===0?'': 'bg-slate-100'}`}>
                                    <th scope="row" className="px-6 py-4 ">
                                        {reward._id}
                                    </th>
                                    <th scope="row" className="px-6 py-4  ">
                                        {reward.rewardName}
                                    </th>
                                    <td className="px-6 py-4">
                                        {reward.created_at}
                                    </td>
                                    <td className="px-6 py-4 h-10 ">
                                        {reward.image_urls?
                                        <>
                                        {reward.image_urls.map(urls=><img src={urls} alt={reward.name} className='h-10'/> )}
                                        </>
                                        : <span>No Image</span>}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reward.type}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reward.isUnlimited? "Unlimited" : "Limited"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reward.isUnlimited? reward.claimedCount: `${reward.claimedCount} / ${reward.totalCount}`}
                                    </td>
                                    {/* <td className="px-6 py-4">
                                        {reward.cost}
                                    </td> */}
                                    <td className="px-6 py-4">
                                        {reward.brand &&(reward.brand[0] ? reward.brand[0].name: "Not available") || "Not available"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reward.link}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reward.expire_at? reward.expire_at: "Not available"}
                                    </td>
                                
                                    <td className="px-6 py-4 h-10 ">
                                    <button type="submit" className={`${reward.available?"bg-red-600 ": "bg-green-600 "}shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-3 p-2 rounded w-24 text-white`} onClick={()=>loadDeleteData(index)}>{reward.available?"Disable":"Enable"}</button>
                                    {/* <button type="submit" className="bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-3 p-2 rounded w-24 text-white" onClick={()=>loadDeleteData(index)}>Delete</button> */}
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>

                    {deleteData && <Modal description={`Do you want to delete ${deleteData.name} ?`} onSuccess={changeRewardState} onCancel={clearDeleteData} /> }
                    
        

            </div> 
    );
}
 
export default CreateRewards;