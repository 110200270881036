import React from "react";
import { Route, Routes } from "react-router-dom";

import SearchUser from "../pages/user/serach-user";
import Login from "../pages/auth/login";
import ClubRoomList from "../pages/audioRooms/clubRoomlist";
import CometchatGroups from "../pages/cometchat/grouplist";
import GroupMessages from "../pages/cometchat/group";
import PostList from "../pages/post/postList";
import ReferalList from "../pages/referals/referallist";
import SideNavbar from "../components/navbar/sidenavbar";
import CollegeVerificationList from "../pages/verification/college";
import CreateBrand from "../pages/merchandise/createBrand";
import CreateMerchandise from "../pages/merchandise/merchandise";
import CreateChallenge from "../pages/challenge/createChallenge";
import CreateClubs from "../pages/clubs/createClubs";
import CreateQuiz from "../pages/clubs/createQuiz";
import CollegeSearch from "../pages/colleges/college-search";
import MarkIntern from "../pages/user/make-user-intern";
import CreateOpportunity from "../pages/clubs/createOpportunity";
import CreateReward from "../pages/reward/createReward";
import ClubResources from "../pages/clubs/resources";
import ClubQuery from "../pages/clubs/query";
import ClubReply from "../pages/clubs/replies";
import RoomList from "../pages/audioRooms/roomlist";
import SendUserNotificaion from "../pages/notifications/sendUserNotifications";
import ChangeUserRole from "../pages/user/changeRole";
import DummyAccount from "../pages/user/dummyAccounts";
import ReportedUser from "../pages/reportedUsers/reportedUsers";
import EditCollege from "../pages/colleges/edit-college";
import BeepMoments from "../pages/beepMoments/beepMoments";
import MomentsSchedule from "../pages/beepMoments/momentsScheduler";
import CollegeRequest from "../pages/request/collegeRequests";
import AddCollege from "../pages/colleges/addCollege";
import AddUserToChannel from "../pages/clubs/addUser";
import InternPanel from "../pages/internpanel/internpanel";
import JobListing from "../pages/jobs/jobListing";
import Banners from "../pages/jobs/banner";
import BeepsyLogin from "../beepsyPages/auth/login-beepsy";
import BeepsySideNavbar from "../components/navbar/beepsy-sidenavbar";
import IdCardVerification from "../beepsyPages/verification/idcard-verification";
import Referral from "../pages/referals/referral";
import BeepsyDummyAccount from "../beepsyPages/verification/dummy_accounts";
import BeepsyPost from "../beepsyPages/post/post";
import CustomNotification from "../beepsyPages/verification/custom_notifications";
import TPODashboard from "../pages/tpo/tpo_dashboard";
import Scrap from "../pages/scrap/scrap";
import CreateRoomDyte from "../pages/audioRoomsDyte/createRoomsDyte";
import Testimonials from "../pages/testimonials/testimonials";
import RecruiterCoupon from "../pages/recruiterCoupon/recruiterCounpon";
import Course from "../pages/course/course";
import JobEditReview from "../pages/jobs/jobEditReview";
import UserOTPS from "../pages/otps/otps";
import RecruiterPremium from "../pages/recruiterPremium/recruiterPremium";

const MainRoutes = () => {
  return (
    <div className="flex bg-slate-200 w-screen">
      <Routes>
        <Route path="beepsy/*" element={<BeepsySideNavbar className="w-100" />} />
        <Route path="*" element={<SideNavbar className="w-100" />} />
      </Routes>

      <div className="w-screen overflow-auto h-screen ">
        <Routes className="">
          <Route path="" element={<Login />} />
          <Route path="beepsy/login" element={<BeepsyLogin />} />
          <Route path="beepsy/verification" element={<IdCardVerification />} />
          <Route path="beepsy/dummyaccounts" element={<BeepsyDummyAccount />} />
          <Route path="beepsy/post" element={<BeepsyPost />} />
          <Route path="beepsy/custnot" element={<CustomNotification />} />
          <Route path="user-search" element={<SearchUser />} />
          <Route path="user/intern" element={<MarkIntern />} />
          <Route path="room-list" element={<RoomList />} />
          <Route path="post" element={<PostList />} />
          <Route path="marketing" element={<ReferalList />} />
          <Route path="brand" element={<CreateBrand />} />
          <Route path="club/create" element={<CreateClubs />} />
          <Route path="college/search" element={<CollegeSearch />} />
          <Route path="merchandise" element={<CreateMerchandise />} />
          <Route path="challenge/create" element={<CreateChallenge />} />
          <Route path="cometchat/channels" element={<CometchatGroups />} />
          <Route path="cometchat/channel/:guid" element={<GroupMessages />} />
          <Route path="verify/college" element={<CollegeVerificationList />} />
          <Route path="club/quiz/:clubId" element={<CreateQuiz />} />
          <Route path="club/opp/:clubId" element={<CreateOpportunity />} />
          <Route path="club/resource/:clubId" element={<ClubResources />} />
          <Route path="club/query/:clubId" element={<ClubQuery />} />
          <Route path="club/replies/:clubId" element={<ClubReply />} />
          <Route path="club/rooms/:clubId" element={<ClubRoomList />} />
          <Route path="rewards" element={<CreateReward />} />
          <Route path="notification" element={<SendUserNotificaion />} />
          <Route path="user/role/:userId/:role" element={<ChangeUserRole />} />
          <Route path="dummyaccounts" element={<DummyAccount />} />
          <Route path="report" element={<ReportedUser />} />
          <Route path="college/:collegeId" element={<EditCollege />} />
          <Route path="moments" element={<BeepMoments />} />
          <Route path="moments/schedules" element={<MomentsSchedule />} />
          <Route path="request" element={<CollegeRequest />} />
          <Route path="college/add" element={<AddCollege />} />
          <Route path="club/add/user/:clubId" element={<AddUserToChannel />} />
          <Route path="internpanel" element={<InternPanel />} />
          <Route path="jobs" element={<JobListing />} />
          <Route path="banners" element={<Banners />} />
          <Route path="referral" element={<Referral />} />
          <Route path="tpo" element={<TPODashboard/>}/>
          <Route path='scrap' element={<Scrap />} />
          <Route path='dyte' element={<CreateRoomDyte />} />
          <Route path='testimonials' element={<Testimonials />} />
          <Route path='course' element={<Course />} />
          <Route path='promocode' element={<RecruiterCoupon />} />
          <Route path='job/edit' element={<JobEditReview />} />
          <Route path='otp' element={<UserOTPS />} />
          <Route path="recruiter" element={<RecruiterPremium />} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </div>
    </div>
  );
};

export default MainRoutes;
