import React, { useEffect, useState } from 'react'
import PageHeading from '../../components/headings/pageHeading'
import axios from 'axios'
import AlertWithClose from '../../components/alert/alertWithClose'
import Modal from '../../components/modal/modal'

const Scrap = () => {
	const [jobslist, setJobslist] = useState([])
	// const [jobForm, setJobForm] = useState({ job_id: '', banner_id: '' })
	const [bannerForm, setBannerForm] = useState({})
    const [jobTotal, setJobTotal] = useState(0)
	// const [alertData, setAlertData] = useState(null)
	// const [deleteData, setDeleteData] = useState(null)

	

	const loadJobs = async () => {
		const url = `${process.env.REACT_APP_BASE_URL}/admin/scrap/job`

		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
            params:{
                skip: jobslist.length
            }
		})

		if (response.data) {
			setJobslist(prev=>[...prev, ...response.data.data])
            setJobTotal(response.data.total)
		}
	}

	useEffect(loadJobs, [])


	return (
		<div className='banner'>
			<PageHeading heading={`Jobs (${jobTotal})`} />

		
			<table className='w-full text-sm text-left p-4 overflow-scroll inline-block'>
				<thead className='text-xs  uppercase bg-gray-50 '>
					<tr>
                        {["Job Title", "Company Name", "Job type", "Source","Posted on", "Location", "Skills", "Salary", "Prob / other salary", 
                "Opening count", "Application Count", "Benifits"].map((title)=> (<th scope='col' className='px-6 py-3'>
							{title}
						</th>))}
						
						
					</tr>
				</thead>
				{/* eslint-disable no-underscore-dangle */}
				<tbody id='accordion-collapse' data-accordion='collapse'> 
					{jobslist.map((jobData, index) => (
						<>
							<tr className={`bg-white border-b`} key={jobData._id}>
								<th scope='row' className='px-6 py-4 font-medium '>
									<a href={jobData?.url} className='text-blue-600' target='_blank'>{jobData.jobTitle}</a>
								</th>
								<td className='px-6 py-4'>
                                {jobData?.companyUrl? <a href={jobData?.companyUrl} className='text-blue-600' target='_blank'>{jobData?.companyName}</a>:jobData?.companyName}
								</td>
								<td className='px-6 py-4'>
									{jobData?.jobType}
								</td>
                                <td className='px-6 py-4'>
									{jobData?.source}
								</td>
								<td className='px-6 py-4'>
									{jobData?.createdAt}
								</td>
								<td className='px-6 py-4'>
									{Array.isArray(jobData?.location)? (jobData?.location.join(', ')): (jobData?.location)}
								</td>
                                <td className='px-6 py-4'>
									{Array.isArray(jobData?.skills)? (jobData?.skills.join(', ')): (jobData?.skills)}
								</td>
                                
                                <td className='px-6 py-4'>
									{jobData?.salary}
								</td>

                                <td className='px-6 py-4'>
									{jobData?.otherSalary}
								</td>
                                <td className='px-6 py-4'>
									{jobData?.noOfOpenings}
								</td>
                                <td className='px-6 py-4'>
									{jobData?.applicationsCount}
								</td>
                                
                                <td className='px-6 py-4'>
									{Array.isArray(jobData?.perks)? (jobData?.perks.join(', ')): (JSON.stringify(jobData?.perks))}
								</td>
							</tr>
						</>
                        // <>{jobData}</>
					))}
				</tbody>
			</table>

                        <div className='mx-auto'>
                      { jobTotal> jobslist.length && <button onClick={loadJobs} className='bg-yellow-500 p-2 text-white shadow-lg mx-auto block'>Load more</button>}
                      </div>

                      
			 {/* {deleteData && ( 
				<Modal
					description={
						// deleteData.available
						`Do you want to  disable banner ?`
						// : `Do you want to activate ${deleteData.name} ?`
					}
					onSuccess={changeMerchandiseState}
					onCancel={clearDeleteData}
				/>
			)}

			{alertData && (
				<AlertWithClose
					message={alertData.message}
					type={alertData.type}
					time={4}
					clearAlert={clerarAlert}
				/>
			)} */}
		</div>
	)
}

export default Scrap
