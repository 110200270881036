import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AlertWithClose from '../../components/alert/alertWithClose';
import InputModal from '../../components/modal/inputModal';
import { sendNotificationToUser } from '../../service/service';


const SearchUser = ({userId, refershFunc}) => {

    const [userID, setUserId] = useState(userId);
    const [users, setUsers] =  useState([]);
    const [alertData, setAlertData] = useState(null);
    const [blockReason, setBlockReason] = useState('');
    const [blockData, setBlockData] =  useState(null);
    const [blockUnblock, setBlockUnblock] = useState(null);
    console.log(userId, "User data");
    const onResonChange = (e)=>{
        setBlockReason(e.target.value);
    }

    const clearAlert= ()=>{
        setAlertData(null);
    }
        
/* eslint-disable no-underscore-dangle */


    const onSubmit= async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/user`;
    
            const token = localStorage.getItem('authKey');

            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`,   
            },
            params:{
                "id":userId
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'User list updated', type:'good'});
            
            setUsers(response.data.suggestions);
            
            console.log(response, users)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to get users', type:'bad'})
                }
                else
                setAlertData({message:'Unable to get users', type:'bad'})
            }
        
    }

    useEffect(()=>{
        setUserId(userID)
        console.log("effect")
    },[])

    useEffect(async()=>{
        await onSubmit()
        console.log("effect 2")
    }, [userId])


    const loadBlockData = (index, block)=>{
        setBlockData(users[index]);
        setBlockUnblock(block);

    }

    const clearBlockData =  ()=>{
        setBlockData(null)
        setBlockReason("")
        setBlockUnblock(null);
    }

    const blockUser =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/block`;
    
            const token = localStorage.getItem('authKey');

            console.log(blockReason,blockReason === null , blockReason ==='', 'Block reason')
            if(blockUnblock && (blockReason === null || blockReason ==='')) { 
                setAlertData({message: 'Reason is required', type:'bad'}); 
                console.log(blockReason, 'Block reason inside')
                return;}
            const promise = sendNotificationToUser(blockData._id, `You are ${blockUnblock?'blocked':'unblocked'} by EB Community`, blockReason, true)
            const response = await axios.post(url, {reason: blockReason, id: blockData._id, block:blockUnblock},{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
        
        await promise;
    
        if(response.status===200){
            setAlertData({message: `User ${blockUnblock?'blocked':'unblocked'}`, type:'good'});

            onSubmit()
            refershFunc()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to block user', type:'bad'})
                }
                else
                setAlertData({message:'Unable to block user', type:'bad'})
            }
        
            clearBlockData();

    }

    return (
        <div className='w-full'>
            <div className="text-center">
                
                {users?(<div className='items-stretch justify-items-stretch' style={{gridAutoRows: '1fr', gridAutoCols:'1fr'}}>
                {users.map((user, index)=>(
                    
                    <div className="px-4  my-2 h-fit">
                    

                    <div className="flex">
                    <div className="">
                        {user.personalInfo.avatar&&<img className="text-sm  h-12 w-12" src={user.personalInfo.avatar} alt="userprofile" />}
                    </div>
                    <div className="flex flex-col mx-3 text-left">
                    <div className='font-semibold bottom-0  ' title={user.personalInfo.name}>{user.personalInfo.name}</div>
                    {user.created_at && <div className="text-sm  mt-1" >{new Date(user.created_at).toLocaleString()}</div>}

                    </div>
                    
                    <div>
                       <div className="flex">
                       {user.blocked?<button type='button' className='bg-green-600 text-white px-2 py-2 rounded shadow-sm text-xs mr-3 ml-auto' onClick={()=>loadBlockData(index, false)}>Unblock</button>: <button type='button' className='bg-black text-yellow-400 px-2 py-2 rounded shadow-sm text-xs' onClick={()=>loadBlockData(index, true)}>Block</button>}
                       
                       </div>
                    </div>
                    </div>
                    
                    
                    
                    <div className="flex pt-1">
                        {user.collegeInfo && <div className="flex flex-col text-left">
                            <div className="text-sm  font-semibold" title={user.collegeInfo.collegeName}>{user.collegeInfo.collegeName}</div>
                            <div className="text-sm">{`${user.collegeInfo.stream} - ${user.collegeInfo.passout}`}</div>
                            <div className="flex">
                            <div className="text-sm">{user._id}</div>
                            {user.personalInfo && user.personalInfo.role && <div className="bg-green-600 text-white px-2 rounded shadow-sm mr-1 mb-1 ml-auto mr-0 text-xs">{user.personalInfo.role[0].toUpperCase()}</div>}
                            </div>
                        </div> }
                        
                    </div>
                    
                    </div>
                    
                ))}
                </div>):"No result"}
            </div>
                    
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            {blockData && <InputModal description={`Do you want to ${blockUnblock?"block":"unblock"} ${blockData.personalInfo.name} ?`} onSuccess={blockUser} onCancel={clearBlockData} onReason={onResonChange} /> }
        </div>
    );
}

export default SearchUser;