import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import axios from "axios";

import AlertWithClose from "../../components/alert/alertWithClose";
import Modal from "../../components/modal/modal";
import PageHeading from "../../components/headings/pageHeading";
import TextareaModal from "../../components/modal/textareaModal";
import { dateCustom } from "../../service/date";

const CreateMerchandise = () => {
  const [merchData, setMerchData] = useState({});
  const [notificationData, setNotificationData] = useState(null);
  const [merchList, setMerchList] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [viewDescriptionIndex, setViewDescriptionIndex] = useState(null);
  const [updateDescriptionText, setUpdateDescriptionText] = useState(null);

  const onImageChange = (event, name) => {
    if (event.target.files && event.target.files[0]) {
      setMerchData((prev) => ({
        ...prev,
        [`${name}Url`]: URL.createObjectURL(event.target.files[0]),
      }));
    } else
      setMerchData((prev) => ({
        ...prev,
        [`${name}Url`]: "",
      }));
  };
  /* eslint-disable consistent-return */
  function parseCoupons() {
    if (!merchData.couponListRaw) {
      console.log("Error");
      return;
    }

    let couponList = merchData.couponListRaw.split(",");

    couponList = couponList.map((coupon) => coupon.trim());
    couponList = couponList.filter((coupons) => coupons);

    console.log(couponList);
    return {
      couponList,
      totalCount: couponList.length,
    };

    // setNotificationData({message: "Parsed data", type:'good'})
  }

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i += 1) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const onFormDataChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setMerchData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      onImageChange(e, name);
    } else
      setMerchData((prev) => ({
        ...prev,
        [name]: value,
      }));

    console.log(merchData, e);
  };
  useEffect(() => {
    const data = parseCoupons();

    if (data && !arraysEqual(data.couponList, merchData.couponList))
      setMerchData((prev) => ({
        ...prev,
        ...data,
      }));
  }, [merchData]);
  const clearAlert = () => {
    setNotificationData(null);
  };

  const loadMerch = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/merch`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setMerchList(response.data.merch);
    } catch (e) {
      console.log(e);
    }
  };

  const submitHandler = async (e) => {
    // onClick={submitHandler}
    e.preventDefault();
    console.log(merchData, e);

    try {
      console.log(merchData);
      const url = `${process.env.REACT_APP_BASE_URL}/admin/add_merch`;
      const reqFormData = new FormData();

      /* eslint-disable no-unneeded-ternary */
      const coupListTemp = merchData.couponList ? merchData.couponList : [];

      // let images = [merchData.image1, merchData.image2]
      // images = images.filter(image => image)

      if (coupListTemp) reqFormData.append("coupons", coupListTemp.join(","));

      if (merchData.name) reqFormData.append("name", merchData.name);
      else {
        setNotificationData({ message: "Name is required", type: "bad" });
        return;
      }
      if (merchData.brandId) reqFormData.append("brand_id", merchData.brandId);
      else {
        setNotificationData({ message: "Brand is required", type: "bad" });
        return;
      }
      if (merchData.link) reqFormData.append("link", merchData.link);
      else {
        setNotificationData({ message: "Link is required", type: "bad" });
        return;
      }
      console.log(typeof merchData.is_trending);
      if (
        merchData.is_trending === "false" ||
        merchData.is_trending === "true"
      ) {
        if (merchData.is_trending === "false") {
          reqFormData.append("trending", false);
        } else {
          reqFormData.append("trending", true);
        }
      } else {
        setNotificationData({ message: "Select trending status", type: "bad" });
        return;
      }

      if (merchData.cost) reqFormData.append("cost", merchData.cost);
      else {
        setNotificationData({ message: "Cost is required", type: "bad" });
        return;
      }
      if (merchData.original_cost)
        reqFormData.append("original_cost", merchData.original_cost);

      if (merchData.is_trending === "true" && !merchData.sale_end) {
        setNotificationData({
          message: "Salse End date is required for trending merch",
          type: "bad",
        });
        return;
      }
      if (merchData.sale_end && merchData.is_trending)
        reqFormData.append("sale_end", merchData.sale_end);

      if (merchData.description)
        reqFormData.append("description", merchData.description);
      else {
        setNotificationData({
          message: "Description is required",
          type: "bad",
        });
        return;
      }

      if (merchData.image1) reqFormData.append("upload", merchData.image1);
      else {
        setNotificationData({ message: "Image is required", type: "bad" });
        return;
      }
      if (merchData.image2) reqFormData.append("upload", merchData.image2);
      else {
        setNotificationData({ message: "Image is required", type: "bad" });
        return;
      }

      console.log(reqFormData);

      const token = localStorage.getItem("authKey");

      const response = await axios.post(url, reqFormData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response);

      if (response.status === 200) {
        setNotificationData({ message: "Merchandise created", type: "good" });
        loadMerch();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      } else
        setNotificationData({ message: "Unable to delete brand", type: "bad" });
    }
  };

  useEffect(async () => {
    await loadMerch();
  }, []);

  /* eslint-disable no-underscore-dangle, prefer-destructuring */
  const loadDeleteData = (index) => {
    setDeleteData(merchList[index]);
  };

  const clearDeleteData = () => {
    setDeleteData(null);
  };

  const changeMerchandiseState = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/merch/${deleteData._id}`;

      const token = localStorage.getItem("authKey");

      const response = await axios.delete(
        url,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setNotificationData({
          message: "Merchandise State changed",
          type: "good",
        });

        loadMerch();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      } else
        setNotificationData({
          message: "Unable to change merchandise state",
          type: "bad",
        });
    }

    clearDeleteData();
  };

  const ondescriptionUpdate = (event) => {
    setUpdateDescriptionText(event.target.value);
  };

  const onDescriptionUpdateCancel = () => {
    setUpdateDescriptionText(null);
    setViewDescriptionIndex(null);
  };

  const onDescriptionUpdateSuccess = async () => {
    console.log(updateDescriptionText);

    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin-users/merchandise/${merchList[viewDescriptionIndex]._id}`;

      const token = localStorage.getItem("authKey");

      const response = await axios.put(
        url,
        { description: updateDescriptionText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setNotificationData({ message: "Merchandise updated", type: "good" });

        loadMerch();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      } else
        setNotificationData({
          message: "Unable to change update merchandise",
          type: "bad",
        });
    }

    onDescriptionUpdateCancel();
  };

  const loadData = (index) => {
    const data = merchList[index];
    const images = {};
    if (data.image_urls.length > 0) images.image1Url = data.image_urls[0];
    if (data.image_urls.length > 1) images.image2Url = data.image_urls[1];
    if (data.image_urls.length > 2) images.image3Url = data.image_urls[2];
    setMerchData({
      ...data,
      isUnlimited: data.isUnlimited ? "unlimited" : "limited",
      isUnique: merchData.isUnique ? "unique" : "standard",
      isUpdate: true,
      couponListRaw: "",
      ...images,
    });
  };

  const updatetHandler = async (e) => {
    // onClick={submitHandler}
    e.preventDefault();
    console.log(merchData, e);

    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin-users/update/merchandise/${merchData._id}`;
      const reqFormData = new FormData();

      /* eslint-disable no-unneeded-ternary */
      // let images = [merchData.image1, merchData.image2, merchData.image3]
      // images = images.filter(image=>image);

      reqFormData.append("name", merchData.name);
      reqFormData.append("brandId", merchData.brandId);
      reqFormData.append("cost", merchData.cost);
      reqFormData.append("description", merchData.description);
      reqFormData.append("link", merchData.link);

      if (merchData.image1) reqFormData.append("image1", merchData.image1);

      if (merchData.image2) reqFormData.append("image2", merchData.image2);

      if (merchData.image3) reqFormData.append("image3", merchData.image3);

      console.log(reqFormData);
      const token = localStorage.getItem("authKey");

      console.log(reqFormData);

      const response = await axios.post(url, reqFormData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response);

      if (response.status === 200) {
        setNotificationData({ message: "Merchandise updated", type: "good" });
        loadMerch();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      }
      console.log(error);
    }
  };

  return (
    <div className="coupons w-full  ">
      <PageHeading heading="Merchandise" className="" />
      <div className="">
        <div className="w-full">
          {/* Row 1 */}
          <div className=" flex ">
            <div className="flex flex-col">
              <label htmlFor="name" className="px-4 py-2  text-lg">
                {" "}
                Merchandise Name{" "}
              </label>
              <div className="px-4 ">
                <input
                  type="text"
                  name="name"
                  value={merchData.name}
                  className="block form-control w-full h-full text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                  placeholder="Enter merchandise name"
                  onChange={onFormDataChange}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="brandId" className="px-4 py-2  text-lg">
                {" "}
                Brand Id{" "}
              </label>
              <div className="px-4 ">
                <input
                  type="text"
                  name="brandId"
                  value={merchData.brandId}
                  className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                  placeholder="Enter brand ID"
                  onChange={onFormDataChange}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="link" className="px-4 py-2  text-lg">
                {" "}
                Link{" "}
              </label>
              <div className="px-4 ">
                <input
                  type="text"
                  name="link"
                  value={merchData.link}
                  className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                  placeholder="Enter link"
                  onChange={onFormDataChange}
                />
              </div>
            </div>
          </div>
          {/* Row 2 */}
          {/* {!merchData.isUpdate && (
						<div className='flex'>
							<div className='flex flex-col'>
								<label htmlFor='type' className='px-4 py-2  text-lg'>
									{' '}
									Type{' '}
								</label>
								<div className='px-4 '>
									<select
										name='type'
										value={merchData.type}
										className='block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300'
										onChange={onFormDataChange}
									>
										<option disabled selected>
											Select type of Merch
										</option>
										<option value='coupon'>Coupon</option>
										<option value='product'>Product</option>
									</select>
								</div>
							</div>

							<div className='flex flex-col'>
								<label htmlFor='isUnlimited' className='px-4 py-2  text-lg'>
									{' '}
									Limited / Unlimited
								</label>
								<div className='px-4 '>
									<select
										name='isUnlimited'
										value={merchData.isUnlimited}
										className='block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300'
										onChange={onFormDataChange}
									>
										<option disabled selected>
											Select
										</option>
										<option value='limited'>Limited</option>
										<option value='unlimited'>Unlimited</option>
									</select>
								</div>
							</div>

							<div className='flex flex-col'>
								<label htmlFor='isUnique' className='px-4 py-2  text-lg'>
									{' '}
									Coupon Type
								</label>
								<div className='px-4 '>
									<select
										name='isUnique'
										value={merchData.isUnique}
										className='block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300'
										onChange={onFormDataChange}
									>
										<option disabled selected>
											Select type{' '}
										</option>
										<option value='standard'>Standard</option>
										<option value='unique'>Unique</option>
									</select>
								</div>
							</div>
						</div>
					)} */}

          {!merchData.isUpdate && (
            <div className="flex">
              <div className="flex flex-col">
                <label htmlFor="is_trending" className="px-4 py-2  text-lg">
                  Trending
                </label>
                <div className="px-4 ">
                  <select
                    name="is_trending"
                    value={merchData.is_trending}
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"
                    onChange={onFormDataChange}
                  >
                    <option disabled selected>
                      Select trending
                    </option>
                    <option value={false}>False</option>
                    <option value={true}>True</option>
                  </select>
                </div>
              </div>

              {merchData.is_trending != "false" && (
                <div className="flex flex-col">
                  <label htmlFor="sale_end" className="px-4 py-2  text-lg">
                    {" "}
                    Salse ends at
                  </label>
                  <div className="px-4 ">
                    <input
                      disabled={merchData.is_trending == "false"}
                      type="datetime-local"
                      name="sale_end"
                      value={merchData.sale_end}
                      className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-2"
                      placeholder="Select date"
                      onChange={onFormDataChange}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Row 3 */}
          {!merchData.isUpdate && (
            <div className="flex">
              <div className="flex flex-col">
                <label htmlFor="cost" className="px-4 py-2  text-lg">
                  {" "}
                  Cost{" "}
                </label>
                <div className="px-4 ">
                  <input
                    type="number"
                    name="cost"
                    value={merchData.cost}
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                    placeholder="No of EB coins"
                    onChange={onFormDataChange}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label htmlFor="original_cost" className="px-4 py-2  text-lg">
                  {" "}
                  Original Cost{" "}
                </label>
                <div className="px-4 ">
                  <input
                    type="number"
                    name="original_cost"
                    value={merchData.original_cost}
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                    placeholder="Original cost"
                    onChange={onFormDataChange}
                  />
                </div>
              </div>

              {/* <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Cost </label>
            <div className="px-4 "><input type="text" name='Brand Id' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3" placeholder="No of EB coins"  /></div>
            </div> */}
            </div>
          )}

          {/* row 4 */}
          <div className="flex">
            <div className="flex flex-col">
              <label htmlFor="description" className="px-4 py-2  text-lg">
                {" "}
                Description{" "}
              </label>
              <div className="px-4 ">
                <textarea
                  name="description"
                  value={merchData.description}
                  className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                  placeholder="Description"
                  onChange={onFormDataChange}
                />
              </div>
            </div>

            {merchData.isUpdate && (
              <div className="flex flex-col">
                <label htmlFor="link" className="px-4 py-2  text-lg">
                  {" "}
                  Link{" "}
                </label>
                <div className="px-4 ">
                  <input
                    type="text"
                    name="link"
                    value={merchData.link}
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                    placeholder="Enter link"
                    onChange={onFormDataChange}
                  />
                </div>
              </div>
            )}

            {!merchData.isUpdate && (
              <div className="flex flex-col">
                <label htmlFor="couponListRaw" className="px-4 py-2  text-lg">
                  {" "}
                  Coupons{" "}
                </label>
                <div className="px-4 ">
                  <textarea
                    name="couponListRaw"
                    value={merchData.couponListRaw}
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                    placeholder="Paste coupons seperated by comma"
                    onChange={onFormDataChange}
                  />
                </div>
              </div>
            )}

            {/* <div className="px-4 pt-10 pb-3 w-96"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded text-center my-3 border-2 border-yellow-500" onClick={parseCoupons}>Parse coupons</button> </div> */}

            {merchData.couponList && (
              <div className="px-4  py-3 h-32 overflow-y-auto w-96">
                <div>
                  <span>No of coupons : </span>
                  <span>{merchData.couponList.length}</span>
                </div>
                {
                  merchData.couponList.join(", ")
                  // map(coupon=> (<div>{coupon.join(", ")}</div>))
                }
              </div>
            )}
          </div>

          {/* Row 5 */}
          <div className="flex">
            <div className="px-4 py-3">
              <input
                type="file"
                name="image1"
                className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                accept="image/*"
                onChange={onFormDataChange}
              />
            </div>
            <div className="px-4 py-3">
              <input
                type="file"
                name="image2"
                className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-violet-400 focus:outline-none px-4 py-3"
                accept="image/*"
                onChange={onFormDataChange}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/3">
              {merchData && merchData.image1Url && (
                <div className="px-10 w-full py-3 w-96">
                  <img
                    className="h-24"
                    src={merchData.image1Url}
                    alt="Brand name"
                  />
                </div>
              )}
            </div>
            <div className="w-1/3">
              {merchData && merchData.image2Url && (
                <div className="ml-5 px-10 w-full py-3 w-96">
                  <img
                    className="h-24"
                    src={merchData.image2Url}
                    alt="Brand name"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="px-4 py-3">
              <button
                type="submit"
                className=" bg-violet-400 text-white font-medium  hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-96"
                onClick={merchData.isUpdate ? updatetHandler : submitHandler}
              >
                {merchData.isUpdate ? "Update" : "Add Merchandise"}{" "}
              </button>{" "}
            </div>
          </div>

          {notificationData && (
            <AlertWithClose
              clearAlert={clearAlert}
              message={notificationData.message}
              time={4}
              type={notificationData.type}
            />
          )}
        </div>

        <div className="w-auto overflow-auto mx-4 mb-10 border border-slate-400 shadow-lg">
          <table className="text-sm text-left text-slate-500 rounded table-fixed">
            <thead className="text-xs text-white uppercase bg-gray-700 ">
              <tr>
                {/* <th scope='col' className='px-6 py-3 w-24'>
									Edit
								</th> */}
                <th scope="col" className="px-6 py-3">
                  Name
                </th>

                <th scope="col" className="px-6 py-3" style={{ width: "20%" }}>
                  Image
                </th>

                <th scope="col" className="px-6 py-3">
                  count
                </th>

                <th scope="col" className="px-6 py-3">
                  Origial cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Brand
                </th>
                <th scope="col" className="px-6 py-3">
                  Link
                </th>
                <th scope="col" className="px-6 py-3">
                  Expiring On
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="overflow-auto  h-94">
              {merchList &&
                merchList.map((merchandise, index) => (
                  <tr
                    className={`bg-white border-b hover:bg-slate-200 ${
                      index % 2 === 0 ? "" : "bg-slate-100"
                    }`}
                  >
                    {/* <th scope='row' className='px-6 py-4 break-word'>
											<button
												type='submit'
												className=' bg-violet-400 text-white  hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-10'
												onClick={() => loadData(index)}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													className='w-4 h-4'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
													/>
												</svg>
											</button>
										</th> */}

                    <th
                      scope="row"
                      className="px-6 py-4 break-word whitspace-normal w-48"
                    >
                      {merchandise.name}
                    </th>

                    <td className="px-6 py-4 w-24">
                      {merchandise.image ? (
                        <div className="">
                          {/* {merchandise.image_urls.map(urls => ( */}
                          <img
                            src={merchandise.image}
                            alt={merchandise.name}
                            className=""
                          />
                          <img
                            src={merchandise.image2}
                            alt={merchandise.name}
                            className=""
                          />
                          {/* ))} */}
                        </div>
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>

                    <td className="px-6 py-4">{merchandise.cost}</td>
                    <td className="px-6 py-4">{merchandise.original_cost}</td>
                    <td className="px-6 py-4">
                      {(merchandise.brand &&
                        (merchandise.brand[0]
                          ? merchandise.brand[0].name
                          : "Not available")) ||
                        "Not available"}
                    </td>
                    <td
                      className="px-6 py-4 truncate w-32"
                      title={merchandise.link}
                    >
                      {merchandise.link
                        ? merchandise.link.slice(0, 30) +
                          (merchandise.link.length > 30 ? "..." : "")
                        : "Not available"}
                    </td>
                    <td className="px-6 py-4">
                      {merchandise.trending
                        ? dateCustom(merchandise.sale_end, "DD/MM/yyy")
                        : "No Trending"}
                    </td>
                    <td
                      className="px-6 py-4 truncate"
                      title={merchandise.description}
                      // onDoubleClick={() => setViewDescriptionIndex(index)}
                    >
                      {/* eslint-disable   no-nested-ternary */}
                      {merchandise.description
                        ? merchandise.description.slice(0, 30) +
                          (merchandise.description.length > 30 ? "..." : "")
                        : "Not available"}
                    </td>

                    <td className="px-6 py-4 h-10 ">
                      {merchandise.active && (
                        <button
                          type="submit"
                          className={`${
                            merchandise.active
                              ? "bg-rose-500 "
                              : "bg-violet-500 "
                          } shadow hover:shadow-lg focus:shadow-lg px-3 p-2 rounded w-24 text-white`}
                          onClick={() => loadDeleteData(index)}
                        >
                          {merchandise.active ? "Disable" : "Enable"}
                        </button>
                      )}
                      {!merchandise.active && <>Disabled</>}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {deleteData && (
          <Modal
            description={
              // deleteData.available
              `Do you want to  disable ${deleteData.name} ?`
              // : `Do you want to activate ${deleteData.name} ?`
            }
            onSuccess={changeMerchandiseState}
            onCancel={clearDeleteData}
          />
        )}

        {viewDescriptionIndex !== null && (
          <TextareaModal
            onCancel={onDescriptionUpdateCancel}
            onSuccess={onDescriptionUpdateSuccess}
            onReason={ondescriptionUpdate}
            description={merchList[viewDescriptionIndex].description}
          />
        )}
      </div>
    </div>
  );
};

export default CreateMerchandise;
