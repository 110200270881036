import React, { useEffect, useState } from "react";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import { Buffer } from "buffer";

// import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from "../../components/alert/alertWithClose";
import PageHeading from "../../components/headings/pageHeading";
import moment from "moment";
import Modal from "../../components/modal/modal";

const RecruiterPromocode = () => {
  // const [rooms, setRooms] =  useState(null);
  const [formData, setForm] = useState({
    planId: -1,
    promoCode: "",
    amount: null,
    remainingCount: "10000",
    description: "",
    expiresAt: null,
  });
  // const { clubId } = useParams();
  const [promocodesList, setPromocodesList] = useState([]);
  const [notificationData, setNotificationData] = useState(null);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [deleteRoomIndex, setDeleteRoomIndex] = useState(null);

  // const [roomsList, setRoomsList] = useState([]);

  const planMap = {
    0: {
      name: "pro_plan_yearly",
      amount: 99000,
      validMonths: 12,
    },
    1: {
      name: "pro_plan_monthly",
      amount: 11000,
      validMonths: 1,
    },
    2: {
      name: "pro_plan_quaterly",
      amount: 36999,
      validMonths: 3,
    },
  };

  const clearAlert = () => {
    setNotificationData(null);
  };

  const parsePromocodes = (value) => {
    if (!formData.tags) {
      console.log("Error");
      return;
    }

    let tagList = value ? value.split(",") : "";

    tagList = tagList.map((promocode) => promocode.trim());
    tagList = tagList.filter((promocodes) => promocodes);

    console.log(tagList);
    setForm((prev) => ({
      ...prev,
      tagList,
    }));

    // setNotificationData({message: "Parsed data", type:'good'})
  };

  const loadPromocodes = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/promocode`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setPromocodesList(response.data.promoCodes);
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(loadPromocodes, []);

  /* eslint-disable  no-param-reassign */
  const onImageChange = (event) => {
    const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {
      // Initiate the JavaScript Image object.
      const image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this;
        console.log(height, width);

        if (event.target.files && event.target.files[0]) {
          setForm((prev) => ({
            ...prev,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          }));
        }
      };
    };
  };
  const onImageChange2 = (event) => {
    console.log("data from 2", event);

    const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {
      // Initiate the JavaScript Image object.
      const image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this;
        console.log(height, width);

        if (event.target.files && event.target.files[0]) {
          setForm((prev) => ({
            ...prev,
            imageUrl2: URL.createObjectURL(event.target.files[0]),
          }));
        }
      };
    };
  };

  //    const addRooms= ()=>{
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp.push({})
  //         return temp;
  //     })
  //    }

  // const roomsInputChange = (event, index) =>{
  //     const {name, value, type} = event.target;

  // if(type==='radio'){
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp[index][name.replace(index.toString(),"")] = value

  //         return temp;
  //     })
  //     return;
  // }
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp[index][name] = value

  //         return temp;
  //     })

  //     console.log(rooms);
  // }

  // useEffect(async()=>{
  //     loadPromocodes()
  // }, [])

  const formChanges = (e) => {
    const { name, value, files } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "image" || name === "image2") {
      setForm((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      console.log(name);
      if (name == "image2") {
        onImageChange2(e);
        console.log(name);
      } else {
        onImageChange(e);
      }
    }

    if (name === "tags") parsePromocodes(value);

    console.log(e, name, value, formData);
  };
  /* eslint-disable prefer-const */
  // const deleteQuestion = async(index)=>{
  //     console.log(index)
  //     setRooms(prev=>{
  //         let temp = [...prev];
  //         temp.splice(index, 1)
  //         // temp = temp.filter((data, indexTemp)=>{
  //         //     console.log(indexTemp, index, index!==indexTemp)
  //         //     return index!==indexTemp
  //         // })
  //         return temp
  //     })

  // }

  const onSubmit = async () => {
    try {
      setDisableCreateButton(true);

      let {
        planId,
        promoCode,
        amount,
        remainingCount,
        description,
        expiresAt,
      } = formData;

      amount = parseInt(amount);
      remainingCount = parseInt(remainingCount);
      planId = parseInt(planId);
      expiresAt = expiresAt? new Date(expiresAt).toISOString():null;

      if (!promoCode) {
        setNotificationData({ message: "Promocode  is required", type: "bad" });
        setDisableCreateButton(false);
        return;
      }

      if (!amount) {
        setNotificationData({
          message: "Discount amount is required",
          type: "bad",
        });
        setDisableCreateButton(false);
        return;
      }

      if (planId==-1) {
        setNotificationData({ message: "Select Plan", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      /* eslint-disable no-unneeded-ternary */

      if (amount > planMap[planId].amount) {
        setNotificationData({
          message: "Discount amount can not be greater than plan amount",
          type: "bad",
        });
        setDisableCreateButton(false);
        return;
      }

      const token = localStorage.getItem("authKey");

      
      const url = `${process.env.REACT_APP_BASE_URL}/admin/promocode`;
      const response = await axios.post(
        url,
        {
          planId,
          promoCode,
          amount,
          promocodeCount:remainingCount,
          description,
          expiresAt,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        setNotificationData({ message: "Code created", type: "good" });
        await loadPromocodes();
        setForm({planId: -1,
            promoCode: "",
            amount: "",
            remainingCount: "10000",
            description: "",
            expiresAt: ""})
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      }
      console.log(error);
    }
    setDisableCreateButton(false);
    // setForm({})
  };

  const deleteRoom = async () => {
    try {
      const token = localStorage.getItem("authKey");

      const url = `${process.env.REACT_APP_BASE_URL}/admin/promocode/${promocodesList[deleteRoomIndex]._id}`;
      const response = await axios.delete(url, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setNotificationData({ message: "Code Delted", type: "good" });
        await loadPromocodes();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      }
      console.log(error);
    }
    setDisableCreateButton(false);
    setDeleteRoomIndex(null);
  };

  return (
    <div className="RecruiterPromocodes w-full">
      <PageHeading heading="Create promocodes" />

      <div className="flex">
        <div className="flex flex-col">
          <label htmlFor="promoCode" className="px-4 py-2">
            Promo Code
          </label>
          <div className="px-4 py-1">
            <input
              type="text"
              name="promoCode"
              value={formData.promoCode}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="UserId"
              onChange={formChanges}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="amount" className="px-4 py-2">
            Discount Amount
          </label>
          <div className="px-4 py-1">
            <input
              type="number"
              name="amount"
              value={formData.amount}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Rs.10,000"
              onChange={formChanges}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="remainingCount" className="px-4 py-2">
            No of Promo codes
          </label>
          <div className="px-4 py-1">
            <input
              type="number"
              name="remainingCount"
              value={formData.remainingCount}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="1000"
              onChange={formChanges}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex flex-col">
          <label htmlFor="description" className="px-4 py-2  text-lg">
            {" "}
            Description{" "}
          </label>
          <div className="px-4 ">
            <textarea
              name="description"
              value={formData.description}
              className="block form-control  h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"
              placeholder="description"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="planId" className="px-4 py-2  text-lg">
            {" "}
            Plan{" "}
          </label>
          <div className="px-4 ">
            <select
              name="planId"
              value={formData.planId}
              className="block form-control  h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"
              onChange={formChanges}
            >
              <option disabled selected value="-1">
                Select plan
              </option>
              <option value="0">Yearly / 99000</option>
              <option value="1">Monthly / 11000</option>
              <option value="2">Quaterly / 36999</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="expiresAt" className="px-4 py-2">
            Expire Time (opt)
          </label>
          <div className="px-4 py-1">
            <input
              type="datetime-local"
              name="expiresAt"
              value={formData.expiresAt}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Select time"
              onChange={formChanges}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="flex pb-5">
          {/* <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2" onClick={addRooms} >Add Question</button> </div> */}

          {!formData.isUpdate && (
            <div className="px-4  mb-0 mt-auto ml-auto mr-0">
              <button
                type="submit"
                className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 "
                onClick={onSubmit}
                disabled={disableCreateButton}
              >
            Add code
              </button>{" "}
            </div>
          )}
        </div>

        <div>
          <table className=" bg-slate-400 px-4 mx-4 overflow-scroll w-full">
            <thead className=" bg-slate-800 text-white">
              <th>Promo Code</th>
              <th>Description</th>
              <th>Remaining Count</th>
              <th>Discount amount</th>
              <th>Plan ID</th>
              <th>Expires At</th>
              <th>Delete</th>
            </thead>

            <tbody>
              {promocodesList.map((data, index) => (
                <tr
                  className={`${
                    index % 2 == 0 ? " bg-slate-300 " : " bg-slate-200 "
                  } overflow-scroll text-center`}
                >
                  <td className="py-2">{data.promoCode}</td>
                  <td className="py-2">{data.description}</td>
                  <td className="py-2">{data.remainingCount}</td>
                  <td className="py-2">{data.amount}</td>
                  <td className="py-2">{`${planMap[data.planId].name} Rs.${
                    planMap[data.planId].amount
                  }`}</td>
                  <td className="py-2">
                    {data.expiresAt
                      ? moment(data.expiresAt).format("DD/MM hh:mm")
                      : "NA"}
                  </td>

                  <td>
                    {" "}
                    <button
                      className="bg-red-500 text-white px-2 py-2 rounded shadow"
                      onClick={() => setDeleteRoomIndex(index)}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {deleteRoomIndex != null && (
        <Modal
          description={`Do you want to delete  ${promocodesList[deleteRoomIndex].promoCode}?`}
          onCancel={() => setDeleteRoomIndex(null)}
          onSuccess={deleteRoom}
        />
      )}
      {notificationData && (
        <AlertWithClose
          clearAlert={clearAlert}
          message={notificationData.message}
          time={4}
          type={notificationData.type}
        />
      )}
    </div>
  );
};

export default RecruiterPromocode;
