import React, { useEffect, useState } from "react";
// import env from 'react-dotenv';
import axios from "axios";

import PageHeading from "../../components/headings/pageHeading";
import { Button, Modal } from "@mui/material";
// import Modal from '../../components/modal/modal';

const ReferalList = () => {
  const [referalData, setReferalList] = useState([]);
  const [indivigualData, setIndivigualData] = useState(null);
  const [view_modal, setViewModal] = useState(false);
  const [Students, setStudents] = useState([]);

  const [formData, setForm] = useState({ name: "" });

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        imageUrl: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };

  const formChanges = (e) => {
    const { name, value, files } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "image") {
      setForm((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      onImageChange(e);
    }
  };

  const getStudents = async (link_id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/marketing_refferals/${link_id}`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.users);
      setStudents(response.data.users);
      setViewModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  const loadReferals = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/refferal_link`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setReferalList(response.data.refferal);
    } catch (e) {
      console.log(e);
    }
  };

  const createReferals = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/refferal_link`;
      const token = localStorage.getItem("authKey");
      const response = await axios.post(
        url,
        { name: formData.name },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setForm({ name: "" });
      loadReferals();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    loadReferals();
  }, []);

  // const sortByReferral = () => {
  // 	console.log('On sort')
  // 	const sortedList = [...referalData.referals]
  // 	sortedList.sort((a, b) => a.referrals - b.referrals)
  // 	console.log(sortedList)
  // 	setDisplayReferalList(sortedList)
  // }

  // const reverseList = () => {
  // 	setDisplayReferalList(prev => {
  // 		const temp = [...prev]
  // 		return temp.reverse()
  // 	})
  // }

  const loadIndivigualData = async (name, id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin-users/usersrefered?user=${id}`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIndivigualData({ name, users: response.data.users });
      console.log(response);
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="postList mx-0 w-full">
      <PageHeading heading="Marketing" />
      <div className="">
        <div id="BrandCreateForm">
          <div className="bg-white m-4">
            <h2 className="font-bold px-4 pt-3">Create Marketing</h2>
            <div className="flex flex-row ">
              <div className="px-4 py-3">
                <input
                  type="text"
                  name="name"
                  className="form-input rounded outline-gray-400 px-4 py-3 outline-2 w-96 bg-gray-300"
                  placeholder="Enter Refferal name"
                  value={formData.name}
                  onChange={formChanges}
                />
              </div>

              <div className="px-4 py-3">
                <button
                  type="submit"
                  onClick={createReferals}
                  className="shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-36 bg-violet-500 text-white"
                >
                  Create
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto  sm:rounded-lg ">
          {/* <div className='mx-5 my-2'>
                        <button className="bg-black text-white px-2 py-2 mr-2 shadow rounded" type='button' onClick={sortByReferral}>
                            Sort
                        </button>
                        <button className="bg-black text-white px-2 py-2 mr-2 shadow rounded" type='button' onClick={reverseList}>
                            Reverse
                        </button>
                        
                    </div> */}
          <table className="text-sm text-left text-gray-500 mx-5  block w-full">
            <thead className="text-xs text-white  bg-gray-700 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                {/* <th scope="col" className="px-6 py-3">
                                    Phone No
                                </th> */}
                <th scope="col" className="px-6 py-3">
                  Count
                </th>
                <th scope="col" className="px-6 py-3">
                  Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Link
                </th>
                <th scope="col" className="px-6 py-3">
                  View Students
                </th>
              </tr>
            </thead>
            <tbody>
              {referalData &&
                referalData.map((referal) => (
                  <tr
                    className="bg-white border-b hover:bg-slate-100"
                    // onClick={() =>
                    //   loadIndivigualData(referal.name, referal._id)
                    // }
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 "
                    >
                      {referal.name}
                    </th>
                    {/* <td className="px-6 py-4">
                                        {referal.phone_number}
                                    </td> */}
                    <td className="px-6 py-4">{referal.count}</td>
                    <td className="px-6 py-4">{referal.referalCode}</td>
                    <td className="px-6 py-4">{referal.link}</td>
                    <td className="px-6 py-4">
                      <Button
                        onClick={(e) => {
                          getStudents(referal._id);
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {indivigualData && (
          <div className="w-3/5 ">
            <h1 className="mt-5 font-bold mx-2">{`${indivigualData.name} Referrals`}</h1>
            <div className="flex flex-wrap">
              {indivigualData.users.length !== 0 &&
                indivigualData.users.map((data) => (
                  <div className="w-1/3">
                    {" "}
                    <div className="px-2 py-3 mx-2 my-2 bg-yellow-400 shadow rounded ">
                      <div className="font-semibold ">{data.name}</div>
                      <div className="flex">
                        <div className="flex flex-col">
                          {/* <div>{data.phone_number}</div> */}
                          <div>{new Date(data.timeStamp).toLocaleString()}</div>
                        </div>
                        <div className="mx-auto text-sm">
                          <button
                            type="button"
                            className="bg-black text-white px-2 py-2 shadow rounded-lg"
                            onClick={() =>
                              loadIndivigualData(data.name, data._id)
                            }
                          >
                            Referrals
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {indivigualData.users.length === 0 && (
                <div className="mx-2">No Referrals</div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        open={view_modal}
        onClose={() => {
          setViewModal(false);
        }}
      >
        <div className="bg-white w-4/5 ">
          <table className="text-sm text-left text-gray-500 mx-5  block w-full">
            <thead className="text-xs text-white  bg-gray-700 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  User Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Number
                </th>
                <th scope="col" className="px-6 py-3">
                  {" "}
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Timestamp
                </th>
              </tr>
            </thead>
            {Students.map((student) => (
              <tr>
                <td className="px-6 py-4">{student.user_id}</td>
                <td className="px-6 py-4">{student.user_info.number}</td>
                <td className="px-6 py-4">
                  {student.user_info.profileInfo
                    ? student.user_info.profileInfo.name
                    : "User"}
                </td>
                <td className="px-6 py-4">{student.timestamp}</td>
              </tr>
            ))}
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default ReferalList;
