import axios from 'axios';

/* eslint-disable import/prefer-default-export */ 
export const sendNotificationToUser = async function (userId, title, message, save){
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users/notifications/${userId}`;
        const token = localStorage.getItem('authKey');
        const data = {title, message, save}
        const response = await axios.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

       return response;
    }
    catch (e) {
        console.log(e);
        return false;
    }
}
