import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from "../../components/headings/pageHeading";
import Modal from '../../components/modal/modal';


const ClubQuery = () => {

    const [query, setQuery] = useState({queries:[], next:false});
    const [isLoadig, setIsLoading] =  useState(false);
    const { clubId } = useParams();
    const [deleteData, setDeleteData] =  useState(null);
    const [notificationData, setNotificationData] = useState(null)

    const loadDeleteData = (index)=>{
        setDeleteData(query.queries[index]);
        console.log(index);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
    }
    
    const clearAlert= ()=>{
        setNotificationData(null);
    }

    const loadOpprtunity = async()=>{
        setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_BASE_URL}/clubs/admin/qna/${clubId}`;
                const token = localStorage.getItem('authKey');
                const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });
    
                setQuery(response.data)
                console.log(query, isLoadig);
    
    
            }
            catch (e) {
                console.log(e);
                setIsLoading(false)
            }
        setIsLoading(false)
    }

    useEffect(async()=>{
        loadOpprtunity();
    }, [])

    /* eslint-disable no-underscore-dangle */
    const deleteOppr =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/clubs/admin/qna/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.delete(url, {headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setNotificationData({message: 'Resource deleted', type:'good'});
            await loadOpprtunity();
            
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    setNotificationData({message: error.response.data.error, type:'bad'})
                }
                else
                setNotificationData({message:'Unable to delete', type:'bad'})
            }
        
            clearDeleteData();

    }

    return ( 
        <div className="query w-full">
            <PageHeading heading="Club Query" />
            
            <div className="grid grid-cols-4">
               
                {query&& query.queries.map((questions, index)=>(
                    <div className="query-card">

                        
                        <div className="p-4 m-2 shadow-md">

                        <div className="flex border-b-2	">
                            <div className="w-10 h-10">
                                <img src={questions.userInfo.personalInfo.avatar} alt="avatar" />
                            </div>
                            <div className='flex flex-col ml-3 mb-1'>
                                <div>{questions.userInfo.personalInfo.name}</div>
                                <div className='text-sm'>{questions.userInfo._id}</div>
                                
                                <div className='text-sm truncate' title={questions.userInfo.collegeInfo.collegeName}>{questions.userInfo.collegeInfo.collegeName.slice(0,36)}</div>
                            </div>
                        </div>
                        
                        <div className="flex font-bold mt-2">
                            <div className='truncate'title={questions.title}>{questions.title}</div>
                            {/* <div className="w-1/4 text-right"><a target='new' href={questions.file} className='text-sm shadow-sm rounded-md bg-black text-white px-2 py-1 inline-block'>{questions.format}</a></div> */}
                            </div>

                        <div className="flex">
                            <div className='w-2/4'>{`Votes ${questions.upvote} /  ${questions.downvote}` }</div>
                            <div className='w-2/4 text-right flex'>
                            {questions.replies>0&& <Link to={`/club/replies/${questions._id}`} className='shadow-sm rounded-md bg-yellow-400 text-black text-white px-2 py-1 ml-auto text-right mt-1'><div className='flex'><div className='mr-1'>Replies</div> </div></Link>}
                                <button type='button' onClick={()=>loadDeleteData(index)} className='shadow-sm rounded-md bg-black text-white px-2 py-1 ml-auto text-right text-sm'>Del</button>
                            </div>
                        </div>

                    
                        </div>
                        
                    </div>
            ))}
                
            </div>
            {deleteData && <Modal description={`Do you want to delete ${deleteData.title} ?`} onSuccess={deleteOppr} onCancel={clearDeleteData} /> }
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
        </div>
     );
}
 
export default ClubQuery;