import React, { useEffect, useState } from "react";
import PageHeading from "../../components/headings/pageHeading";
import axios from "axios";
import Modal from "../../components/modal/modal";

const IdCardVerification = () => {
  const [stat, setStat] = useState({});
  const [idcard, setIdCard] = useState(null);
  const [selected, setSelected] = useState(null);

  const loadStats = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL2}/anonymous/idcard/count`;

      const token = localStorage.getItem("beepsyAuthKey");

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setStat(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadIdCard = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL2}/anonymous/idcardVerification`;

      const token = localStorage.getItem("beepsyAuthKey");

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        // setStat(response.data.data)
        setIdCard(response.data.data[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeVerification = async (reqId, isAccepted) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL2}/anonymous/idcardVerification/${reqId}`;

      const token = localStorage.getItem("beepsyAuthKey");

      const response = await axios.patch(
        url,
        {
          isAccepted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        loadStats();
        loadIdCard();
        setSelected(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(loadStats, []);
  useEffect(loadIdCard, []);

  return (
    <div className="idcard-verificat">
      <PageHeading heading="ID Card verification" isBeepsy={true} />
      <div className="inner ">
        <div className="count m-4 bg-white/70">
          <div className="flex p-4">
            <div className="card">
              <div className="flex flex-col mx-4 p-4 border-l-4 shadow bg-white border-gray-700">
                <div className="font-bold text-xl">{stat.notProccessed}</div>
                <div className="font-bold text-sm">Pending</div>
              </div>
            </div>
            <div className="card">
              <div className="flex flex-col mx-4 p-4 border-l-4 shadow bg-white border-green-500">
                <div className="font-bold text-xl">{stat.accepted}</div>
                <div className="font-bold text-sm">Accepted</div>
              </div>
            </div>
            <div className="card">
              <div className="flex flex-col mx-4 p-4 border-l-4 shadow bg-white border-red-500">
                <div className="font-bold text-xl">{stat.rejected}</div>
                <div className="font-bold text-sm">Rejected</div>
              </div>
            </div>
          </div>
        </div>

        {idcard && (
          <div className="id-cards m-4 bg-white p-4 flex">
            <div className="p-4 ">
              <div className="flex mb-2">
                <div className="flex flex-col mr-4">
                  <div className="font-bold"> {idcard.UserInfo.profileInfo.name}</div>
                  <div className="text-sm">
                    {" "}
                    {idcard.UserInfo.profileInfo.gender == 0 ? "Female" : "Male"}
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="font-bold"> {idcard.CollegeInfo.collegeName}</div>
                  <div className="text-sm"> {idcard.CollegeInfo.collegeAlias}</div>
                </div>
              </div>

              <div className="id">
                <img src={idcard.idCardUrl} className="max-w-[500px] max-h-[500px]" alt="" />
              </div>
            </div>

            <div className="w-1/3 p-4 ml-auto mr-0">
              <button
                className="w-96 p-4  rounded my-2 font-bold bg-green-600 shadow text-white"
                onClick={() => setSelected({ reqId: idcard._id, isAccepted: true })}
              >
                Accept
              </button>
              <button
                className="w-96 p-4  rounded my-2 font-bold bg-red-600 shadow text-white"
                onClick={() => setSelected({ reqId: idcard._id, isAccepted: false })}
              >
                Reject
              </button>
            </div>
          </div>
        )}
      </div>
      {selected && (
        <Modal
          description={`Do you want to ${selected.isAccepted ? "Accept" : "Reject"}`}
          onSuccess={() => {
            changeVerification(selected.reqId, selected.isAccepted);
          }}
          onCancel={() => setSelected(null)}
        />
      )}
    </div>
  );
};

export default IdCardVerification;
