import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from '../../components/headings/pageHeading';
import Modal from '../../components/modal/modal';

const MarkIntern = () => {

    const [formData, setForm] = useState([]);
    const [users, setUsers] =  useState([]);
    const [alertData, setAlertData] = useState(null);
    const [markData, setMarkData] =  useState(null);
    const [internList, setInternList] =  useState([]);


    const clearAlert= ()=>{
        setAlertData(null);
    }

    const formChanges = (field, value) => {
        setForm(prev => ({
            ...prev,
            [field]: value
        }))

        console.log(formData)
    }
/* eslint-disable no-underscore-dangle */

    const onSubmit= async (field, type)=>{

        try{
            if(!formData[field]){
                setAlertData({message: `${field} is mandatory`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/user`;
    
            const token = localStorage.getItem('authKey');

            console.log({
                [field]:formData[field]
            })

            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`,   
            },
            params:{
                [field]:formData[field],
                limit: 200,
                skip: type==='more'?users.length:0
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'User list updated', type:'good'});
            if(type==='more')
            setUsers(prev=>[...prev, ...response.data.suggestions]);
            else{
            setUsers(response.data.suggestions);
            }
            console.log(response, users)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to get users', type:'bad'})
                }
                else
                setAlertData({message:'Unable to get users', type:'bad'})
            }
        
    }

    const loadMarkData = (index)=>{
        setMarkData(users[index]);
    }
    const loadMarkDataIntern = (index)=>{
        setMarkData(internList[index]);
    }

    const clearMarkData =  ()=>{
        setMarkData(null)
    }

    const getInternList =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/interns`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setInternList(response.data.suggestions)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable mark user as intern', type:'bad'})
                }
                else
                setAlertData({message:'Unable to Mark user as intern', type:'bad'})
            }
            clearMarkData();
    }

    const markUser =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/interns`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.post(url, { id: markData._id, isIntern:!markData.intern},{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setAlertData({message: 'Marked as Intern', type:'good'});
            onSubmit('id')
            getInternList()
            
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable mark user as intern', type:'bad'})
                }
                else
                setAlertData({message:'Unable to Mark user as intern', type:'bad'})
            }
        
            clearMarkData();

    }


    useEffect(async()=>{
        getInternList()
    },[])

    return (
        <div className='px-5 w-full'>
            <PageHeading heading="Make intern user" />
            <div className="flex flex-col border-b-2 border-yellow-400">
                <div className="flex flex-row py-2">    
                     
                    <div className="px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="User ID" id="userClg" name="userClg" onChange={data => formChanges("id", data.target.value)} /></div>
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit('id')}>Search By Id</button> </div>
                </div>
            </div>
            <div className="text-center">
                
                {users?(<div className='grid grid-cols-3 items-stretch justify-items-stretch' style={{gridAutoRows: '1fr', gridAutoCols:'1fr'}}>
                {users.map((user, index)=>(
                    
                    <div className="bg-yellow-400 px-4 py-4 mx-2 my-2 h-fit">
                    

                    <div className="flex">
                    <div className="w-1/6">
                        {user.personalInfo.avatar&&<img className="text-sm text-ellipsis whitespace-nowrap overflow-hidden h-10 w-10" src={user.personalInfo.avatar} alt="userprofile" />}
                    </div>
                    <div className="flex flex-col text-left w-4/6">
                    <div className='font-semibold bottom-0 text-ellipsis whitespace-nowrap overflow-hidden ' title={user.personalInfo.name}>{user.personalInfo.name}</div>
                    {user.created_at && <div className="text-sm text-ellipsis whitespace-nowrap overflow-hidden " >{new Date(user.created_at).toLocaleString()}</div>}
                    </div>
                    <div className='1/6'>{user.intern?
                            <button type='button' className='bg-red-600 text-white px-2 py-2 rounded shadow-sm' onClick={()=>loadMarkData(index)}>Remove Intern</button>:
                            <button type='button' className='bg-green-600 text-white px-2 py-2 rounded shadow-sm' onClick={()=>loadMarkData(index)}>Make Intern</button>}</div>
                    </div>
                    
                    
                    
                    <div className="flex pt-1">
                        {user.collegeInfo && <div className="flex flex-col text-left">
                            <div className="text-sm text-ellipsis w-11/12 whitespace-nowrap overflow-hidden font-semibold" title={user.collegeInfo.collegeName}>{user.collegeInfo.collegeName}</div>
                            <div className="text-sm">{`${user.collegeInfo.stream} - ${user.collegeInfo.passout}`}</div>
                        </div> }
                    </div>
                    </div>
                    
                ))}
                </div>):"No result"}
            
                    {internList.length!==0 && <div>
                    <table className="text-sm text-left text-gray-500 mx-2 my-4">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    College
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Batch
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            {internList && internList.map((user, index) =>
                                <tr className="bg-white border-b hover:bg-slate-100" >
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                        {user.personalInfo.name}
                                    </th>
                                    <td className="px-6 py-4">
                                    {user.collegeInfo.collegeName}
                                    </td>
                                    <td className="px-6 py-4">
                                        <span>{user.collegeInfo.stream}</span>
                                        <span>{user.collegeInfo.passout}</span>
                                    </td>
                                <td className="px-6 py-4">
                                    <button type='button' className='bg-red-600 text-white px-2 py-2 rounded shadow-sm' onClick={()=>loadMarkDataIntern(index)}>Remove</button>
                                </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                    </div>}
            </div>
                    
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            {markData && <Modal description={`Do you want to mark ${markData.personalInfo.name} ?`} onSuccess={markUser} onCancel={clearMarkData}  /> }
        </div>
    );
}

export default MarkIntern;