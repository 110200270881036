import React from "react";

import avatar1 from "../../assets/avatar/avatar_1.webp";
import avatar2 from "../../assets/avatar/avatar_2.webp";
import avatar3 from "../../assets/avatar/avatar_3.webp";
import avatar4 from "../../assets/avatar/avatar_4.webp";
import avatar5 from "../../assets/avatar/avatar_5.webp";
import avatar6 from "../../assets/avatar/avatar_6.webp";
import avatar7 from "../../assets/avatar/avatar_7.webp";
import avatar8 from "../../assets/avatar/avatar_8.webp";
import avatar9 from "../../assets/avatar/avatar_9.webp";
import avatar10 from "../../assets/avatar/avatar_10.webp";
import avatar11 from "../../assets/avatar/avatar_11.webp";
import avatar12 from "../../assets/avatar/avatar_12.webp";
import avatar13 from "../../assets/avatar/avatar_13.webp";
import avatar14 from "../../assets/avatar/avatar_14.webp";
import avatar15 from "../../assets/avatar/avatar_15.webp";
import avatar16 from "../../assets/avatar/avatar_16.webp";
import avatar17 from "../../assets/avatar/avatar_17.webp";
import avatar18 from "../../assets/avatar/avatar_18.webp";
import avatar19 from "../../assets/avatar/avatar_19.webp";
import avatar20 from "../../assets/avatar/avatar_20.webp";
import avatar21 from "../../assets/avatar/avatar_21.webp";
import avatar22 from "../../assets/avatar/avatar_22.webp";
import avatar23 from "../../assets/avatar/avatar_23.webp";
import avatar24 from "../../assets/avatar/avatar_24.webp";

export const getAvatar = (number) => {
  const imageData = {
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18,
    avatar19,
    avatar20,
    avatar21,
    avatar22,
    avatar23,
    avatar24,
  };

  return imageData[`avatar${number}`];
};
