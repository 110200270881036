import React, { useEffect, useState } from 'react'
import PageHeading from '../../components/headings/pageHeading'
import axios from 'axios'
import AlertWithClose from '../../components/alert/alertWithClose'
import Modal from '../../components/modal/modal'

const Banners = () => {
	const [bannerslist, setBannerslist] = useState([])
	const [jobForm, setJobForm] = useState({ job_id: '', banner_id: '' })
	const [bannerForm, setBannerForm] = useState({})
	const [alertData, setAlertData] = useState(null)
	const [deleteData, setDeleteData] = useState(null)

	const clerarAlert = () => setAlertData(null)

	const updateJobForm = e => {
		setJobForm(prev => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			}
		})
	}

	const addJobToBanner = async () => {
		const url = `${process.env.REACT_APP_BASE_URL}/admin/assign_job_to_banner`

		try {
			const response = await axios.post(url, jobForm, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('authKey'),
				},
			})

			if (response.data) {
				setJobForm({ job_id: '', banner_id: '' })
				setAlertData({ message: 'Job Added', type: 'good' })
			}
		} catch (error) {
			// console.log(error, error?.response?.data?.error)

			if (error?.response?.data?.error)
				setAlertData({ message: error?.response?.data?.error, type: 'bad' })
			else setAlertData({ message: 'Something went wrong', type: 'bad' })
		}
	}

	const loadBanners = async () => {
		const url = `${process.env.REACT_APP_BASE_URL}/admin/banner`

		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('authKey'),
			},
		})

		if (response.data) {
			setBannerslist(response.data.banners)
		}
	}

	useEffect(loadBanners, [])

	const onFormDataChange = e => {
		const { name, value, type, files } = e.target

		if (type === 'file') {
			setBannerForm(prev => ({
				...prev,
				[name]: files[0],
			}))
			onImageChange(e, name)
		} else
			setBannerForm(prev => ({
				...prev,
				[name]: value,
			}))
	}

	const onImageChange = (event, name) => {
		if (event.target.files && event.target.files[0]) {
			setBannerForm(prev => ({
				...prev,
				[`${name}Url`]: URL.createObjectURL(event.target.files[0]),
			}))
		} else {
			setBannerForm(prev => ({
				...prev,
				[`${name}Url`]: '',
			}))
		}
	}

	const addBanner = async () => {
		if (!bannerForm.image || !bannerForm.image2) {
			setAlertData({ message: 'Both images required', type: 'bad' })
			return
		}

		try {
			const url = `${process.env.REACT_APP_BASE_URL}/admin/create_banner`
			const reqFormData = new FormData()

			reqFormData.append('upload', bannerForm.image)
			reqFormData.append('upload', bannerForm.image2)
			// reqFormData.append('upload', bannerForm.image2)

			const token = localStorage.getItem('authKey')

			const response = await axios.post(url, reqFormData, {
				headers: {
					'content-type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			})

			if (response.status === 200) {
				setAlertData({ message: 'Banner Created', type: 'good' })
				loadBanners()
				setBannerForm({})
			}
		} catch (error) {
			if (error?.response?.data?.error)
				setAlertData({ message: error?.response?.data?.error, type: 'bad' })
			else setAlertData({ message: 'Something went wrong', type: 'bad' })
		}
	}

	const loadDeleteData = index => {
		setDeleteData(bannerslist[index])
	}

	const clearDeleteData = () => {
		setDeleteData(null)
	}

	const changeMerchandiseState = async () => {
		try {
			const url = `${process.env.REACT_APP_BASE_URL}/admin/banner/${deleteData._id}`

			const token = localStorage.getItem('authKey')

			const response = await axios.delete(
				url,

				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						id: deleteData._id,
					},
				}
			)

			if (response.status === 200) {
				setAlertData({ message: 'Banner State changed', type: 'good' })

				loadBanners()
			}
		} catch (error) {
			if (error.name === 'AxiosError') {
				setAlertData({ message: error.response.data.error, type: 'bad' })
			} else
				setAlertData({
					message: 'Unable to change banner state',
					type: 'bad',
				})
		}

		clearDeleteData()
	}

	return (
		<div className='banner'>
			<PageHeading heading='Banners' />

			<div className='createBanner mx-4 my-4 p-4 bg-white'>
				<h2 className='font-bold '>Add Job to banner</h2>
				<div className='flex flex-row'>
					<div className='py-3 mr-4'>
						<input
							type='text'
							name='job_id'
							className='block form-control px-2 w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
							placeholder='Enter Job Id'
							value={jobForm.job_id}
							onChange={updateJobForm}
						/>
					</div>
					<div className='py-3 mr-4'>
						<input
							type='text'
							name='banner_id'
							className='block form-control px-2 w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
							placeholder='Enter Banner Id'
							value={jobForm.banner_id}
							onChange={updateJobForm}
						/>
					</div>
					<div className='py-3 mr-4'>
						<button
							type='submit'
							onClick={addJobToBanner}
							className='shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96 bg-violet-600 text-white font-semibold'
						>
							Add Job
						</button>{' '}
					</div>
				</div>
			</div>

			<div className='createBanner mx-4 my-4 p-4 bg-white'>
				<h2 className='font-bold '>Create Banner</h2>
				<div className='flex flex-row'>
					<div className='py-3 mr-4'>
						<input
							type='file'
							name='image'
							className='block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
							placeholder='Enter Brand name'
							accept='image/*'
							onChange={onFormDataChange}
						/>
					</div>
					<div className='py-3 mr-4'>
						<input
							type='file'
							name='image2'
							className='block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
							placeholder='Enter Brand name'
							accept='image/*'
							onChange={onFormDataChange}
						/>
					</div>
					<div className='py-3 mr-4'>
						<button
							type='submit'
							onClick={addBanner}
							className='shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96 bg-violet-600 text-white font-semibold'
						>
							Add Banner
						</button>{' '}
					</div>
				</div>
				<div className='flex'>
					<div className='w-1/3'>
						<img src={bannerForm.imageUrl} alt='' className='w-1/4' />
					</div>
					<div className='w-1/3'>
						<img src={bannerForm.image2Url} alt='' className='w-1/4' />
					</div>
				</div>
			</div>
			{/* {formData && formData.imageUrl && <img className="h-24 px-5" src={formData.imageUrl} alt='Brand name' />} */}

			<table className='w-full text-sm text-left p-4 overflow-scroll inline-block'>
				<thead className='text-xs  uppercase bg-gray-50 '>
					<tr>
						<th scope='col' className='px-6 py-3'>
							Banner Id
						</th>
						<th scope='col' className='px-6 py-3'>
							Image 1
						</th>
						<th scope='col' className='px-6 py-3'>
							Image 2
						</th>
						<th scope='col' className='px-6 py-3'>
							Active
						</th>
					</tr>
				</thead>
				{/* eslint-disable no-underscore-dangle */}
				<tbody id='accordion-collapse' data-accordion='collapse'>
					{bannerslist.map((bannerData, index) => (
						<>
							<tr className={`bg-white border-b`} key={bannerData._id}>
								<th scope='row' className='px-6 py-4 font-medium '>
									{bannerData._id}
								</th>
								<td className='px-6 py-4'>
									<img src={bannerData.image} className=' w-1/6' />
								</td>
								<td className='px-6 py-4'>
									<img src={bannerData.image2} className=' w-1/6' />
								</td>
								<td className='px-6 py-4'>
									{bannerData.active && (
										<button
											type='submit'
											className={`${
												bannerData.active ? 'bg-rose-500 ' : 'bg-violet-500 '
											} shadow hover:shadow-lg focus:shadow-lg px-3 p-2 rounded w-24 text-white`}
											onClick={() => loadDeleteData(index)}
										>
											{bannerData.active ? 'Disable' : 'Enable'}
										</button>
									)}
									{!bannerData.active && <>Disabled</>}
								</td>
							</tr>
						</>
					))}
				</tbody>
			</table>

			{deleteData && (
				<Modal
					description={
						// deleteData.available
						`Do you want to  disable banner ?`
						// : `Do you want to activate ${deleteData.name} ?`
					}
					onSuccess={changeMerchandiseState}
					onCancel={clearDeleteData}
				/>
			)}

			{alertData && (
				<AlertWithClose
					message={alertData.message}
					type={alertData.type}
					time={4}
					clearAlert={clerarAlert}
				/>
			)}
		</div>
	)
}

export default Banners
