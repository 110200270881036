import React, { useEffect, useState } from 'react';
// import env from 'react-dotenv';
import axios from 'axios';

import PageHeading from '../../components/headings/pageHeading';
import SearchUser from './userDetails';
// import Modal from '../../components/modal/modal';


const ReportedUser = () => {

    const [referalData, setReferalList] = useState(null);
    // const [displayReferalData, setDisplayReferalList] = useState([]);
    const [indivigualData, setIndivigualData] = useState(null);
    const [reportOfAUser, setReportOfAUser] =useState(null);

    console.log(referalData);

    const loadReferals = async () => {

        try {
            const url = `${process.env.REACT_APP_BASE_URL}/users/reportUsers`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });
            console.log("response from data",response)
            setReferalList(response.data.response)
            // setDisplayReferalList(response.data.referrals);
            
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(async () => {
        loadReferals();
    }, [])

    // const sortByReferral = ()=>{
    //     console.log("On sort")
    //     const sortedList = [...referalData.referals]
    //     sortedList.sort((a,b) => a.referrals - b.referrals);
    //     console.log(sortedList)
    //     // setDisplayReferalList(sortedList);
    // }

    // const reverseList = ()=>{
    //     setDisplayReferalList(prev=>{
    //         const temp = [...prev];
    //         return temp.reverse()
    //     } );
    // }

    const loadIndivigualData = async(name,id, index)=>{
        try{

            setReportOfAUser(index);
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/usersrefered?user=${id}`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setIndivigualData({name, users:response.data.users})
            console.log(response);
            window.scrollTo(0, 0);
        }catch (e) {
            console.log(e);
        }
    }

/* eslint no-underscore-dangle: 0,jsx-a11y/no-noninteractive-element-interactions:0, jsx-a11y/click-events-have-key-events:0 */
    return (
        <div className="postList mx-0 w-full">

            <PageHeading heading="Referrals" />
            <div className="flex">

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-2/5">
                    <div className='mx-5 my-2'>
                        {/* <button className="bg-black text-white px-2 py-2 mr-2 shadow rounded" type='button' onClick={sortByReferral}>
                            Sort
                        </button>
                        <button className="bg-black text-white px-2 py-2 mr-2 shadow rounded" type='button' onClick={reverseList}>
                            Reverse
                        </button> */}
                        
                    </div>
                    <table className="text-sm text-left text-gray-500 mx-5 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                    Avatar
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    Phone No
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    Report Count
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Blocked
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            {referalData  && referalData.map((referal, index) =>
                                <tr className="bg-white border-b hover:bg-slate-100" onClick={()=>loadIndivigualData(referal.name, referal._id, index+1)}>
                                    
                                    <th scope="row" className="px-6 py-4  ">
                                        <div className="rounded w-8 h-8" >
                                            <img src={referal.userInfo[0].personalInfo.avatar} alt="user" />
                                        </div>
                                    </th>

                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                        {referal.userInfo[0].personalInfo.name}
                                    </th>
                                    {/* <td className="px-6 py-4">
                                        {referal.phone_number}
                                    </td> */}
                                    <td className="px-6 py-4">
                                        {referal.count}
                                    </td>
                                    <td className="px-6 py-4">
                                        {referal.userInfo[0].blocked?"Blocked":"No"}
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>

                <div className="w-3/5 ">
               {indivigualData &&  <div className="w-3/5 " >
                   <h1 className='mt-5 font-bold mx-2'>{`${indivigualData.name} Referrals`}</h1>
                   <div className="flex flex-wrap">
                                {(indivigualData.users.length!==0) && indivigualData.users.map(data=> <div className="w-1/3"> <div className='px-2 py-3 mx-2 my-2 bg-yellow-400 shadow rounded ' >
                                    <div className="font-semibold ">{data.name}</div>
                                    <div className="flex">
                                        <div className="flex flex-col">
                                        {/* <div>{data.phone_number}</div> */}
                                    <div>{new Date(data.timeStamp).toLocaleString()}</div>
                                        </div>
                                        <div className='mx-auto text-sm'><button type='button' className='bg-black text-white px-2 py-2 shadow rounded-lg' onClick={()=>loadIndivigualData(data.name, data._id)}>Referrals</button></div>
                                    </div>
                                </div></div>)} 
                                {(indivigualData.users.length===0)  && <div className='mx-2'>No Referrals</div>}
                                </div>
                </div> }

                {reportOfAUser && referalData[reportOfAUser-1] && referalData[reportOfAUser-1].reports&& (<>
                
                    <SearchUser userId={referalData[reportOfAUser-1]._id} refershFunc={loadReferals}/>
                <div className="data ml-4 mt-3">
                    <div className="heading font-bold">
                        Reports
                    </div>
                    {
                        referalData[reportOfAUser-1].reports.map((data, index)=><>
                            <div className="data mt-1">
                                {`${index+1}) ${data.reason}`}
                            </div>
                            </>)
                    }
                </div>
        
                </>)}
                </div>

            </div>

        </div>
    );
}

export default ReportedUser;