import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import PageHeading from "../../components/headings/pageHeading";
import AlertWithClose from '../../components/alert/alertWithClose';


const AddCollege = () => {

    const [formData, setFormData] = useState({});
    const [collegeData, setCollegeData]  =  useState(null)
    const [alertData, setAlertData] = useState(null);
   
    const clearAlert= ()=>{
        setAlertData(null);
    }

    const onFormDataChange=(name, value)=>{
        setFormData(prev=>({
            ...prev,
            [name]:value
        }))
    }

    const parseStreams =(data)=>{

        if(!data) {onFormDataChange("streams", [])}

        let streams = data.split(',');
        

        streams = streams.map(stream=>stream.trim());
        streams = streams.filter(stream=> stream);

        onFormDataChange("streams", streams)
    }

    const LoadCollegeData = async(collegeId)=>{

        const url = `${process.env.REACT_APP_BASE_URL}/requests/college/${collegeId}`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`
        }})

        console.log(response);

        if(response && response.data){
        setCollegeData(response.data.clgData);
        }
    }

    const onSubmit= async ()=>{

        try{
                if(!formData.collegeName){
                    setAlertData({message: `College Name is mandatory`, type:'bad'});
                    return;
                }

                if(!formData.alias){
                    setAlertData({message: `Alias is mandatory`, type:'bad'});
                    return;
                }

                if(!formData.streams){
                    setAlertData({message: `Streams is mandatory`, type:'bad'});
                    return;
                }

            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/college`;
    
            const token = localStorage.getItem('authKey');


            const response = await axios.put(url,formData,{headers: {
                Authorization: `Bearer ${token}`,   
            },
        } )
    
        if(response.status===200){
            setAlertData({message: 'College Added', type:'good'});
            console.log(response.data, response.data.clg.insertedId);
            LoadCollegeData(response.data.clg.insertedId)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to Add college', type:'bad'})
                }
                else
                setAlertData({message:'Unable to Add college', type:'bad'})
            }
        
    }


    /* eslint-disable no-underscore-dangle */

    return ( 
        <div className="w-full">
            <PageHeading heading="Add College" />

            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="CollegeName" className='px-4 py-2  text-lg'> College Name </label>
            <div className="px-4 "><input type="text" name='collegeName' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Eg) Indian Institute of Technology, Delhi"  onChange={e=>onFormDataChange(e.target.name, e.target.value)}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="alias" className='px-4 py-2  text-lg'> alias</label>
            <div className="px-4 "><input type="text" name='alias' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Eg) IIT Delhi"  onChange={e=>onFormDataChange(e.target.name, e.target.value)}/></div>
            </div>


            </div>

            <div className="flex">
            
            <div className="flex flex-col">
            <label htmlFor="Streams" className='px-4 py-2  text-lg'> Streams / Branches (Seperate by comma)</label>
            <div className="px-4 "><textarea name='Streams' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter streams seperated by comma" onChange={(e)=>parseStreams(e.target.value)} /></div>
            </div>

            <div className="flex flex-col px-4 my-2 overflow-scroll h-32 w-96">
            {formData &&formData.streams && formData.streams.map(data=>(
                <div className="text-sm ">
                    {data}
                </div>
            )   )}
            
            </div>
            </div>

            <div className="flex flex-row">  
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={onSubmit}>Create college</button> </div>
                </div>

                {collegeData && 
                
                <div className="flex flex-col mx-4 my-2">
                <div className="flex">
                
                <div className=" font-bold ">
                    {collegeData.collegeName} - {collegeData.alias} 

                </div>
                    
                <Link target="new" className="ml-2 bg-black text-white px-2 py-2 rounded shadow-sm  text-xs mr-2" to={`/college/${collegeData._id}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                </Link>

                </div>
                <div className="flex flex-col mt-4">
                {collegeData && collegeData.streams.map(stream=>
                <div>
                    {stream}
                </div>
                )}
                </div>
            </div>
                }



                { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
        </div>
     );
}
 
export default AddCollege;