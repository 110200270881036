import axios from "axios";
import React, { useState } from "react";

const BoostUpvote = ({ clear, userInfo, postId, LoadFeedRefresh }) => {
  const [upvoteCount, setUpvoteCount] = useState(null);

  const submitUpvote = async () => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/boostUpvote/${postId}`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.post(
      url,
      { count: upvoteCount },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      LoadFeedRefresh();

      clear();
    }
  };

  return (
    <div
      id="staticModal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      className="fixed  z-50     w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full bg-violet-400/50 backdrop-blur	"
    >
      <div className="relative w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">Boost Upvote</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              onClick={clear}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">clear modal</span>
            </button>
          </div>
          <div className="p-4 mx-auto text-center">
            <input
              type="number"
              name="upvoteCount"
              id=""
              value={upvoteCount}
              onChange={(e) => setUpvoteCount(e.target.value)}
              min={0}
              className="border rounded px-2 py-2"
              placeholder="Boost count"
            />
            <button
              className="px-2 py-2 mx-2 bg-violet-400 shadow rounded text-white"
              onClick={submitUpvote}
            >
              Boost
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostUpvote;
