import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Buffer} from 'buffer';

// import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';


const CreateRoom = ({loadRooms, roomData}) => {

    // const [rooms, setRooms] =  useState(null);
    const [formData, setForm] = useState({
        name:''
    });
    // const { clubId } = useParams();
    // const [rooms, setRooms] = useState([{}]);
    const [notificationData, setNotificationData] = useState(null);
    const [disableCreateButton, setDisableCreateButton] =  useState(false);
    
    // const [roomsList, setRoomsList] = useState([]);
    
    const clearAlert= ()=>{
        setNotificationData(null);
    }

    const parseCoupons =(value)=>{

        if(!formData.tags) {console.log("Error"); return;}

        let tagList =value?value.split(','):"";
        

        tagList = tagList.map(coupon=>coupon.trim());
        tagList = tagList.filter(coupons=> coupons);

        console.log(tagList)
        setForm(prev=>({
            ...prev,
            tagList,
            
        }))

        // setNotificationData({message: "Parsed data", type:'good'})
    }



    // const loadRooms = async()=>{
    //     try {
    //         const url = `${process.env.REACT_APP_BASE_URL}/clubs/roomses_admin/${clubId}`;
    //         const token = localStorage.getItem('authKey');
    //         const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

    //         setRoomsList(response.data.roomses )
    //         console.log(roomsList, response.data);


    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    /* eslint-disable  no-param-reassign */
    const onImageChange = (event) => {

        const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {

    // Initiate the JavaScript Image object.
    const image = new Image();

    // Set the Base64 string return from FileReader as source.
    image.src = e.target.result;

    // Validate the File Height and Width.
    image.onload = function () {
        const {height, width} = this;
        console.log(height, width)
        
        if(height> width) 
        {
            setNotificationData({message: "Image is not in landscape", type:'bad'})
            event.target.value=null
            setForm(prev => ({
                ...prev, 
            image: null
          }));
        }
        else if (event.target.files && event.target.files[0]) {
                setForm(prev => ({
                    ...prev, 
                imageUrl: URL.createObjectURL(event.target.files[0])
              }));
            }
        


    };
    };

       }

       useEffect(()=>{
        if(roomData)
        setForm({...roomData, isUpdate: true})
    },[roomData])

    //    const addRooms= ()=>{
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp.push({})
    //         return temp;
    //     })
    //    }
    
    // const roomsInputChange = (event, index) =>{
    //     const {name, value, type} = event.target;

    // if(type==='radio'){
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp[index][name.replace(index.toString(),"")] = value

    //         return temp;
    //     })
    //     return;
    // }
    //     setRooms(prev=>{
    //         const temp = [...prev];
    //         temp[index][name] = value

    //         return temp;
    //     })
    
    //     console.log(rooms);
    // }

    // useEffect(async()=>{
    //     loadRooms()
    // }, [])

    const formChanges = (e) => {
        const {name, value, files} = e.target;

        setForm(prev => ({
            ...prev,
            [name]: value
        }))
        if(name==='image'){
            setForm(prev => ({
                ...prev,
                [name]: files[0]
            }))
            onImageChange(e)
        }

        if(name==='tags')parseCoupons(value)

        console.log(e, name, value, formData)
    }
/* eslint-disable prefer-const */ 
    // const deleteQuestion = async(index)=>{
    //     console.log(index)
    //     setRooms(prev=>{
    //         let temp = [...prev];
    //         temp.splice(index, 1)
    //         // temp = temp.filter((data, indexTemp)=>{
    //         //     console.log(indexTemp, index, index!==indexTemp)
    //         //     return index!==indexTemp
    //         // })
    //         return temp
    //     })

          
    // }


const onSubmit =async ()=>{

    try{
        setDisableCreateButton(true);
        const reqFormData = new FormData();
        
        if(!formData){
            setNotificationData({message: 'Enter some data', type:'bad'}); return;
        }
        /* eslint-disable no-unneeded-ternary */
        const {name, startsAt, endsAt, image, tagList, isOpen, maximumParticipants, clubId, createdBy, description} = formData;
        if(!name){
            setNotificationData({message: 'Name is required', type:'bad'}); return;
        }
        if(!image){
            setNotificationData({message: 'image is required', type:'bad'}); return;
        }
        if(!startsAt){
            setNotificationData({message: 'Start time is required', type:'bad'}); return;
        }
        if(!endsAt){
            setNotificationData({message: 'End time is required', type:'bad'}); return;
        }
        

        if(! maximumParticipants ){
            setNotificationData({message: 'Maximum participents is required', type:'bad'}); return;
        }
        if(! createdBy ){
            setNotificationData({message: 'Enter created by User Id', type:'bad'}); return;
        }
        if(! isOpen ){
            setNotificationData({message: 'Select room type', type:'bad'}); return;
        }
        if(! description ){
            setNotificationData({message: 'Enter room description', type:'bad'}); return;
        }

        if(!tagList || !tagList.length){
            setNotificationData({message: 'Tags are required', type:'bad'}); return;
        }

        

        // console.log(questions);
            
        const tags64 = Buffer.from(JSON.stringify(tagList)).toString('base64');

        

        reqFormData.append('name', name);
        reqFormData.append('upload', image);
        reqFormData.append('startsAt', new Date(startsAt).toISOString());
        reqFormData.append('endsAt', new Date(endsAt).toISOString());
        reqFormData.append('isOpen', isOpen==='open'? true:false);
        reqFormData.append('tags64', tags64);
        if(clubId)reqFormData.append('clubId', clubId);
        reqFormData.append('createdBy', createdBy);
        reqFormData.append('description', description);
        reqFormData.append('maximumParticipants', maximumParticipants);
        

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)
        const url = `${process.env.REACT_APP_BASE_URL}/rooms/admin`;
        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Rooms created', type:'good'})
        await loadRooms()
    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
        }
        setDisableCreateButton(false);
    }

    const onUpdate =async ()=>{

        try{
            setDisableCreateButton(true);
            const reqFormData = new FormData();
            
            if(!formData){
                setNotificationData({message: 'Enter some data', type:'bad'}); return;
            }
            /* eslint-disable no-unneeded-ternary */
            const {name, _id, startsAt, endsAt, image, tags, isOpen, maximumParticipants, clubId, createdBy, description} = formData;
            if(!name){
                setNotificationData({message: 'Name is required', type:'bad'}); return;
            }
            
            if(!startsAt){
                setNotificationData({message: 'Start time is required', type:'bad'}); return;
            }
            if(!endsAt){
                setNotificationData({message: 'End time is required', type:'bad'}); return;
            }
            
    
            if(! maximumParticipants ){
                setNotificationData({message: 'Maximum participents is required', type:'bad'}); return;
            }
            if(! createdBy ){
                setNotificationData({message: 'Enter created by User Id', type:'bad'}); return;
            }
            if(! isOpen ){
                setNotificationData({message: 'Select room type', type:'bad'}); return;
            }
            if(! description ){
                setNotificationData({message: 'Enter room description', type:'bad'}); return;
            }
    
            if(!tags || !tags.length){
                setNotificationData({message: 'Tags are required', type:'bad'}); return;
            }
    
            
    
            // console.log(questions);
                
            const tags64 = Buffer.from(JSON.stringify(tags)).toString('base64');
    
            
    
            reqFormData.append('name', name);
            reqFormData.append('_id', _id);
            reqFormData.append('upload', image);
            reqFormData.append('startsAt', new Date(startsAt).toISOString());
            reqFormData.append('endsAt', new Date(endsAt).toISOString());
            reqFormData.append('isOpen', isOpen==='open'? true:false);
            reqFormData.append('tags64', tags64);
            if(clubId)reqFormData.append('clubId', clubId);
            reqFormData.append('createdBy', createdBy);
            reqFormData.append('description', description);
            reqFormData.append('maximumParticipants', maximumParticipants);
            
    
            console.log(reqFormData);
            const token = localStorage.getItem('authKey');
    
            console.log(reqFormData)
            const url = `${process.env.REACT_APP_BASE_URL}/rooms/update/admin/${_id}`;
            const response = await axios.put(url,reqFormData, {headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        } )
    
        console.log(response);
    
        if(response.status===200){
    
            setNotificationData({message: 'Rooms updated', type:'good'});
            await loadRooms();
            
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    
                    setNotificationData({message: error.response.data.error, type:'bad'})
                }
                console.log(error)
            }
            setDisableCreateButton(false);
        }

    return ( 

        <div className="createRooms w-full">

            {/* <PageHeading heading="Create Rooms" /> */}
        
            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="name" className='px-4 py-2'>Rooms Name</label>
                    <div className="px-4 py-1"><input type="text" name='name' value={formData.name} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Rooms name" onChange={formChanges} /></div>
            </div>

            
            <div className="flex flex-col">
                    <label htmlFor="image" className='px-4 py-2'> Rooms Image</label>
                    <div className="px-4 py-1"><input type="file" name='image' className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Enter Clubs name" accept="image/*" onChange={formChanges} /></div>
                    </div> 

                    {formData && formData.imageUrl && <img className="mt-2 h-24 px-5" src={formData.imageUrl} alt='Clubs name' />}

            </div>

            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="startsAt" className='px-4 py-2'>Start time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='startsAt' value={formData.startsAt} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>

            
            <div className="flex flex-col">
                    <label htmlFor="endsAt" className='px-4 py-2'>End Time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='endsAt' value={formData.endsAt} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>

            <div className="flex flex-col">
                    <label htmlFor="maximumParticipants" className='px-4 py-2'>Maximum participents</label>
                    <div className="px-4 py-1"><input type='number' name='maximumParticipants' value={formData.maximumParticipants} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Maximum participents" onChange={formChanges} /></div>
            </div>
            
            </div>

            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="createdBy" className='px-4 py-2'>Created By</label>
                    <div className="px-4 py-1"><input type="text" name='createdBy' value={formData.createdBy} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="UserId" onChange={formChanges} /></div>
            </div>
            <div className="flex flex-col">
                    <label htmlFor="clubId" className='px-4 py-2'>Club Id</label>
                    <div className="px-4 py-1"><input type="text" name='clubId' value={formData.clubId} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Club Id" onChange={formChanges} /></div>
            </div>
            <div className="flex flex-col">
            <label htmlFor="isOpen" className='px-4 py-2  text-lg'> Open / Closed Room </label>
            <div className="px-4 ">
                <select name='isOpen' value={formData.isOpen} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={formChanges}>
                <option  disabled selected >Select type of Room</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                </select>
                </div>
            </div>
            {/* <div className="flex flex-col">
                    <label htmlFor="duration" className='px-4 py-2'>Description</label>
                    <div className="px-4 py-1"><input type="text" name='description' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Duration in Minutes" onChange={formChanges} /></div>
            </div> */}
            </div>

            <div className="flex">

            <div className="flex flex-col">
            <label htmlFor="description" className='px-4 py-2  text-lg'> Description </label>
            <div className="px-4 "><textarea name='description' value={formData.description} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="description" onChange={formChanges} /></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="tags" className='px-4 py-2  text-lg'> Tags </label>
            <div className="px-4 "><textarea name='tags' value={formData.tags} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter tags seperated by comma" onChange={formChanges} /></div>
            </div>


            </div>

            <div>

            <div className="flex pb-5">
                {/* <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2" onClick={addRooms} >Add Question</button> </div> */}

                {!formData.isUpdate && <div className="px-4  mb-0 mt-auto ml-auto mr-0"><button type="submit" className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 " onClick={onSubmit} disabled={disableCreateButton}>Create room</button> </div>}
                {formData.isUpdate && <div className="px-4  mb-0 mt-auto ml-auto mr-0"><button type="submit" className="bg-violet-400 text-white font-medium shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 " onClick={onUpdate} disabled={disableCreateButton}>Update Room</button> </div>}
            </div>
               
            </div>
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
        </div>
     );
}
 
export default CreateRoom;