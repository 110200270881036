import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Buffer} from 'buffer';

import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';

const CreateQuiz = () => {

    // const [quiz, setQuiz] =  useState(null);
    const [formData, setForm] = useState(null);
    const { clubId } = useParams();
    const [quiz, setQuiz] = useState([{}]);
    const [notificationData, setNotificationData] = useState(null);
    const [quizList, setQuizList] = useState([]);
    
    const clearAlert= ()=>{
        setNotificationData(null);
    }


    const loadQuiz = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/clubs/quizes_admin/${clubId}`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setQuizList(response.data.quizes )
            console.log(quizList, response.data);


        }
        catch (e) {
            console.log(e);
        }
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setForm(prev => ({
                ...prev, 
            imageUrl: URL.createObjectURL(event.target.files[0])
          }));
        }
       }

       const addQuiz= ()=>{
        setQuiz(prev=>{
            const temp = [...prev];
            temp.push({})
            return temp;
        })
       }
    
    const quizInputChange = (event, index) =>{
        const {name, value, type} = event.target;

    if(type==='radio'){
        setQuiz(prev=>{
            const temp = [...prev];
            temp[index][name.replace(index.toString(),"")] = value

            return temp;
        })
        return;
    }
        setQuiz(prev=>{
            const temp = [...prev];
            temp[index][name] = value

            return temp;
        })
    
        console.log(quiz);
    }

    useEffect(async()=>{
        loadQuiz()
    }, [])
    const formChanges = (e) => {
        const {name, value, files} = e.target;

        setForm(prev => ({
            ...prev,
            [name]: value
        }))
        if(name==='image'){
            setForm(prev => ({
                ...prev,
                [name]: files[0]
            }))
            onImageChange(e)
        }
        console.log(e, name, value, formData)
    }
/* eslint-disable prefer-const */ 
    const deleteQuestion = async(index)=>{
        console.log(index)
        setQuiz(prev=>{
            let temp = [...prev];
            temp.splice(index, 1)
            // temp = temp.filter((data, indexTemp)=>{
            //     console.log(indexTemp, index, index!==indexTemp)
            //     return index!==indexTemp
            // })
            return temp
        })

          
    }

const onSubmit =async ()=>{

    try{
        const url = `${process.env.REACT_APP_BASE_URL}/clubs/quiz/${clubId}`;
        const reqFormData = new FormData();
        
        if(!formData){
            setNotificationData({message: 'Enter some data', type:'bad'}); return;
        }
        /* eslint-disable no-unneeded-ternary */
        const {name, startsAt, endsAt, image, duration, totalQuestions} = formData;
        if(!name){
            setNotificationData({message: 'Name is required', type:'bad'}); return;
        }

        if(!startsAt){
            setNotificationData({message: 'Start time is required', type:'bad'}); return;
        }
        if(!endsAt){
            setNotificationData({message: 'End time is required', type:'bad'}); return;
        }
        if(!image){
            setNotificationData({message: 'image is required', type:'bad'}); return;
        }

        if(!duration){
            setNotificationData({message: 'duration is required', type:'bad'}); return;
        }

        if(! totalQuestions ){
            setNotificationData({message: 'Total Questions is required', type:'bad'}); return;
        }

        if(totalQuestions > quiz.length ){
            setNotificationData({message: 'Totalquestions cannot be more than no of questions', type:'bad'}); return;
        }

      const questions= quiz.map((data, index)=> {

        const tempQuestion={choices:[], question:"", answer:""}
            if(!data.question){
            setNotificationData({message: `Question -${index+1} is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.question = data.question
            if(!data.choice1){
                setNotificationData({message: `Question - ${index+1} choice 1 is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.choices.push(data.choice1)

            if(!data.choice2){
                setNotificationData({message: `Question - ${index+1} choice 2 is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.choices.push(data.choice2)

            if(!data.choice3){
                setNotificationData({message: `Question - ${index+1} choice 3 is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.choices.push(data.choice3)

            if(!data.choice4){
                setNotificationData({message: `Question - ${index+1} choice 4 is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.choices.push(data.choice4)

            if(!data.answer){
                setNotificationData({message: `Question - ${index+1} answer is missing`, type:'bad'}); throw new Error("Value missing");
            }
            tempQuestion.correct = parseInt(data.answer, 10);

            return tempQuestion;
        })

        console.log(questions);
            
        const questionsBase64 = Buffer.from(JSON.stringify(questions)).toString('base64');

        

        reqFormData.append('name', name);
        reqFormData.append('upload', image);
        reqFormData.append('starts_at', new Date(startsAt).toISOString());
        reqFormData.append('ends_at', new Date(endsAt).toISOString());
        reqFormData.append('questions', questionsBase64);
        reqFormData.append('duration', parseInt(duration, 10)*60);
        reqFormData.append('totalQuestions', parseInt(totalQuestions, 10));
        

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)

        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Quiz created', type:'good'})
        
    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
        }
     
    }

    return ( 

        <div className="createQuiz w-full">

            <PageHeading heading="Create Quiz" />
        
            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="name" className='px-4 py-2'>Quiz Name</label>
                    <div className="px-4 py-1"><input type="text" name='name' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Quiz name" onChange={formChanges} /></div>
            </div>

            
            <div className="flex flex-col">
                    <label htmlFor="image" className='px-4 py-2'> Quiz Image</label>
                    <div className="px-4 py-1"><input type="file" name='image' className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Enter Clubs name" accept="image/*" onChange={formChanges} /></div>
                    </div> 

                    {formData && formData.imageUrl && <img className="mt-2 h-24 px-5" src={formData.imageUrl} alt='Clubs name' />}

            </div>

            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="startsAt" className='px-4 py-2'>Start time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='startsAt' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>

            
            <div className="flex flex-col">
                    <label htmlFor="endsAt" className='px-4 py-2'>End Time</label>
                    <div className="px-4 py-1"><input type='datetime-local' name='endsAt' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={formChanges} /></div>
            </div>
            <div className="flex flex-col">
                    <label htmlFor="duration" className='px-4 py-2'>Duration (in minutes)</label>
                    <div className="px-4 py-1"><input type="number" name='duration' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Duration in Minutes" onChange={formChanges} /></div>
            </div>
            </div>

            <div className="flex">
            <div className="flex flex-col">
                    <label htmlFor="totalQuestions" className='px-4 py-2'>Questions to be displayed to the user </label>
                    <div className="px-4 py-1"><input type="number" name='totalQuestions' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="No of questions" onChange={formChanges} /></div>
            </div>
            </div>

            <div>

            
        {quiz && quiz.map((data, index)=>(
                            <div className="question py-2">
                            <div className="flex">
                            <h3 className='font-semibold px-4'>Question - {index+1}</h3>
                            <button type='button' onClick={()=>deleteQuestion(index-1)}>Del</button>
                            </div>
                            <div className="">
                                <div className="flex flex-col">
                                {/* <label htmlFor="question" className='px-4 py-2 '>Questi</label> */}
                                <div className="px-4 py-1"><input type="text" name='question' className="form-input w-full rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300" placeholder="Enter Question" onChange={(e)=>quizInputChange(e, index)} /></div>
                                
                                <div className="options flex flex flex-col">
                                <div className="flex">
                                
                                <div className="px-2 py-2">
                                <input type="radio" className="form-check-input p-2 m-2 outline-2 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-yellow-400 checked:outline-yellow-400  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"id="option1" name={`answer${index}`} value="1" onChange={(e)=>quizInputChange(e, index)}/>
                                <input type="text" name='choice1' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-72 mx-2 bg-slate-300" placeholder="Option 1" onChange={(e)=>quizInputChange(e, index)} />
                                </div>
        
                                <div className="px-2 py-2">
                                <input type="radio" className="form-check-input p-2 m-2 outline-2 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-yellow-400 checked:outline-yellow-400  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"id="option2" name={`answer${index}`} value="2" onChange={(e)=>quizInputChange(e, index)} />
                                <input type="text" name='choice2' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-72 mx-2 bg-slate-300" placeholder="Option 2" onChange={(e)=>quizInputChange(e, index)} /> 
                                </div>
        
                                </div>
                                
                                <div className="flex">
                                <div className="px-2 py-2">
                                <input type="radio" className="form-check-input p-2 m-2 outline-2 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-yellow-400 checked:outline-yellow-400  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"id="option3" name={`answer${index}`} value="3" onChange={(e)=>quizInputChange(e, index)} />
                                <input type="text" name='choice3' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-72 mx-2 bg-slate-300" placeholder="Option 3" onChange={(e)=>quizInputChange(e, index)} />
                                </div>
        
                                <div className="px-2 py-2">
                                <input type="radio" className="form-check-input p-2 m-2 outline-2 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-yellow-400 checked:outline-yellow-400  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"id="option4" name={`answer${index}`} value="4" onChange={(e)=>quizInputChange(e, index)} />
                                <input type="text" name='choice4' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-72 mx-2 bg-slate-300" placeholder="Option 4" onChange={(e)=>quizInputChange(e, index)} />
                                </div></div>
        
                                </div>
        
        
        
                                </div>
                            </div>
                        </div>
        
        ))}

            <div className="flex pb-5">
                <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2" onClick={addQuiz} >Add Question</button> </div>

                <div className="px-4  mb-0 mt-auto ml-auto mr-0"><button type="submit" className="bg-green-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white" onClick={onSubmit} >Submit quiz</button> </div>
            </div>
                {(quizList && quizList.length!==0) && 
                <table className="table-fixed text-sm text-left text-slate-500 mx-5 mt-5 mb-8 shadow">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                    <tr>
                    {/* <th scope="col" className="px-6 py-3">
                            ID
                        </th> */}
                        <th scope="col" className="px-6 py-3">
                            Name of the quiz
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Starts At
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Ends At
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Created At
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Duration (In Minutes)
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Questions (listing) / (total)
                        </th>
                        <th scope="col" className="px-6 py-3 w-2/12">
                            Image
                        </th>
                     
                    </tr>
                </thead>
                <tbody>


                    {quizList && quizList.map((quizIndi, index) =>
                        <tr className={`bg-white border-b hover:bg-slate-200 ${index%2===0?'': 'bg-slate-100'}`}>
                            <th scope="row" className="px-6 py-4  ">
                                {quizIndi.quiz_name}
                            </th>
                            <td className="px-6 py-4">
                                {new Date(quizIndi.starts_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4">
                            {new Date(quizIndi.ends_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4">
                                {new Date(quizIndi.created_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4">
                            {parseInt(quizIndi.duration, 10) / 60}
                            </td>
                            <td className="px-6 py-4">
                            {`${quizIndi.totalQuestions} / ${quizIndi.questions.length }`}
                            </td>
                           
                            <td className="px-6 py-4 h-10 ">
                                {quizIndi.file?
                                
                                <img src={quizIndi.file} alt={quizIndi.quiz_name} className='h-10'/> 
                                
                                : <span>No Image</span>}
                            </td>

                        </tr>
                    )}

                </tbody>
            </table>
                }
            </div>
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
        </div>
     );
}
 
export default CreateQuiz;