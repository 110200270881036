import React, { useEffect, useRef, useState } from "react";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import { Buffer } from "buffer";

// import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from "../../components/alert/alertWithClose";
import PageHeading from "../../components/headings/pageHeading";
import moment from "moment";
import "moment-timezone";
import Modal from "../../components/modal/modal";
import Select from "react-select";

const CreateRoomDyte = ({ roomData }) => {
  // const [rooms, setRooms] =  useState(null);
  const [formData, setForm] = useState({
    name: "",
  });
  // const { clubId } = useParams();
  const [rooms, setRooms] = useState([]);
  const [notificationData, setNotificationData] = useState(null);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [deleteRoomIndex, setDeleteRoomIndex] = useState(null);
  const downloadAnchor = useRef(null);

  const [skills, setSkills] = useState([]);

  // const [roomsList, setRoomsList] = useState([]);

  const clearAlert = () => {
    setNotificationData(null);
  };

  const parseCoupons = (value) => {
    if (!formData.tags) {
      console.log("Error");
      return;
    }

    let tagList = value ? value.split(",") : "";

    tagList = tagList.map((coupon) => coupon.trim());
    tagList = tagList.filter((coupons) => coupons);

    console.log(tagList);
    setForm((prev) => ({
      ...prev,
      tagList,
    }));

    // setNotificationData({message: "Parsed data", type:'good'})
  };

  const loadRooms = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/rooms`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setRooms(response.data);
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const loadSkills = async() => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/user/skills`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit:2000
        }
      });

      setSkills(response.data.skills.map((data)=>{return{label:data.skill, value:data.skill}}));
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(()=>{
    loadRooms()
    loadSkills()
  }, []);

  /* eslint-disable  no-param-reassign */
  const onImageChange = (event) => {
    const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {
      // Initiate the JavaScript Image object.
      const image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this;
        console.log(height, width);

        if (height > width) {
          setNotificationData({
            message: "Image is not in landscape",
            type: "bad",
          });
          event.target.value = null;
          setForm((prev) => ({
            ...prev,
            image: null,
          }));
        } else if (event.target.files && event.target.files[0]) {
          setForm((prev) => ({
            ...prev,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          }));
        }
      };
    };
  };

  useEffect(() => {
    if (roomData) setForm({ ...roomData, isUpdate: true });
  }, [roomData]);

  //    const addRooms= ()=>{
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp.push({})
  //         return temp;
  //     })
  //    }

  // const roomsInputChange = (event, index) =>{
  //     const {name, value, type} = event.target;

  // if(type==='radio'){
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp[index][name.replace(index.toString(),"")] = value

  //         return temp;
  //     })
  //     return;
  // }
  //     setRooms(prev=>{
  //         const temp = [...prev];
  //         temp[index][name] = value

  //         return temp;
  //     })

  //     console.log(rooms);
  // }

  // useEffect(async()=>{
  //     loadRooms()
  // }, [])

  const formChanges = (e) => {
    console.log(e);
    let { name, value, files } = e.target;
    console.log(value)
    // if(name==="startTime" || name==="endTime") value = new Date(value).toISOString();
    
    if(name === "startTime" || name === "endTime") {
      let samvalue = moment(value).tz("Asia/Kolkata").format("yyyy-MM-DDTHH:mm");
      setForm((prev) => ({
        ...prev,
        [name+"_1"]: samvalue,
      }));
    }
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "image") {
      setForm((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      onImageChange(e);
    }

    if (name === "tags") parseCoupons(value);

    // console.log(e, name, value, formData);
  };
  /* eslint-disable prefer-const */
  // const deleteQuestion = async(index)=>{
  //     console.log(index)
  //     setRooms(prev=>{
  //         let temp = [...prev];
  //         temp.splice(index, 1)
  //         // temp = temp.filter((data, indexTemp)=>{
  //         //     console.log(indexTemp, index, index!==indexTemp)
  //         //     return index!==indexTemp
  //         // })
  //         return temp
  //     })

  // }

  const onSubmit = async () => {
    try {
      setDisableCreateButton(true);
      const reqFormData = new FormData();

      if (!formData) {
        setNotificationData({ message: "Enter some data", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      /* eslint-disable no-unneeded-ternary */
      const {
        title,
        startTime,
        endTime,
        image,
        seatsAvailable,
        link,
        skills,
        tags,
        description,
        hostNumber,
        isUpdate,
        _id
      } = formData;
      if (!title) {
        setNotificationData({ message: "tITLE is required", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      if (!isUpdate && !image) {
        setNotificationData({ message: "image is required", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      if (!startTime) {
        setNotificationData({ message: "Start time is required", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      if (!endTime) {
        setNotificationData({ message: "End time is required", type: "bad" });
        setDisableCreateButton(false);
        return;
      }

      if (new Date(startTime) <= new Date()) {
        setNotificationData({ message: "Start time must be greater than current time", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      if (new Date(endTime) <= new Date(startTime)) {
        setNotificationData({ message: "End time must be greater than start time", type: "bad" });
        setDisableCreateButton(false);
        return;
      }

      if (!description) {
        setNotificationData({ message: "Enter room description", type: "bad" });
        setDisableCreateButton(false);
        return;
      }

      if (!hostNumber || hostNumber.length != 10) {
        setNotificationData({ message: "Invalid host number", type: "bad" });
        setDisableCreateButton(false);
        return;
      }
      let trending = false;
      if (formData.trending && formData.trending === "true") {
        trending = true;
      }

      reqFormData.append("title", title);
      reqFormData.append("upload", image);
      reqFormData.append("startTime", new Date(startTime).toISOString());
      reqFormData.append("endTime", new Date(endTime).toISOString());
      if (seatsAvailable)
        reqFormData.append("seatsAvailable", parseInt(seatsAvailable));
      if (link) reqFormData.append("link", link);
      reqFormData.append("skills", skills ?? "");
      reqFormData.append("tags", tags ?? "");

      reqFormData.append("description", description);
      reqFormData.append("hostNumber", hostNumber);
      reqFormData.append("trending", trending);
      console.log(reqFormData);
      const token = localStorage.getItem("authKey");

      console.log(reqFormData);
      const url = `${process.env.REACT_APP_BASE_URL}/admin/${isUpdate?"update_audio_room/"+_id:"create_audio_room"}`;
      const response = await axios.post(url, reqFormData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response);

      if (response.status === 200) {
        setNotificationData({ message: "Rooms created", type: "good" });
        setForm({
          title:"",
          imageUrl:"",
          startTime:"",
          endTime:"",
          seatsAvailable:"",
          hostNumber:"",
          link:"",
          isOpen:"",
          description:"",
          skills:"",
          tags:"",
          trending:"",
          startTime_1:"",
          endTime_1:"",
          image:null,
          skillString:[]
        });
        await loadRooms();
        setForm({});
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      }
      console.log(error);
    }
    setDisableCreateButton(false);
    
  };

  const editRoom = (index) => {
    const startTime_1 = moment(rooms[index].startTime).tz("Asia/Kolkata").format("yyyy-MM-DDTHH:mm");
    const endTime_1 = moment(rooms[index].startTime).tz("Asia/Kolkata").format("yyyy-MM-DDTHH:mm");

    if(rooms[index].skills)
      console.log(rooms[index].skills.map(val=>({value:val, label:val})))
    skillChange(rooms[index].skills.map(val=>({value:val, label:val})));
    setForm({ ...rooms[index], isUpdate: true, startTime_1, endTime_1 });
  }

  const deleteRoom = async () => {
    try {
      const token = localStorage.getItem("authKey");

      const url = `${process.env.REACT_APP_BASE_URL}/admin/room/${rooms[deleteRoomIndex]._id}`;
      const response = await axios.delete(url, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setNotificationData({ message: "Room Delted", type: "good" });
        await loadRooms();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setNotificationData({
          message: error.response.data.error,
          type: "bad",
        });
      }
      console.log(error);
    }
    setDisableCreateButton(false);
    setDeleteRoomIndex(null);
  };

  const downloadFile = async (index) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/admin/audioroom/users/${rooms[index]._id}`;
        const token = localStorage.getItem("authKey");

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const urlF = URL.createObjectURL(blob);
        downloadAnchor.current.href = urlF;
        downloadAnchor.current.download = `${rooms[index].title}.csv`;
        downloadAnchor.current.click();
        URL.revokeObjectURL(urlF);
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const skillChange = (values) =>{
    console.log(values)
    const skillString = values.map(val=>val.value).join(",");

    setForm((prev)=>({
      ...prev,
      skills: skillString,
      skillsArray: values
    }))
  }

  return (
    <div className="createRoomDytes w-full">
      <PageHeading heading="Create Rooms" />

      <div className="flex">
        <div className="flex flex-col">
          <label htmlFor="name" className="px-4 py-2">
            Rooms Name
          </label>
          <div className="px-4 py-1">
            <input
              type="text"
              name="title"
              value={formData.title}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Enter Rooms name"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="image" className="px-4 py-2">
            {" "}
            Rooms Image
          </label>
          <div className="px-4 py-1">
            <input
              type="file"
              name="image"
              className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Enter Clubs name"
              accept="image/*"
              onChange={formChanges}
            />
          </div>
        </div>

        {formData && (formData.imageUrl||formData.image) && (
          <img
            className="mt-2 h-24 px-5"
            src={formData.imageUrl || formData.image}
            alt="Clubs name"
          />
        )}
      </div>

      <div className="flex">
        <div className="flex flex-col">
          <label htmlFor="startsAt" className="px-4 py-2">
            Start time
          </label>
          <div className="px-4 py-1">
            <input
              type="datetime-local"
              name="startTime"
              value={formData.startTime_1}
              // min={new Date().toISOString().slice(0, 16)}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Select time"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="endsAt" className="px-4 py-2">
            End Time
          </label>
          <div className="px-4 py-1">
            <input
              type="datetime-local"
              name="endTime"
              // min={formData.startTime || new Date().toISOString().slice(0, 16)}
              disabled={!formData.startTime}
              value={formData.endTime_1}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Select time"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="maximumParticipants" className="px-4 py-2">
            Maximum participents (opt)
          </label>
          <div className="px-4 py-1">
            <input
              type="number"
              name="seatsAvailable"
              value={formData.seatsAvailable}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Maximum participents"
              onChange={formChanges}
            />
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col">
          <label htmlFor="createdBy" className="px-4 py-2">
            Host mobile number
          </label>
          <div className="px-4 py-1">
            <input
              type="text"
              name="hostNumber"
              value={formData.hostNumber}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="UserId"
              onChange={formChanges}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="clubId" className="px-4 py-2">
            Link(opt)
          </label>
          <div className="px-4 py-1">
            <input
              type="text"
              name="link"
              value={formData.link}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Google meet / zoom link"
              onChange={formChanges}
            />
          </div>
        </div>
        {/* <div className="flex flex-col">
            <label htmlFor="isOpen" className='px-4 py-2  text-lg'> Open / Closed Room </label>
            <div className="px-4 ">
                <select name='isOpen' value={formData.isOpen} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={formChanges}>
                <option  disabled selected >Select type of Room</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                </select>
                </div>
            </div> */}
        {/* <div className="flex flex-col">
                    <label htmlFor="duration" className='px-4 py-2'>Description</label>
                    <div className="px-4 py-1"><input type="text" name='description' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Duration in Minutes" onChange={formChanges} /></div>
            </div> */}
      </div>

      <div className="flex w-full">
        <div className="flex flex-col">
          <label htmlFor="description" className="px-4 py-2  text-lg">
            {" "}
            Description{" "}
          </label>
          <div className="px-4 ">
            <textarea
              name="description"
              value={formData.description}
              className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"
              placeholder="description"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="tags" className="px-4 py-2  text-lg">
            {" "}
            skills (Seperate by comma){" "}
          </label>
          <div className="px-4 ">
            <textarea
              name="skills"
              value={formData.skills}
              className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3 disabled:cursor-not-allowed"
              placeholder="Enter skills seperated by comma"
              disabled={true}
              onChange={formChanges}
            />

<Select
    isMulti
    name="skills"
    options={skills}
    value={formData.skillsArray}
    className="basic-multi-select"
    classNamePrefix="select"
    defaultValue={formData.skillsArray}
    onChange={(e)=>skillChange(e)}
  />

          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="tags" className="px-4 py-2  text-lg">
            {" "}
            Tags (Seperate by comma)
          </label>
          <div className="px-4 ">
            <textarea
              name="tags"
              value={formData.tags}
              className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"
              placeholder="Enter tags seperated by comma"
              onChange={formChanges}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="tags" className="px-4 py-2  text-lg">
            {" "}
            Trending
          </label>
          <div className="px-4 ">
            <select
              name="trending"
              value={formData.trending}
              className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"
              onChange={formChanges}
            >
              <option disabled selected>
                Trending
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div className="flex pb-5">
          {/* <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2" onClick={addRooms} >Add Question</button> </div> */}

          { (
            <div className="px-4  mb-0 mt-auto ml-auto mr-0">
              <button
                type="button"
                className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 "
                onClick={onSubmit}
                disabled={disableCreateButton}
              >
                {formData.isUpdate ? "Update":"Create room"}
              </button>{" "}
            </div>
          )}
        </div>

        <div className="w-full">
          <table className=" bg-slate-400 px-4 mx-4 overflow-scroll w-full">
            <thead className=" bg-slate-800 text-white">
              <th>Title</th>
              <th>Description</th>
              <th>Start</th>
              <th>End</th>
              <th>Host number</th>
              <th>Seats Available</th>
              <th>Seats Rejistered</th>
              <th>Skills</th>
              <th>Tags</th>
              <th>Edit</th>
              <th>Delete</th>
            </thead>

            <tbody>
              {rooms.map((data, index) => (
                <tr
                  className={`${
                    index % 2 == 0 ? " bg-slate-300 " : " bg-slate-200 "
                  } overflow-scroll`}
                >
                  <td className="py-2 flex">
                    <button  onClick={()=>downloadFile(index)} className=" p-3 bg-violet-500 shadow rounded mr-2 text-white scale-75"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
  </button>
                    <div className="">{data.title}</div>
                  </td>
                  <td title={data.description}>
                    {data.description
                      ? data.description.slice(0, 5) + "..."
                      : ""}
                  </td>
                  <td className="px-2">
                    {moment(data.startTime).tz("Asia/Kolkata").format("DD/MM - hh:mm a")}
                  </td>
                  <td className="px-2">
                    {moment(data.endTime).tz("Asia/Kolkata").format("DD/MM - hh:mm a")}
                  </td>
                  <td className="px-2">{data.hostedByInfo?.name}</td>
                  <td className="px-2">{data.seatsAvailable}</td>
                  <td className="px-2">{data.seatsRegistered}</td>
                  <td className="px-2" title={data.skills.join(", ")}>{data.skills.join(", ").slice(0, 5) + "..."}</td>
                  <td className="px-2" title={data.tags.join(", ")}>{data.tags.join(", ").slice(0, 5) + "..."}</td>
                  <td>
                    {" "}
                    <button
                      className="bg-violet-500 text-white px-2 py-2 rounded shadow"
                      onClick={() => editRoom(index)}
                    >
                      {" "}
                      Edit
                    </button>
                  </td>
                  <td>
                    {" "}
                    <button
                      className="bg-red-500 text-white px-2 py-2 rounded shadow"
                      onClick={() => setDeleteRoomIndex(index)}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {deleteRoomIndex != null && (
        <Modal
          description={`Do you want to delete  ${rooms[deleteRoomIndex].title}?`}
          onCancel={() => setDeleteRoomIndex(null)}
          onSuccess={deleteRoom}
        />
      )}
      {notificationData && (
        <AlertWithClose
          clearAlert={clearAlert}
          message={notificationData.message}
          time={4}
          type={notificationData.type}
        />
      )}
      <a ref={downloadAnchor}></a>
    </div>

  );
};

export default CreateRoomDyte;


