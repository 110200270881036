import axios from "axios";
import React from "react";

const DeleteModal = ({ clear, postData, LoadFeedRefresh }) => {
  const deleteCommnet = async (commnetId) => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/post/${postData._id}`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      LoadFeedRefresh();
      clear();
    }
  };

  return (
    <div
      id="staticModal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      className="fixed  z-50     w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full bg-violet-400/50 backdrop-blur	"
    >
      <div className="relative w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">Delete Post</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              onClick={clear}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Delete Post</span>
            </button>
          </div>
          <div className="m-4 pb-4 text-center">
            <div>
              Do you wan tot delete the post created by{" "}
              <span className="name font-bold">{postData?.userInfo?.profileInfo?.name}</span>?
            </div>

            <div className="flex mx-auto text-center justify-center ">
              <div>
                <button
                  className="p-2 my-1 bg-violet-400 rounded inline-block text-center text-white mx-2 self-center"
                  onClick={clear}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="p-2 my-1 bg-red-600 rounded inline-block text-white mx-2 self-center"
                  onClick={deleteCommnet}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
