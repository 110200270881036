import React, { useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from '../../components/headings/pageHeading';


const CollegeSearch = () => {

    const [formData, setForm] = useState([]);
    const [colleges, setColleges] =  useState([]);
    const [lastField, setLastField] = useState(null)
    const [alertData, setAlertData] = useState(null);
   
    const clearAlert= ()=>{
        setAlertData(null);
    }

    const formChanges = (field, value) => {
        setForm(prev => ({
            ...prev,
            [field]: value
        }))

        console.log(formData)
    }
/* eslint-disable no-underscore-dangle */

    const onSubmit= async (field, type)=>{

        try{
            if(!formData[field]){
                setAlertData({message: `${field} is mandatory`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/colleges`;
    
            const token = localStorage.getItem('authKey');

            console.log({
                [field]:formData[field]
            })

            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`,   
            },
            params:{
                [field]:formData[field],
                limit: 200,
                skip: type==='more'?colleges.length:0
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'College list updated', type:'good'});
            if(type==='more')
            setColleges(prev=>[...prev, ...response.data.suggestions]);
            else{
            setLastField(field);
            setColleges(response.data.colleges);
            }
            console.log(response, colleges)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to get colleges', type:'bad'})
                }
                else
                setAlertData({message:'Unable to get colleges', type:'bad'})
            }
        
    }


    return (
        <div className='w-full'>
            
            <div className="w-full flex"> 
            <div className='w-5/6'><PageHeading heading="Search Colleges" className="" /> </div>
            
            <div className="shadow py-1 w-1/6">
                <div className=' mx-4  mt-4 '>
                <Link to="/college/add" className='p-2 bg-gray-200 hover:bg-gray-300 mx-1'>Add</Link>
                <Link to="/request" className='p-2 bg-gray-200 hover:bg-gray-300 mx-1'>Requests</Link>
                </div>
            </div>
            </div>

            <div className="flex flex-col border-b-2 border-yellow-400">
                <div className="flex flex-row">
                    <div className="basis-1/6 px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Name" id="collegeName" name="collegeName" onChange={data => formChanges("name", data.target.value)} /></div>
                    {/* <div className="basis-1/6 px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Phone No" id="collegePhone" name="collegePhone" onChange={data => formChanges("phoneNo", data.target.value)} /></div> */}
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit('name')}>Search By Name</button> </div>

                </div>
                <div className="flex flex-row">    
                     
                    <div className="px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="College ID" id="collegeClg" name="collegeClg" onChange={data => formChanges("id", data.target.value)} /></div>
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit('id')}>Search By Id</button> </div>
                </div>


                {/* <div className="flex-1 px-4 py-3"><input type="text" className ="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Name" id="collegeName" name="collegeName" onChange={data=>formChanges("email", data.target.value)}/></div> */}
            </div>
            <div className="text-center">
                
                {colleges?(<div className='grid grid-cols-3 items-stretch justify-items-stretch' style={{gridAutoRows: '1fr', gridAutoCols:'1fr'}}>
                {colleges.map((college)=>(
                    
                    <div className="bg-yellow-400 px-4 py-4 mx-2 my-2 h-fit">
                    
{/* 
                    <div className="flex">
                    <div className="w-1/6">
                
                    </div>
                    <div className="flex flex-col text-left w-4/6"> */}
                    <div className="flex">
                    <div className='font-semibold bottom-0 text-ellipsis whitespace-nowrap overflow-hidden text-left w-5/6' title={college.collegeName}>{college.collegeName}</div>
                    {/* <div className="w-1/6"> */}
                    <Link target="new" className="ml-2 bg-black text-white px-2 py-2 rounded shadow-sm  text-xs mr-2" to={`/college/${college._id}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                    </Link>
                {/* </div>   */}
                    
                    </div>
                    <div className="text-sm text-ellipsis whitespace-nowrap overflow-hidden text-left" >{college._id}</div>
                    
                    {/* </div>
                    <div className='1/6'>{college.blocked?<div className='mx-2'>Blocked</div>: <button type='button' className='bg-black text-yellow-400 px-2 py-2 rounded shadow-sm' onClick={()=>loadBlockData(index)}>Ban</button>}</div>
                    </div>
                    
                    
                    
                    <div className="flex pt-1">
                        {college.collegeInfo && <div className="flex flex-col text-left">
                            <div className="text-sm text-ellipsis w-11/12 whitespace-nowrap overflow-hidden font-semibold" title={college.collegeInfo.collegeName}>{college.collegeInfo.collegeName}</div>
                            <div className="text-sm">{`${college.collegeInfo.stream} - ${college.collegeInfo.passout}`}</div>
                        </div> }
                    </div> */}
                    </div>
                    
                ))}
                </div>):"No result"}
                {colleges && colleges.length>199 &&colleges.length%200===0 && <div className="px-4 pb-5"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit(lastField, "more")}>Load More</button> </div>}
            </div>
                    
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            
        </div>
    );
}

export default CollegeSearch;