import axios from "axios";
import React from "react";

const BanModal = ({ clearBan, userInfo, LoadFeedRefresh }) => {
  const banUser = async (days) => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/ban/${userInfo._id}`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.post(
      url,
      { days },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      LoadFeedRefresh();
      clearBan();
    }
  };

  return (
    <div
      id="staticModal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      className="fixed  z-50     w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full bg-violet-400/50 backdrop-blur	"
    >
      <div className="relative w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">Ban User</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              onClick={clearBan}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="m-4 pb-4 text-center">
            <div>
              <div className="name font-bold">{userInfo?.profileInfo?.name}</div>
              <div className="clg">{userInfo?.campusInfo?.collegeAlias}</div>
            </div>

            <div className="flex flex-col">
              <div>
                <button className="p-2 my-1 bg-violet-400 rounded inline-block text-white">
                  Send Warning
                </button>
              </div>
              <div>
                <button
                  className="p-2 my-1 bg-violet-400 rounded inline-block text-white"
                  onClick={() => banUser(1)}
                >
                  Suspend 1 Day
                </button>
              </div>
              <div>
                <button
                  className="p-2 my-1 bg-violet-400 rounded inline-block text-white"
                  onClick={() => banUser(3)}
                >
                  Suspend 3 Day
                </button>
              </div>
              <div>
                <button
                  className="p-2 my-1 bg-violet-400 rounded inline-block text-white"
                  onClick={() => banUser(7)}
                >
                  Suspend 7 Day
                </button>
              </div>
              <div>
                <button
                  className="p-2 my-1 bg-red-600 rounded inline-block text-white"
                  onClick={() => banUser(200000)}
                >
                  Ban
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanModal;
