import React from 'react'

const Modal = ({ description, onSuccess, onCancel }) => (
	<div
		id='popup-modal'
		tabIndex='-1'
		className='overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full'
		style={{ backgroundColor: `rgba(0,0,0,0.5)` }}
	>
		<div
			className='relative p-4 w-full max-w-md h-full md:h-auto opacity-100'
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
		>
			<div className='relative rounded-lg shadow bg-white'>
				<button
					type='button'
					className='absolute top-3 right-2.5 text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
					data-modal-toggle='popup-modal'
					onClick={onCancel}
				>
					<svg
						className='w-5 h-5'
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
							clipRule='evenodd'
						/>
					</svg>
				</button>
				<div className='p-6 text-center'>
					<svg
						className='mx-auto mb-4 w-14 h-14 '
						fill='none'
						stroke='currentColor'
						viewBox='0 0 24 24'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
						/>
					</svg>
					<h3 className='mb-5 text-lg text-slate-900 font-normal'>{description}</h3>
					<button
						data-modal-toggle='popup-modal'
						type='button'
						className='text-white bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
						onClick={onSuccess}
					>
						Yes Im sure
					</button>
					<button
						data-modal-toggle='popup-modal'
						type='button'
						className='hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
						onClick={onCancel}
					>
						No, cancel
					</button>
				</div>
			</div>
		</div>
	</div>
)

export default Modal
