import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from '../../components/headings/pageHeading';

const EditCollege = () => {

    const [formData, setFormData] = useState({"type":"collegeName", name:"", streams:[]});
    const [collegeData, setCollegeData] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const { collegeId } = useParams();

    const formChanges = (field, value)=>{
        setFormData(prev=>({...prev, [field]:value}))
    }

    const parseStreams =(data)=>{

        if(!data) {formChanges("streams", [])}

        let streams = data.split(',');
        

        streams = streams.map(stream=>stream.trim());
        streams = streams.filter(stream=> stream);

        formChanges("streams", streams)
    }
    
    
    const clearAlert= ()=>{
        setAlertData(null);
    }

    const LoadCollegeData = async()=>{

        const url = `${process.env.REACT_APP_BASE_URL}/requests/college/${collegeId}`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`
        }})

        console.log(response);

        if(response && response.data){
        setCollegeData(response.data.clgData);
        }
    }

    const onSubmit= async()=>{
        try{
            if(!formData.name){
                setAlertData({message: `${formData.type} is mandatory`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/requests/college/${collegeId}`;
    
            const token = localStorage.getItem('authKey');

            console.log({
                formData
            })

            const response = await axios.patch(url,{[formData.type]:formData.name},{headers: {
                Authorization: `Bearer ${token}`,   
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'College list updated', type:'good'});
            LoadCollegeData()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to get colleges', type:'bad'})
                }
                else
                setAlertData({message:'Unable to get colleges', type:'bad'})
            }
    }

    const onStreamSubmit= async ()=>{

        try{

                if(!formData.streams){
                    setAlertData({message: `Streams is mandatory`, type:'bad'});
                    return;
                }

            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/college/${collegeId}`;
    
            const token = localStorage.getItem('authKey');


            const response = await axios.patch(url,{streams:formData.streams},{headers: {
                Authorization: `Bearer ${token}`,   
            },
        } )
    
        if(response.status===200){
            setAlertData({message: 'College Added', type:'good'});
            console.log(response.data);
            LoadCollegeData()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to Add college', type:'bad'})
                }
                else
                setAlertData({message:'Unable to Add college', type:'bad'})
            }
        
    }
    

    useEffect(()=>{
        LoadCollegeData();
    },[])

    return ( 
        <div className="w-full">

            <PageHeading heading={`Edit College ${collegeData?` - ${collegeData.alias}`:""}`} />
                      

                <div className="clgData">
                    {collegeData && <>

                        <div className="flex flex-col ">
                <div className="flex flex-row">
                    <div className="basis-1/6 px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="College Name / Alias" id="collegeName" name="name" onChange={data => formChanges("name", data.target.value)} /></div>
                    
                    <div className="basis-1/6 px-4 py-3">
                    <select name='type' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={data => formChanges("type", data.target.value)}>
                        
                        <option value="collegeName" selected>College Name</option>
                        <option value="collegeAlias">Alias</option>
                    </select>
                    </div>

                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit('name')}>Change</button> </div>
                </div>
                </div>

            <div className="flex border-b-2 border-yellow-400">
            
            <div className="flex flex-col">
            <label htmlFor="Streams" className='px-4 py-2  text-lg'> Streams / Branches (Seperate by comma)</label>
            <div className="px-4 "><textarea name='Streams' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter streams seperated by comma" onChange={(e)=>parseStreams(e.target.value)} /></div>
            </div>

            <div className="flex flex-col mx-4 px-4 my-2 overflow-scroll h-32 w-96">
            {formData &&formData.streams && formData.streams.map(data=>(
                <div className="text-sm ">
                    {data}
                </div>
            )   )}
            
            </div>

            <div className="flex flex-row">  
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={onStreamSubmit}>Add Streams</button> </div>
            </div>

            </div>

            


                    <div className="flex flex-col mx-4 my-2">
                        <div className=" font-bold ">
                            {collegeData.collegeName} - {collegeData.alias}
                        </div>
                        <div className="flex flex-col mt-4">
                        {collegeData && collegeData.streams.map(stream=>
                        <div>
                            {stream}
                        </div>
                        )}
                        </div>
                    </div>

                    </>}
                </div>
                { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
        </div>
     );
}
 
export default EditCollege;