import React, { useEffect, useState } from "react";
import { dateCustom } from "../../../service/date";
import axios from "axios";
import CommentsList from "./commnets";
import { getAvatar } from "../avatar";

const PostModal = ({ clear, accept, postData, LoadFeedRefresh }) => {
  const [commentsList, setCommentsList] = useState({ comments: [], next: false });

  const loadComments = async () => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/comments/${postData._id}`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        skip: commentsList.comments.length,
      },
    });

    if (response.data) {
      setCommentsList((prev) => {
        return {
          ...response.data.userList,
          comments: [...response.data.comments],
        };
      });
    }
  };

  useEffect(loadComments, []);

  return (
    <div
      id="staticModal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      className="fixed  z-50     w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full bg-violet-400/50 backdrop-blur	"
    >
      <div className="relative w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">Post</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              onClick={clear}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Post</span>
            </button>
          </div>
          <div className="post" key={postData._id}>
            <div className="user flex w-8/12 m-4">
              <div className="image w-10 h-10 place-self-center rounded-full ">
                <img
                  className="rounded-full mr-2"
                  src={getAvatar(postData?.userInfo?.profileInfo.avatar)}
                />
              </div>
              <div className="flex flex-col">
                <div className="name">{postData.userInfo?.profileInfo?.name}</div>
                <div className="clg">{postData.userInfo?.campusInfo?.collegeAlias}</div>
              </div>
            </div>

            <div className="postacrd p-4  bg-white m-4">
              <div className="image self-center mx-auto">
                <img src={postData.file} alt="" className="max-w-48 max-h-96 mx-auto" />
              </div>

              <div className="body flex flex-col mx-4">
                <div className="head flex my-1 items-center	"></div>

                <div className="flex">
                  <div className="description w-10/12 mx-4 whitespace-pre-wrap">
                    <div className="text-xs bg-violet-400 inline p-1 rounded-full text-white">
                      {postData.type}
                    </div>
                    <div className="italic font-semibold">{postData.title}</div>
                    <div>{postData.type != "Poll" && postData.body}</div>
                    <div>
                      {postData.type == "Poll" && (
                        <div>
                          <div className="question flex">
                            {" "}
                            <div className="font-bold">Q : </div>{" "}
                            <div>{postData.body.question}</div>
                          </div>
                          <div className="question">
                            <div>
                              {postData.body.answer.map((answer, index) => (
                                <div className="flex">
                                  <div className="count">{index + 1} : </div>
                                  <div>{answer}</div>
                                  <div className="mx-2  px-1 rounded-full bg-yellow-400">
                                    {postData.pollCount[`Option${index}`]}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="votes comments flex py-4 justify-between mx-4">
              <div className="upvote flex">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
                  />
                </svg>
                <div className="Upvote">Upvote</div>
                <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                  {postData.upvote_count}
                </div>
              </div>
              <div className="upvote flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                  />
                </svg>
                <div className="Downvote">Downvote</div>
                <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                  {postData.downvote_count}
                </div>
              </div>
              <div className="upvote flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                  />
                </svg>

                <div className="">Comment</div>
                <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                  {postData.comment_count}
                </div>
              </div>
              <div className=" flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>

                <div className="">Report</div>
                <div>
                  <div className="py-0.5 px-2 bg-yellow-400 rounded-full mx-2 ">
                    {postData.reportedCount}
                  </div>
                </div>
              </div>
            </div>
            {postData.comment_count > 0 && (
              <CommentsList
                commentsList={commentsList.comments}
                loadComments={loadComments}
                LoadFeedRefresh={LoadFeedRefresh}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
