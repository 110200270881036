import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';
import { sendNotificationToUser } from '../../service/service';
import InputModal from '../../components/modal/inputModal';



const BeepMoments = () => {

    /* eslint-disable no-underscore-dangle */

    const [moments, setMoments] = useState([]);
    const [deleteData, setDeleteData] =  useState(null);
    const [deleteReason, setDeleteReason] =  useState(null);
    const [alertData, setAlertData] = useState(null);
    const [hasNext, sethasNext] =  useState(false);

    const onResonChange = (e)=>{
        setDeleteReason(e.target.value);
    }

    const clearAlert= ()=>{
        setAlertData(null);
    }

    const LoadData = async(index)=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/beep-click/admin?skip=${index}`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });


            console.log(response.data);
            sethasNext(response.data.next);
            setMoments(prev=>[...prev, ...response.data.beepclicks] )
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        LoadData(0)
    },[])

    const loadDeleteData = (index)=>{
        setDeleteData(moments[index]);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
        setDeleteReason("")
    }

    const loadMore = ()=>{

        const index = moments.length

        LoadData(index);
    }

    const deletePost =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/beep-click/admin/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');

            console.log(deleteReason,deleteReason === null , deleteReason ==='', 'Delete reason')
            if(deleteReason === null || deleteReason ==='') { 
                setAlertData({message: 'Reason is required', type:'bad'}); 
                console.log(deleteReason, 'Delete reason inside')
                return;}
    
            const response = await axios.delete(url,{headers: {
                Authorization: `Bearer ${token}`
            }
        } )

        
        const notificationResponse = await sendNotificationToUser(deleteData.userInfo._id, `Your moment has been removed by EB Community`, `"${deleteData.title.slice(0,10)}${deleteData.title.length>10?"...":""}" moment deleted, ${deleteReason}`, true);
        console.log(deleteData.userInfo._id, `Your moment has been removed by EB Community`, `"${deleteData.title.slice(0,10)}${deleteData.title.length>10?"...":""}" moment deleted, ${deleteReason}`)

        if(notificationResponse){
            setAlertData({message: "Notification sent to user", type:'good'})
        }
        if(response.status===200){
            setAlertData({message: 'Post deleted', type:'good'});      
            LoadData()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to delete user', type:'bad'})
                }
                else
                setAlertData({message:'Unable to delete user', type:'bad'})
            }
        
            clearDeleteData();

    }

    
    return (
        <div className="w-full">
            <div className="w-full flex"> 
            <div className='w-5/6'><PageHeading heading="Beep Moments" className="" /> </div>
            
            <div className="shadow py-1 w-1/6 bg-white w-fit">
                <div className=' mx-4  mt-4 '>
                <Link to="/moments/schedules" className='p-2 bg-gray-200 hover:bg-gray-300'>Schedule</Link>
                </div>
            </div>
            </div>
            <div className="grid grid-cols-4">
            {
                moments && moments.map((data, index)=><>       
                <div className="flex flex-col bg-yellow-400 px-2 py-2 mx-2 my-2">
                    <div className="heading">
                    <div className="flex border-b-1 border-black" style={{borderBottomWidth :'1px'}}>
                            <img src={data.userInfo&& data.userInfo.personalInfo && data.userInfo.personalInfo.avatar} alt="Avatar" className="h-8 mr-2 rounded-full my-auto" />
                                <div className="flex flex-col w-11/12">
                                    <div className="font-bold">{data.userInfo&& data.userInfo.personalInfo && data.userInfo.personalInfo.name}</div>
                                    <div className="text-xs truncate w-11/12" title={data.userInfo && data.userInfo.collegeInfo&& data.userInfo.collegeInfo.collegeName && data.userInfo.collegeInfo.collegeName}>{data.userInfo && data.userInfo.collegeInfo&& data.userInfo.collegeInfo.collegeName && data.userInfo.collegeInfo.collegeName}</div>
                                </div>
                            </div>
                    </div>
                    <div className="flex text-xs">
                                <div className='w-1/2 truncate text-left'title={data._id} >{data._id}</div>
                                <div className='ml-1 text-right w-1/2 truncate'title={new Date(data.timeStamp).toLocaleString()}>{new Date(data.timeStamp).toLocaleString()}</div>
                            </div>
                    <div className="text mt-1">
                        {data.title?data.title:"N/A"}
                    </div>
                    <div className="flex mt-1">
                        <img src={data.frontImage} alt="front" className='w-32 mr-1'/>
                        <img src={data.backImage} alt="back" className='w-32'/>
                    </div>
                    <div className="w-full">
                    <button className="ml-auto mr-auto  bg-black rounded shadow px-1 text-white my-1" type="button" onClick={()=>loadDeleteData(index)}>Del</button>

                    {data.hideFrontImage && <div className="font-sm">fRONT</div>}
                    {data.hideBackImage && <div className="font-sm">Back</div>}
                    </div>
                </div>
                </>)
            }
           
        </div>
        {hasNext && <button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={loadMore}>LoadMore</button>}
        {deleteData && <InputModal description={`Do you want to delete ${deleteData.title.slice(0,30)} ?`} onSuccess={deletePost} onCancel={clearDeleteData} onReason={onResonChange} /> }
        { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
        </div>
      );
}
 
export default BeepMoments;