import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import PageHeading from "../../components/headings/pageHeading";
import { dateCustom } from "../../service/date";
import StatusBadge from "../../components/staus/statusBadge";

const JobListing = () => {
  const [jobList, setJobList] = useState({ hasNext: false, jobs: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [showCollapse, setShowCollapse] = useState(null);
  const [banners, setBanners] = useState([]);
  const [selected_banner, set_selected_banner] = useState("");
  const [refresh, set_refresh] = useState(false);

  const loadJobList = async () => {
    console.log(isLoading);
    setIsLoading(true);
    try {
      const url = `${env.REACT_APP_BASE_URL}/admin/jobs`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
        params: {
          skip: jobList.jobs.length,
          limit: 10,
        },
      });

      if (response.data) {
        setJobList((prev) => {
          const tempJobs = [...prev.jobs, ...response.data.jobs];

          return {
            jobs: tempJobs,
            hasNext: response.data.hasNext,
          };
        });
      }
    } catch (e) {
      // if (e?.response?.data?.error)
      // setAuthError(e?.response?.data?.error)
      // else setAuthError('Something went wrong')
    }
    setIsLoading(false);
  };

  const changeCollapse = (jobId) => {
    setShowCollapse((prev) => {
      if (prev === jobId) return null;
      return jobId;
    });
  };

  const get_banners = async () => {
    const url = `${env.REACT_APP_BASE_URL}/admin/banner`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authKey"),
      },
    });

    if (response.data) {
      console.log(response.data.banners);
      setBanners(response.data.banners);
      set_selected_banner(response.data.banners[0]._id);
    }
  };

  useEffect(loadJobList, []);
  useEffect(get_banners, []);

  const removeBanner = async (job_id) => {
    const url = `${env.REACT_APP_BASE_URL}/admin/update_job_banner`;
    const response = await axios.post(
      url,
      {
        add: false,
        job_id: job_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
      }
    );

    if (response.data) {
      document.location.reload();
    }
  };

  const addBanner = async (job_id) => {
    const url = `${env.REACT_APP_BASE_URL}/admin/update_job_banner`;
    const response = await axios.post(
      url,
      {
        add: true,
        job_id: job_id,
        banner_id: selected_banner,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
      }
    );

    if (response.data) {
      document.location.reload();
    }
  };

  const flagJob = async (index, flag) => {
    const url = `${env.REACT_APP_BASE_URL}/admin/job/status`;
    console.log(url);

    const response = await axios.post(
      url,
      {
        job_id: jobList.jobs[index]._id,
        flag,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
      }
    );

    if (response.data) {
      const url = `${env.REACT_APP_BASE_URL}/admin/jobs`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },

        params: {
          skip: index,
          limit: 1,
        },
      });

      if (response.data) {
        setJobList((prev) => {
          const tempJobs = [...prev.jobs];
          tempJobs[index] = response.data.jobs[0];

          return {
            jobs: tempJobs,
            hasNext: prev.hasNext,
          };
        });
      }
    }
  };

  return (
    <div className="jobListing">
      <PageHeading heading="Jobs" />

      <div className="table mx-auto py-4 w-full px-4">
        <div
          className=" overflow-x-auto w-full scrollableDiv"
          id="scrollableDiv"
        >
          <InfiniteScroll
            hasMore={jobList.hasNext}
            // loader={<div className="mx-auto">Loading</div>}
            dataLength={jobList.jobs.length}
            next={loadJobList}
            scrollableTarget="scrollableDiv"
          >
            <table className="w-full text-sm text-left ">
              <thead className="text-xs  uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created By
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job ID
                  </th>
                </tr>
              </thead>
              {/* eslint-disable no-underscore-dangle */}
              <tbody id="accordion-collapse" data-accordion="collapse">
                {jobList.jobs.map((jobData, index) => (
                  <>
                    <tr
                      className={`bg-white border-b ${
                        showCollapse === jobData._id &&
                        "bg-violet-400 text-white shadow-inner	"
                      }`}
                      key={jobData._id}
                      onClick={() => changeCollapse(jobData._id)}
                    >
                      <th scope="row" className="px-6 py-4 font-medium ">
                        {jobData.job_profile}
                      </th>
                      <td className="px-6 py-4">{jobData.job_type}</td>
                      <td className="px-6 py-4">
                        {jobData.recruiter_info.firstName}
                      </td>
                      <td className="px-6 py-4">{jobData.company_info.name}</td>
                      <td className="px-6 py-4">
                        <StatusBadge status={jobData.status} />
                      </td>
                      <td className="px-6 py-4">
                        {dateCustom(jobData.created_at, "DD/MM/yyyy")}
                      </td>
                      <td className="px-6 py-4">{jobData._id}</td>
                    </tr>

                    {showCollapse === jobData._id && (
                      <tr
                        id="accordion-collapse-body-1"
                        className=""
                        aria-labelledby="accordion-collapse-heading-1"
                      >
                        <td colSpan={7}>
                          <div className="w-full bg-gray-100 p-4 transition	delay-700 ease-in shadow-lg mb-2 block ">
                            <table className="w-full">
                              <thead>
                                <tr>
                                  <th>No of openings</th>
                                  <th>Date of closing</th>
                                  <th>Joining Type</th>
                                  <th> Workplace</th>
                                  <th> Category</th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="">
                                  <td>{jobData.job_openings}</td>
                                  <td>
                                    {jobData.job_date_of_closing
                                      ? jobData.job_date_of_closing
                                      : "None"}
                                  </td>
                                  <td>{jobData.joining_type}</td>
                                  <td>{jobData.workplace_type}</td>
                                  <td>{jobData.category_info?.name}</td>
                                  <td
                                    onClick={() =>
                                      flagJob(
                                        index,
                                        jobData.status == 3 ? false : true
                                      )
                                    }
                                    className={`${
                                      jobData.status != 3
                                        ? "bg-red-500"
                                        : "bg-violet-500"
                                    } text-white font-bold p-2 text-center shadow rounded cursor-pointer`}
                                  >
                                    {jobData.status == 3 ? "Enable" : "Flag"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table className="w-1/2">
                              <thead>
                                <tr>
                                  <th>Salary Type</th>
                                  <th>Range</th>
                                  <th>Salary Frequency</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="">
                                  <td>{jobData.job_compensation_type}</td>
                                  <td>
                                    {jobData.salary && jobData.salary}
                                    {jobData.job_min_salary &&
                                      `${jobData.job_min_salary} - ${jobData.job_max_salary}`}
                                    {!(
                                      jobData.salary || jobData.job_min_salary
                                    ) && "None"}
                                  </td>
                                  <td>
                                    {jobData.job_salary_frequency
                                      ? jobData.job_salary_frequency
                                      : "None"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="decription mt-5">
                              <th>Description</th>
                              <pre className="whitespace-pre-line">
                                {jobData.job_description}
                              </pre>
                            </div>
                            <div className="decription mt-5">
                              <th>Skills</th>
                              <pre className="whitespace-pre-line">
                                {jobData.skills?.length
                                  ? jobData.skills.join(",")
                                  : "None"}
                              </pre>
                            </div>
                            <div className="decription mt-5">
                              <th>Perks</th>
                              <pre className="whitespace-pre-line">
                                {jobData.perks?.length
                                  ? jobData.perks.join(",")
                                  : "None"}
                              </pre>
                            </div>
                            <div className="decription mt-5">
                              <th>Banners</th>
                              {jobData.banner ? (
                                <>
                                  {
                                    banners.find((x) => x._id == jobData.banner)
                                      .banner_name
                                  }
                                  <button
                                    onClick={() => removeBanner(jobData._id)}
                                    className={`bg-red-500 text-white font-bold p-2 text-center shadow rounded cursor-pointer`}
                                  >
                                    Remove Banner
                                  </button>
                                </>
                              ) : (
                                <div>
                                  <div>
                                    <label>
                                      Select Banner
                                      <select
                                        onChange={(e) => {
                                          console.log(e.target.value);
                                          set_selected_banner(e.target.value);
                                        }}
                                        value={selected_banner}
                                      >
                                        {banners.map((banner) => {
                                          return (
                                            <option value={banner._id}>
                                              {banner.banner_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </label>
                                  </div>
                                  <button
                                    onClick={() => addBanner(jobData._id)}
                                    className={`bg-green-500 text-white font-bold p-2 text-center shadow rounded cursor-pointer`}
                                  >
                                    Add Banner
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          <div className="w-full text-center ">
            {jobList.hasNext && (
              <button
                type="button"
                className="mx-auto py-2 px-2 shadow bg-violet-600 my-2 rounded text-white"
                onClick={loadJobList}
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListing;

{
  /* 
                                                    "joining_date": "",
                                                    "job_duration": 0,
                                                    "job_compensation_type": "None",
                                                    "category": "64be7dffbd9a0d37e3f3bcbc",
                                                    
                                                    "created_at": "2023-07-27T05:33:40+00:00",
                                                    "recruiter_info": {
                                                        "firstName": "Hari",
                                                        "lastName": "Prasath"
                                                    },
                                                    "company_info": {
                                                        "name": "Mango2346",
                                                        "logo": "company/logo/646f2998a9e3c52b34e7f498_614"
                                                    } */
}
