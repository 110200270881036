import React from 'react';

const PostModal = ({description,url,onCancel,type}) => 
     ( 
        <div id="popup-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full" style={{backgroundColor: `rgba(0,0,0,0.5)`}} >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto opacity-100 mt-10" style={{ "position": "absolute", "top": "50%",  "left": "50%",  "transform": "translate(-50%, -50%)"}}>
            <div className="relative rounded-lg shadow bg-white mt-16">
                <button type="button" className="absolute top-3 right-2.5 text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal" onClick={onCancel}>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>  
                </button>
                <div className="p-6 text-center text-black px-5 ">
                    {description}
                {/* eslint-disable jsx-a11y/media-has-caption */}
                    {type==='Image' &&<img className="max-h-fit" src={url} alt="Post" />}
                    {type==='Video' && <video controls>
                        <source src={url}  />
                    </video>}
                </div>
            </div>
        </div>
    </div>
     );
 
export default PostModal;