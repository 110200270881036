import React, { useEffect, useState } from 'react';
import {Buffer} from 'buffer';
import axios from 'axios';

import AlertWithClose from '../../components/alert/alertWithClose';
// import Modal from '../../components/modal/modal';
import PageHeading from '../../components/headings/pageHeading';

const CreateChallenge = () => {

    const [challengeData, setChallengeData] = useState({audio:false, video:false, image:false})
    const [alertData, setAlertData] = useState(null)
    const [challengeList, setChallengeList] =  useState(null);
    // const [deleteData, setDeleteData] =  useState(null);

    const loadChallenges = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/challenges`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setChallengeList(response.data.challenges.challenges)
            console.log(challengeList);


        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(async()=>{
        await loadChallenges();
    },[])

    const onImageChange = (event, name) => {
        if (event.target.files && event.target.files[0]) {
            setChallengeData(prev => ({
                ...prev, 
            [`${name}Url`]: URL.createObjectURL(event.target.files[0])
          }));
        }
       }

       const checkboxHandler= (name)=>{
            setChallengeData(prev=>({
                ...prev,
                [name]: !prev[name]
            }))
       }

       const onFormDataChange=(e)=>{
        const {name, value, type, files} = e.target;

        if(type==='file'){
            setChallengeData(prev=>({
                ...prev,
                [name]:files[0]
            }))    
            onImageChange(e, name);
        }else
        setChallengeData(prev=>({
            ...prev,
            [name]:value
        }))

        console.log(challengeData,e);
    }

    const clearAlert= ()=>{
        setAlertData(null);
    }
    const parseCoupons =(e)=>{
        
        
        const {name, value }= e.target;
        
        let itemList = value.split(',');
        

        itemList = itemList.map(item=>item.trim());
        itemList = itemList.filter(items=> items);

        
        setChallengeData(prev=>({
            ...prev,
            [name]: itemList
        }))

        setAlertData({message: "Parsed data", type:'good'})
    }


    const submitHandler = async (e)=>{
        // onClick={submitHandler}
        e.preventDefault();
        console.log(challengeData, e)

        try{
        const url = `${process.env.REACT_APP_BASE_URL}/admin-users/challenge`;
        const reqFormData = new FormData();
        
        /* eslint-disable no-unneeded-ternary */

        const arrayToBase64 = (arrayData)=>Buffer.from(JSON.stringify(arrayData)).toString('base64')

        const collegeCampusIds = arrayToBase64(challengeData.collegeCampusIds) ;
        const tags = arrayToBase64(challengeData.tags);
        const rewardIds = arrayToBase64(challengeData.rewardIds);
        
        let formats = []
        if(challengeData.audio) formats.push('audio')
        if(challengeData.video) formats.push('video')
        if(challengeData.image) formats.push('image')

        formats = arrayToBase64(formats)

        
        reqFormData.append('name', challengeData.name);
        reqFormData.append('description', challengeData.description);

        reqFormData.append('collegeCampusIds', collegeCampusIds);
        reqFormData.append('formats', formats);
        reqFormData.append('tags', tags);
        reqFormData.append('likesToFinish', challengeData.likesToFinish);
        reqFormData.append('rewardIds',rewardIds );
        reqFormData.append('upload',challengeData.challengeImage );
        
        

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)

        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){
        setAlertData({message: 'Challenge created', type:'good'})
        loadChallenges();
    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setAlertData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
        }

    }


    

    /* eslint-disable no-underscore-dangle */
    // const loadDeleteData = (index)=>{
    //     setDeleteData(challengeList[index]);
    // }

    // const clearDeleteData =  ()=>{
    //     setDeleteData(null)
    // }

    // const deletChallenge =async ()=>{

    //     try{
    //         const url = `${process.env.REACT_APP_BASE_URL}/admin-users/challenge/${deleteData._id}`;
    
    //         const token = localStorage.getItem('authKey');
    
    //         const response = await axios.delete(url, {headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     } )
    
    //     if(response.status===200){
    //         setAlertData({message: 'Challenge deleted', type:'good'});

    //         loadChallenges()
    //     }
    //         }catch(error){
    //             if(error.name ==='AxiosError'){
                    
    //                 setAlertData({message: error.response.data.error, type:'bad'})
    //             }
    //             else
    //             setAlertData({message:'Unable to delete challenge', type:'bad'})
    //         }
        
    //         clearDeleteData();

    // }
    

    return ( 
        <div className="challenge">
            
            <PageHeading heading='Challenge' />
            <div>

            {/* Row 1 */}
            <div className="flex">

            <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Challenge Name </label>
            <div className="px-4 "><input type="text" name='name' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter merchandise name"  onChange={onFormDataChange}/></div>
            </div>

            

            <div className="flex flex-col">
            <label htmlFor="likesToFinish" className='px-4 py-2  text-lg'> Likes to Finish </label>
            <div className="px-4 "><input type='number' name='likesToFinish' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="No of EB coins"  onChange={onFormDataChange}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="challengeImage" className='px-4 py-2  text-lg'> Challenge Image </label>
            <div className="px-4 "><input type="file" name='challengeImage' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" accept="image/*" onChange={onFormDataChange} /></div>
            </div>

            </div>
            {/* Row 2 */}
            <div className="flex">
                
            <div className="flex flex-col">
            <label htmlFor="tags" className='px-4 py-2  text-lg'> Tags </label>
            <div className="px-4 "><input type="text" name='tags' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Seperate tags by comma"  onChange={parseCoupons}/></div>
            </div>

            <div className="flex">
                
                <div className="flex flex-col w-96">
                <label htmlFor="tags" className='px-4 py-2  text-lg'> Allowed Formats </label>
                <div className="flex px-4 py-2">
                <div className='px-2'>
                <input type="checkbox" id="audio" name="audio" checked={challengeData.audio} value="Audio" onChange={()=>{checkboxHandler("audio")}} />
                <label htmlFor="audio"> Audio</label>
                </div>

                
                <div className='px-2'>
                <input type="checkbox" id="video" name="video" checked={challengeData.video} value="Video"  onChange={()=>{checkboxHandler("video")}}/>
                <label htmlFor="video"> Video</label>
                </div>
                
                
                <div className='px-2'>
                <input type="checkbox" id="image" name="image" checked={challengeData.image} value="Image" onChange={()=>{checkboxHandler("image")}}/>
                <label htmlFor="image"> Image</label>
                </div>
                </div>
                </div>

            </div>

            {challengeData && challengeData.challengeImageUrl && <div className="ml-5 px-10 w-full py-3 w-96"><img className="h-24" src={challengeData.challengeImageUrl} alt='Brand name' /></div>}

            </div>

            <div className="flex">
            
            

            <div className="flex flex-col">
            <label htmlFor="description" className='px-4 py-2  text-lg'> Description </label>
            <div className="px-4 "><textarea name='description' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter description" onChange={onFormDataChange} /></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="collegeCampusIds" className='px-4 py-2  text-lg'> College Ids </label>
            <div className="px-4 "><textarea name='collegeCampusIds' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Paste college Ids seperated by comma" onChange={parseCoupons} /></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="rewardIds" className='px-4 py-2  text-lg'> Rewards Ids</label>
            <div className="px-4 "><textarea name='rewardIds' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Paste Reward Ids seperated by comma" onChange={parseCoupons} /></div>
            </div>

            </div>

            <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-96 border-4 border-yellow-500" onClick={submitHandler} >Create Challenge</button> </div>


            </div>

            <table className="text-sm text-left text-slate-500 mx-5 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3 w-full">
                                    Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    formats
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    tags
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    College
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Rewards
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    Actions
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>


                            {challengeList && challengeList.map((challenge, index) =>
                                <tr className={`bg-white border-b hover:bg-slate-200 ${index%2===0?'': 'bg-slate-100'}`}>
                                    <th scope="row" className="px-6 py-4 ">
                                        {challenge.id}
                                    </th>
                                    <th scope="row" className="px-6 py-4  ">
                                        {challenge.name}
                                    </th>
                                    <td className="px-6 py-4 ">
                                        {challenge.description}
                                    </td>
                                    <td className="px-6 py-4 h-10 ">
                                        {challenge.image_url?
                                        <>
                                        <img src={challenge.image_url} alt={challenge.name} className='h-10'/>
                                        </>
                                        : <span>No Image</span>}
                                    </td>
                                    <td className="px-6 py-4">
                                        {challenge.formats?challenge.formats.toString():"No"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {challenge.tags?challenge.tags.toString():"o"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {challenge.collegeCampusIds? challenge.collegeCampusIds.toString(): "No"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {challenge.rewardIds? challenge.rewardIds.toString():'No'}
                                    </td>
                                    
                                
                                    {/* <td className="px-6 py-4 h-10 ">
                                    <button type="submit" className="bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-3 p-2 rounded w-24 text-white" onClick={()=>loadDeleteData(index)} >Delete</button>
                                    </td> */}
                                </tr>
                            )}

                        </tbody>
                    </table>


            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            {/* {deleteData && <Modal description={`Do you want to delete ${deleteData.name} ?`} onSuccess={deletChallenge} onCancel={clearDeleteData} /> } */}


            </div>
       
     );
}
 
export default CreateChallenge;