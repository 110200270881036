import axios from "axios";
import React from "react";
import { getAvatar } from "../avatar";

const CommentsList = ({ commentsList, loadComments, LoadFeedRefresh }) => {
  const deleteCommnet = async (commnetId) => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/commnet/${commnetId}`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      LoadFeedRefresh();
      loadComments();
    }
  };

  return (
    <div className="mx-4 pb-4">
      <div className="break border-b-2 font-semibold mb-2">Comments</div>
      {commentsList.map((commnetData) => (
        <div className="flex items-center">
          <div className="w-4/12">
            <div className="user flex  ">
              <div className="image w-10 h-10 place-self-center rounded-full ">
                <img
                  className="rounded-full mr-2"
                  src={getAvatar(commnetData.userInfo?.profileInfo.avatar)}
                />
              </div>
              <div className="flex items-center">
                <div className="name">{commnetData.userInfo?.profileInfo?.name}</div>
              </div>
            </div>
          </div>
          <div className="w-7/12">{commnetData.comment}</div>
          <div className="w-1/12">
            <button className="delete  text-red-600" onClick={() => deleteCommnet(commnetData._id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentsList;
