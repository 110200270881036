import React from "react";
import PageHeading from "../../components/headings/pageHeading";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import AlertWithClose from "../../components/alert/alertWithClose";

const RecruiterPremium = () => {
  const [email, setEmail] = React.useState("");
  const [companyData, setComapnyData] = React.useState(null);
  const [recruiterData, setRecruiterData] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [notification, setNotification] = React.useState(null);

  const plans = {
    0: {
      name: "pro_plan_yearly",
      amount: 99000,
      validMonths: 12,
      validDays: 0,
    },
    1: {
      name: "pro_plan_monthly",
      amount: 11000,
      validMonths: 1,
      validDays: 0,
    },
    2: {
      name: "pro_plan_quaterly",
      amount: 36999,
      validMonths: 3,
      validDays: 0,
    },
    3: {
      name: "pro_plan_45days",
      amount: 5500,
      validMonths: 0,
      validDays: 45,
    },
  };

  const fetchRecruuterComapnyDetails = async () => {
    try {
      setComapnyData(null);
      const url = `${process.env.REACT_APP_BASE_URL}/admin/recruiter/company`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: { email },
      });

      setComapnyData(response.data.company);
      setRecruiterData(response.data.recruiter);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data && e.response.data.message) {
        setNotification(e.response.data.message);
        return;
      } else setNotification("Something went wrong");
    }
  };

  const activatePlan = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/recruiter/company`;
      const token = localStorage.getItem("authKey");
      const response = await axios.post(
        url,
        {
          companyId: companyData._id,
          planId: parseInt(selectedPlan),
          planValidTill: moment()
            .add(plans[selectedPlan].validMonths, "months")
            .add(plans[selectedPlan].validDays, "days")
            .toISOString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchRecruuterComapnyDetails();
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data && e.response.data.message) {
        setNotification(e.response.data.message);
        return;
      } else setNotification("Something went wrong");
    }
  };

  const acceptRequest = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/recruiter/request`;
      const token = localStorage.getItem("authKey");
      
      await axios.patch(
        url,
        {
            recruiterId: recruiterData._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchRecruuterComapnyDetails();
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data && e.response.data.message) {
        setNotification(e.response.data.message);
        return;
      } else setNotification("Something went wrong");
    }
  };

  return (
    <div>
      <PageHeading heading="Recruiter Premium" />

      {/* find recruiter by email */}

      <div className="flex">
        <div className="flex flex-col">
          <label htmlFor="remainingCount" className="px-4 py-2">
            Recruiter Email
          </label>
          <div className="px-4 py-1">
            <input
              type="email"
              name="remainingCount"
              value={email}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="hr@eventbeep.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="px-4 w-52 mb-0 mt-auto ">
          <button
            type="submit"
            className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 "
            onClick={fetchRecruuterComapnyDetails}
            disabled={!email}
          >
            Find Company
          </button>{" "}
        </div>
      </div>

      <hr className="border border-slate-400 mx-2 my-4" />

      <div>
        {companyData && (
          <div>
            <div className="flex items-center mx-4">
              <img
                src={companyData.logo}
                alt="company logo"
                className="w-20 h-20 rounded-full"
              />
              <div className="text-lg font-bold mx-4">{companyData.name}</div>
              {companyData.planValidTill && (
                <div className=" bg-green-400 p-4 rounded-sm mx-4">
                  <div>
                    Plan Valid Till :{" "}
                    {moment(companyData.planValidTill).format("DD MMM YY")}
                  </div>
                </div>
              )}

              {!companyData.planValidTill && <div>Plan Not Active</div>}
            </div>

            <div>
              {!companyData.planValidTill && (
                <div>
                  <div className="text-lg font-bold mx-4">Activate Plan</div>
                  <div className="flex items-center mx-4">
                    <div className="text-lg font-bold mx-4">Select Plan</div>
                    <select
                      name="plan"
                      id="plan"
                      onChange={(e) => setSelectedPlan(e.target.value)}
                      value={selectedPlan}
                      className="form-select rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
                    >
                      <option selected disabled>
                        Select Value
                      </option>
                      <option value="0">1 Year</option>
                      <option value="1">1 Month</option>
                      <option value="2">3 Month</option>
                      <option value="3">45 days</option>
                    </select>
                    <button
                      onClick={activatePlan}
                      disabled={!selectedPlan}
                      className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 mx-4"
                    >
                      Activate Plan
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div>
                {recruiterData?.status==31 && (
                    <div>
                        <hr className="border border-slate-400 mx-2 my-4" />

                    <div className="text-lg font-bold mx-4">Recruiter Details</div>

                    <div className="flex items-center mx-4">
                        <div className="text-lg font-bold mx-4">Name</div>
                        <div>{recruiterData.name}</div>

                        <button
                        onClick={acceptRequest}
                        className="bg-violet-400 text-white font-medium  shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-52 outline-yellow-600 outline-2 text-white disabled:shadow-none disabled:bg-green-100 mx-4"
                        >Approve request</button>
                    
                    </div>



                    </div>

                )}
            </div>
          </div>
        )}
      </div>
      {notification && (
        <AlertWithClose
          clearAlert={() => setNotification(null)}
          message={notification}
          time={4}
          type={"bad"}
        />
      )}
    </div>
  );
};

export default RecruiterPremium;
