import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AlertWithClose from '../../components/alert/alertWithClose';
import PageHeading from '../../components/headings/pageHeading';
import Modal from '../../components/modal/modal';


const MomentsSchedule = () => {
    /* eslint-disable no-underscore-dangle */
    const [formData, setFormData] = useState({"type":"collegeName", name:""});
    const [schedulerData, setschedulerData] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const [deleteData, setDeleteData] =  useState(null);
    const [currentMonth, setCurrentMonth] = useState([]);
    
    const formChanges = (field, value)=>{
        setFormData(prev=>({...prev, [field]:new Date(value)}))
    }
    
    
    const clearAlert= ()=>{
        setAlertData(null);
    }

    const loadDeleteData = (index)=>{
        setDeleteData(schedulerData[index]);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
        
    }

    const getMonthName = (monthNumber)=> {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];

            
        
        return months[monthNumber-1];
      }

      function getMonthArray(date, schedule) {
        const year = date.getFullYear();
        const month = date.getMonth();
        const numDaysInMonth = new Date(year, month + 1, 0).getDate();
        const monthArray = new Array(7 * Math.ceil((numDaysInMonth + 1) / 7)).fill({day:null});
        
        const currentMonthSchedule = schedule.filter(data=>
             new Date(data.schedule).getMonth() === month
        )
        
        console.log(currentMonthSchedule);

        for (let day = 1; day <= numDaysInMonth; day+=1) {
          const index = new Date(year, month, day).getDay() + (day-1);
          monthArray[index] = {day};
        }
        let count=0;
        const monthArr = monthArray.map((data)=> {
            if(data.day)
            {count+=1; return {day:count}}
            return {day:null};
        })

        let datePointer = 0;

        let schedulePointer=0;
        
        console.log(datePointer, monthArr.length, schedulePointer, currentMonthSchedule.length)
        while(datePointer< monthArr.length && schedulePointer< currentMonthSchedule.length){
            console.log(monthArr[datePointer].day, new Date(currentMonthSchedule[schedulePointer].schedule).getDate(), datePointer)
            if( monthArr[datePointer].day!==null &&monthArr[datePointer].day === new Date(currentMonthSchedule[schedulePointer].schedule).getDate()){
                monthArr[datePointer].schedule= currentMonthSchedule[schedulePointer].schedule;
                schedulePointer+=1;
            }

            datePointer+=1;
        }

        setCurrentMonth(monthArr);
      }

      const LoadScheduleData = async()=>{

        const url = `${process.env.REACT_APP_BASE_URL}/beep-click/schedule`;
    
        const token = localStorage.getItem('authKey');

        const response = await axios.get(url,{headers: {
            Authorization: `Bearer ${token}`
        }})

        console.log(response);

        if(response && response.data){
            const arr = response.data.schedules.reverse();
            // arr.sort((a,b)=> a.schedule > b.schedule ? 1 :  -1 );

        setschedulerData(arr);
        getMonthArray(new Date(), arr);
        }
    }

      useEffect(()=>{
        LoadScheduleData();
        // getMonthArray(new Date());
    },[])

    const deletePost =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/beep-click/schedule/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');

            
    
            const response = await axios.delete(url,{headers: {
                Authorization: `Bearer ${token}`
            }
        } )

        if(response.status===200){
            setAlertData({message: 'Schedule deleted', type:'good'});      
            LoadScheduleData()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to delete schedule', type:'bad'})
                }
                else
                setAlertData({message:'Unable to delete schedule', type:'bad'})
            }
        
            clearDeleteData();

    }

    const onSubmit= async()=>{
        try{
            if(!formData.timeStamp){
                setAlertData({message: `${formData.type} is mandatory`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/beep-click/schedule`;
    
            const token = localStorage.getItem('authKey');

            console.log({
                formData
            })

            const response = await axios.post(url,{timeStamp:formData.timeStamp},{headers: {
                Authorization: `Bearer ${token}`,   
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'Schedule created', type:'good'});
            LoadScheduleData()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable create schedule', type:'bad'})
                }
                else
                setAlertData({message:'Unable create schedule', type:'bad'})
            }
    }


    return ( 
        <div className=" w-full">
                      
                      <PageHeading heading="Beep Moments schedules" />
                      <div className="flex flex-col border-b-2 border-violet-400 mx-4">
                <div className="flex flex-row py-2">
                    <div className="basis-1/6  py-3">
                    <input type='datetime-local' name='timeStamp' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Select time" onChange={data=>formChanges("timeStamp", data.target.value)} />
                        </div>
                    
                    <div className="px-4 py-3"><button type="submit" className="bg-violet-400 shadow-md font-medium text-white hover:shadow-sm py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit('name')}>Create Schedule</button> </div>
                </div>
                </div>

                <div className="clgData">
                    {schedulerData && <>

                      

                    <div className="flex flex-col mx-4 my-4">
                        <div className=" font-bold ">
                            Schedules
                        </div>

                        <div className="grid grid-cols-2">

                        <div className="flex flex-col mt-4">
                        {schedulerData && schedulerData.map((stream, index)=>
                        <div className='flex bg-gray-100 shadow-sm hover:bg-gray-50 inline px-2 py-2 w-1/2 mb-2'>
                            <div className="mx-2 my-1 w-3/4 ">
                            {`${index+1})`}  {stream.forDate} - {new Date(stream.schedule).toLocaleTimeString()}
                            </div>
                            <div className="mx-2 w-1/4">
                            <button className="ml-auto mr-0 bg-black rounded shadow px-1 text-white my-1" type="button" onClick={()=>loadDeleteData(index)}>Del</button>
                            </div>
                        </div>
                        )}
                        </div>

{/* calendar */}
                        <div className="calendar shadow rounded bg-slate-50 h-fit">
                        <div className=" w-100 text-center">
                            <div className="text-center w-100 py-2 ">{getMonthName(new Date().getMonth()+1)}</div>
                            <div className="w-100">
                                <div className="grid grid-cols-7 grid-gap-2 border-b border-slate-400 m-2">
                                    <div className="day">S</div>
                                    <div className="day">M</div>
                                    <div className="day">T</div>
                                    <div className="day">W</div>
                                    <div className="day">T</div>
                                    <div className="day">F</div>
                                    <div className="day">S</div>
                                </div>

                                <div className="grid grid-cols-7 grid-gap-2  m-2">
                                {currentMonth.map(data=>(data.day?<div className={`day border-b px-2 py-1 m-1 rounded ${data.schedule?'bg-violet-400 text-white':''} ${data.day< new Date().getDate()? 'text-slate-300':''}`}>{data.day}</div>:<div/>))}    
                                </div>

                                
                            </div>
                        </div>
                        </div>
                        </div>
                        
                    </div>

                    </>}
                </div>
                { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
                {deleteData && <Modal description="Do you want to delete?" onCancel={clearDeleteData} onSuccess={deletePost}/>}
        </div>
     );
}
 
export default MomentsSchedule;