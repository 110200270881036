import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PageHeading from '../../components/headings/pageHeading';
import Modal from '../../components/modal/modal';



const ClubRoomList = () => {

    const [roomData,setRoomsList] =  useState({rooms:[]});
    const [deleteRoom, setDeleteRoom] =  useState(null);
    const {clubId} = useParams();
    const [userBan, setUserBan] =  useState(null);
    // const [deleteLoading, setDeleteLoading] =  useState(false);


    const loadRooms = async()=>{
        
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/rooms/admin/clubs/${clubId}`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
           setRoomsList(response.data)

        }
        catch(e){
            console.log(e);
        }
    }

    useEffect(async()=>{
        loadRooms();
     },[])

    const initateDelete = (index, id)=>{
        setDeleteRoom({index, id});
    }
    const clearDelete =  ()=>{
        setDeleteRoom(null);
    }

    const clearBan = ()=>{
        setUserBan(null);
    }

    const intiateBanUser = async (index, userId)=>{
        setUserBan({index, userId});
    }

    const banUser = async()=>{
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/block`;
            const token = localStorage.getItem('authKey');
            const data = JSON.stringify({id:userBan.userId, block:true})
            await axios.post(url,data, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
            loadRooms()
            setUserBan(null);
        }
        catch(e){
            console.log(e);
        }

    }

    const deleteSelectedRoom = async()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/rooms/admin/${deleteRoom.id}`;
            const token = localStorage.getItem('authKey');
            await axios.delete(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
            loadRooms()
            setDeleteRoom(null);
        }
        catch(e){
            console.log(e);
        }

    }

    
    /* eslint no-underscore-dangle: 0 */
    
    return ( 
        <div className="roomList">
            
            <PageHeading heading="Audio Rooms" />
            <div className="flex flex-wrap">
                    {roomData.rooms.map((data, index)=>
                        <div  className=" px-4 bg-yellow-400 shadow rounded py-3 mx-2 my-2" key={data._id}>
                            <div className="flex flex-col">
                            <div className="flex">
                            <div className='text-lg basis-3/4 font-semibold truncate' title={data.name}>{data.name? (data.name.slice(0,30)+(data.name.length>30?"...":"")):null}</div>
                            {data.image_url && <div className='basis-1/4 h-5 w-5'><img src={data.image_url} alt={data.name} /></div>}
                            </div>
                            <div className='text-sm truncate'title={data.description}>{data.description? (data.description.slice(0,30)+(data.description.length>30?"...":"")):null}</div>
                            <div className="text-xs">{new Date(data.starts_at).toLocaleString()} -  {new Date(data.ends_at).toLocaleString()}</div>
                            <div className="flex py-2">
                            { data.userInfo.avatar  && <div className="basis-1/5 rounded-100 text-sm"> <img className='h-10 w-10 px-1 py-1' src={data.userInfo.avatar} alt="No Avatar" /> </div>}
                                <div className="flex flex-col">
                                <div className='basis-4/5 text-xs font-bold'>{data.userInfo.name}</div>
                                <div className='basis-4/5 text-xs'>{data.created_by}</div>
                                </div>
                                <button className='px-1 text-sm  text-white shadow-lg rounded bg-gray-900 ml-auto hover:cursor-pointer hover:shadow-2xl text-yellow-400' type='button' onClick={()=>intiateBanUser(index, data.created_by)}>Ban</button>
                            </div>
                            <div className="flex pt-1 text-sm">
                                {(new Date()> new Date(data.starts_at)) && <div className='px-1 my-1 mr-1 bg-green-600 text-white rounded'>Live</div>}
                                {data.is_private && <div className='px-1 my-1 bg-green-600 text-white rounded'>{data.is_private && "Private"}</div>}
                                <div className={`px-1 my-1 ${data.is_open? 'bg-green-600': 'bg-red-600'} text-white ml-1 rounded`}>{data.is_open? "open": "closed"}</div>
                                <button className='px-2 py-1 text-white shadow-lg rounded bg-gray-900 ml-auto hover:cursor-pointer hover:shadow-2xl text-yellow-400' type='button' onClick={()=>initateDelete(index, data._id)}>Delete</button>
                            </div>
                            </div>
                        </div>                        
                    ) }
            </div>
         {deleteRoom && <Modal description={`Do you want to delete  ${roomData.rooms[deleteRoom.index].name}?`} onCancel={clearDelete} onSuccess={deleteSelectedRoom} />}
         {userBan && <Modal description={`Do you want to ban  ${roomData.rooms[userBan.index].userInfo.name}?`} onCancel={clearBan} onSuccess={banUser} />}
        </div>
     );
}
 
export default ClubRoomList;