import React, { useState } from 'react';
import PageHeading from '../../components/headings/pageHeading';
import axios from 'axios';


const UserOTPS = () => {
    const [number, setNumber] = useState('');
    const [otp, setOtp] =  useState('');

    const getOtp = async () => {
        try {
            setOtp("")
          const url = `${process.env.REACT_APP_BASE_URL}/admin/user/otp`;
          const token = localStorage.getItem("authKey");
          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params:{
                number
            }
          });
    
          setOtp(response.data.otp);
          console.log(response.data);
        } catch (e) {
          setOtp("Error")
        }
      };


    return (  
        <div>
            <PageHeading heading='User OTPs' />

            <div className="mx-8 my-4 flex gap-4">
            <div className="flex flex-col">
                <label className="text-lg font-bold">User Phone number</label>
                <input type="text" 
                    value={number} 
                    onChange={(e)=>setNumber(e.target.value)}
                    className="border border-gray-400 rounded-md p-2 w-64" 
                    placeholder="+91xxxxxx" />
            </div>

            <button className="bg-blue-500 text-white p-2 rounded-md self-end" onClick={getOtp}>Get OTP</button>

            {otp && <div className="flex flex-col self-center"> 
                    {otp}
                    </div>
            }
            </div>

            
        </div>
    );
}
 
export default UserOTPS;