import { async } from "@firebase/util";
import { Button, Modal } from "@mui/material";
import axios from "axios";
import PageHeading from "../../components/headings/pageHeading";
import React, { useEffect, useState } from "react";
import moment from "moment";
import AlertWithClose from "../../components/alert/alertWithClose";
import Select from "react-select";

function TPODashboard() {
  const [tpos, set_tpos] = useState([]);
  const [azure_url, set_azure_url] = useState("");
  const [open, set_open] = useState(false);

  const [alertData, setAlertData] = useState(null);
  const [formData, setForm] = useState({
    tpo_email: "",
    campus_name: "",
    campus_alias: "",
    campus_state: "",
    campus_city: "",
    campus_id: null,
  });

  const [search, setSearch] = useState("");

  const [show_students, set_show_students] = useState("");
  const [students, set_students] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([
    { label: "key1", value: "value1" },
    { label: "key2", value: "value2" },
  ]);
  const [selectedCollege, setSelectedCollege] = useState(null);

  const [showCreateCollege, setShowCreateCollege] = useState(false);
  const [collegename, set_collegename] = useState("");

  const clearModalState = () => {
    setForm({
      tpo_email: "",
      campus_name: "",
      campus_alias: "",
      campus_state: "",
      campus_city: "",
    });
    setShowCreateCollege(false);
  };

  /* eslint-disable  no-param-reassign */
  const onImageChange = (event) => {
    const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {
      // Initiate the JavaScript Image object.
      const image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this;
        console.log(height, width);

        if (event.target.files && event.target.files[0]) {
          setForm((prev) => ({
            ...prev,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          }));
        }
      };
    };
  };
  const formChanges = (e) => {
    const { name, value, files } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "image") {
      setForm((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      onImageChange(e);
    }

    console.log(e, name, value, formData);
  };

  const get_tpos = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/tpo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
      })
      .then(({ data }) => {
        set_azure_url(data.azure_link);
        set_tpos(data.tpos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    get_tpos();
    loadCollegeList("");
  }, []);

  const loadOptions = async (e) => {
    loadCollegeList(e);
    console.log("loading....", e);
    setSearch(e);
    setCollegeOptions([]);
  };
  const clerarAlert = () => setAlertData(null);

  const loadCollegeList = async (e) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/campus`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
        params: {
          limit: 10,
          name: e,
        },
      });

      if (response.data) {
        setCollegeOptions((prev) => {
          const newData = response.data.campusList.map((clg) => ({
            label: clg.campusName,
            value: clg._id,
          }));
          return [...newData];
        });
      }
    } catch (e) {
      // if (e?.response?.data?.error)
      // setAuthError(e?.response?.data?.error)
      // else setAuthError('Something went wrong')
    }
  };

  const createTpo = async (e) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/tpo`;
      const reqFormData = new FormData();
      const {
        tpo_email,
        campus_name,
        campus_alias,
        campus_state,
        campus_city,
        image,
        campus_id,
      } = formData;

      if (!tpo_email) {
        setAlertData({ message: "Please add tpo email", type: "bad" });
        return;
      }
      reqFormData.append("tpo_email", tpo_email);

      if (!image) {
        setAlertData({ message: "Please Attach Logo", type: "bad" });
        return;
      }
      reqFormData.append("upload", image);

      if (campus_id == null) {
        if (
          campus_name == "" ||
          campus_alias == "" ||
          campus_state == "" ||
          campus_city == ""
        ) {
          setAlertData({ message: "Please add all college info", type: "bad" });
          return;
        }
        reqFormData.append("campus_name", campus_name);
        reqFormData.append("campus_alias", campus_alias);
        reqFormData.append("campus_state", campus_state);
        reqFormData.append("campus_city", campus_city);
      } else {
        reqFormData.append("campus_id", campus_id);
      }

      const token = localStorage.getItem("authKey");

      const response = await axios.post(url, reqFormData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setAlertData({ message: "Banner Created", type: "good" });
        get_tpos();
        set_open(false);
        clearModalState();
      }
    } catch (error) {
      if (error?.response?.data?.message)
        setAlertData({ message: error?.response?.data?.message, type: "bad" });
      else setAlertData({ message: "Something went wrong", type: "bad" });
    }
  };

  const get_students_data = async (onboarded, TPO_id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/tpoStudents/${TPO_id}?onboarded=${onboarded}`;
    const token = localStorage.getItem("authKey");

    axios
      .get(url, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        set_students(data);
      })
      .catch((err) => {
        console.log(err);
        set_show_students(false);
      });
  };

  return (
    <div>
      <PageHeading heading="TPO Dashboard" />
      <div className="m-2">
        <div>
          <Button
            onClick={() => {
              set_open(true);
            }}
          >
            Add new TPO
          </Button>
        </div>
        <div className="mt-2">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs  uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S.No.
                </th>
                <th scope="col" className="px-6 py-3">
                  College Name
                </th>
                <th scope="col" className="px-6 py-3">
                  College Logo
                </th>
                <th scope="col" className="px-6 py-3">
                  TPO Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Invited Students
                </th>
                <th scope="col" className="px-6 py-3">
                  Onboarded Students
                </th>
                <th scope="col" className="px-6 py-3">
                  Pending Students
                </th>
                <th scope="col" className="px-6 py-3">
                  Onboarded On
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {tpos.map((tpo, index) => (
                <tr className=" ">
                  <td className="text-center py-2">
                    <div className="py-2">{index + 1}</div>
                  </td>
                  <td>{tpo.campus_info.campusName}</td>
                  <td>
                    <img src={azure_url + "/" + tpo.logo} className="h-8 w-8" />
                  </td>
                  <td>{tpo.tpo_email}</td>
                  <td>{tpo.invited_users}</td>
                  <td>
                    <p
                      className="cursor-pointer text-blue-800"
                      onClick={() => {
                        set_students([]);
                        set_collegename(tpo.campus_info.campusName);
                        get_students_data("true", tpo._id);
                        set_show_students(true);
                      }}
                    >
                      {tpo.onboarded_users}
                    </p>
                  </td>
                  <td>
                    <p
                      className="cursor-pointer text-blue-800"
                      onClick={() => {
                        console.log("Test");
                        set_students([]);
                        set_collegename(tpo.campus_info.campusName);
                        get_students_data("false", tpo._id);
                        set_show_students(true);
                      }}
                    >
                      {tpo.invited_users - tpo.onboarded_users}
                    </p>
                  </td>
                  <td className="">
                    {moment(tpo.created_on).format("MMMM Do YYYY")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          set_open(false);
          clearModalState();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex items-center justify-center"
      >
        <div className="p-4 w-1/2  bg-white">
          <div>Add new TPO</div>
          <div className="m-4">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="w-full">
                  <label htmlFor="startsAt" className="px-4 py-2">
                    TPO Email
                  </label>
                  <div className="px-4 py-1">
                    <input
                      type="email"
                      name="tpo_email"
                      value={formData.tpo_email}
                      className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                      placeholder="Enter TPO Email"
                      onChange={formChanges}
                    />
                  </div>
                  <div className="w-full">
                    {showCreateCollege && (
                      <div className="">
                        <label htmlFor="startsAt" className="px-4 py-2">
                          College Name
                        </label>
                        <div className="px-4 py-1">
                          <input
                            type="input"
                            name="campus_name"
                            value={formData.campus_name}
                            className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                            placeholder="Enter Campus name"
                            onChange={formChanges}
                          />
                        </div>

                        <label htmlFor="startsAt" className="px-4 py-2">
                          College Alias
                        </label>
                        <div className="px-4 py-1">
                          <input
                            type="input"
                            name="campus_alias"
                            value={formData.campus_alias}
                            className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                            placeholder="Enter campus alias"
                            onChange={formChanges}
                          />
                        </div>

                        <label htmlFor="startsAt" className="px-4 py-2">
                          College city
                        </label>
                        <div className="px-4 py-1">
                          <input
                            type="input"
                            name="campus_city"
                            value={formData.campus_city}
                            className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                            placeholder="Enter capus city"
                            onChange={formChanges}
                          />
                        </div>

                        <label htmlFor="startsAt" className="px-4 py-2">
                          College state
                        </label>
                        <div className="px-4 py-1">
                          <input
                            type="input"
                            name="campus_state"
                            value={formData.campus_state}
                            className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                            placeholder="Enter campus state"
                            onChange={formChanges}
                          />
                        </div>
                      </div>
                    )}
                    {!showCreateCollege && (
                      <div className="">
                        <div className="flex py-2">
                          <label htmlFor="startsAt" className="px-4 py-2">
                            College Name
                          </label>
                          <span
                            htmlFor="startsAt"
                            className="px-4 py-2 mr-4 ml-auto rounded text-white bg-violet-400 cursor-pointer"
                            onClick={() => {
                              setShowCreateCollege(true);
                              setForm((prev) => {
                                return {
                                  ...prev,
                                  campus_id: null,
                                };
                              });
                            }}
                          >
                            Create College
                          </span>
                        </div>
                        <Select
                          className="px-4 "
                          value={selectedCollege}
                          inputValue={search}
                          onInputChange={loadOptions}
                          options={collegeOptions}
                          onChange={(e) => {
                            setSelectedCollege(e);
                            setForm((prev) => {
                              return {
                                ...prev,
                                campus_id: e.value,
                              };
                            });
                          }}
                          nSele
                        />
                        {/* <div className="px-4 py-1">
                <input
                  type="email"
                  name="tpo_email"
                  value={formData.tpo_email}
                  className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-full bg-slate-300"
                  placeholder="Enter TPO Email"
                  onChange={formChanges}
                />
              </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="image" className="px-4 py-2">
                  {" "}
                  College Logo
                </label>
                <div className="px-4 py-1">
                  <input
                    type="file"
                    name="image"
                    className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Enter Clubs name"
                    accept="image/*"
                    onChange={formChanges}
                  />
                </div>
              </div>

              {formData && formData.imageUrl && (
                <img
                  className="mt-2 h-24 px-5"
                  src={formData.imageUrl}
                  alt="Clubs name"
                />
              )}
            </div>
          </div>
          <div className="w-full relative flex">
            <div
              className=" bg-violet-400 text-white rounded cursor-pointer px-4 py-2 ml-auto mr-8"
              onClick={createTpo}
            >
              Add TPO
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={show_students}
        onClose={() => {
          set_show_students(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex items-center justify-center"
      >
        <div className="p-2 bg-white h-2/3 w-4/5 overflow-scroll">
          <div className="text-lg">Students: {collegename}</div>
          <div className="p-2">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs  uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S.No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Invited On
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr className=" ">
                  <td className="text-center py-2">
                    <div className="py-2">{index + 1}</div>
                  </td>
                  <td>{student.number}</td>
                  <td>{student.profileInfo.name}</td>
                  <td>{student.email}</td>
                  <td className="">
                    {moment(student.invited_on).format("MMMM Do YYYY")}
                  </td>
                  <td>{student.skills?(<>Onboarded</>):(<>Pending</>)}</td>
                
                </tr>
              ))}
            </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {alertData && (
        <AlertWithClose
          message={alertData.message}
          type={alertData.type}
          time={4}
          clearAlert={clerarAlert}
        />
      )}
    </div>
  );
}

export default TPODashboard;
