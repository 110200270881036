import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import PageHeading from "../../components/headings/pageHeading";
import { dateCustom } from "../../service/date";
import StatusBadge from "../../components/staus/statusBadge";
import TextModal from '../../components/modal/inputModal';
import Modal from '../../components/modal/modal';

const JobEditReview = () => {
  const [jobList, setJobList] = useState({ hasNext: false, jobs: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [showCollapse, setShowCollapse] = useState(null);
  const [refresh, set_refresh] = useState(false);
  const [displayModal, setDisplayModal] = useState({type:null, index:null});
  const [rejectReason, setRejectreason] =  useState('');

  const resetModalData = () => {
    setDisplayModal({type:null, index:null});
    setRejectreason('');
  }

  const loadJobList = async (skip, limit) => {
    console.log(isLoading);
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/review/editedjobs`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
        params: {
          skip: skip!=null?skip:jobList.jobs.length,
          limit: limit?limit:10,
        },
      });

      if (response.data) {
        setJobList((prev) => {
          const tempJobs = skip==null?[...response.data.jobs]:[...prev.jobs, ...response.data.jobs];

          return {
            jobs: tempJobs,
            hasNext: response.data.hasNext,
          };
        });
      }
    } catch (e) {
      // if (e?.response?.data?.error)
      // setAuthError(e?.response?.data?.error)
      // else setAuthError('Something went wrong')
    }
    setIsLoading(false);
  };

  const changeCollapse = (jobId) => {
    setShowCollapse((prev) => {
      if (prev === jobId) return null;
      return jobId;
    });
  };

  useEffect(()=>loadJobList(null, null), []);


  const updateJob = async () => {
    const jobid = jobList.jobs[displayModal.index]._id;
    const url = `${process.env.REACT_APP_BASE_URL}/admin/update/job/${jobid}`;
    console.log(url);

    console.log({
        rejectionReason: rejectReason,
        action: displayModal.type,
      },)

    const response = await axios.patch(
      url,
      {
        rejectionReason: rejectReason,
        action: displayModal.type,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authKey"),
        },
      }
    );

    resetModalData();
    loadJobList(null, null);
  };

return (
    <div className="jobListing">
        <PageHeading heading="Jobs" />

        <div className="table mx-auto py-4 w-full px-4">
            <div
                className=" overflow-x-auto w-full scrollableDiv"
                id="scrollableDiv"
            >
                <InfiniteScroll
                    hasMore={jobList.hasNext}
                    // loader={<div className="mx-auto">Loading</div>}
                    dataLength={jobList.jobs.length}
                    next={loadJobList}
                    scrollableTarget="scrollableDiv"
                >
                    <table className="w-full text-sm text-left ">
                        <thead className="text-xs  uppercase bg-gray-50 ">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Job Profile
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Company
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job ID
                                </th>
                            </tr>
                        </thead>
                        {/* eslint-disable no-underscore-dangle */}
                        <tbody id="accordion-collapse" data-accordion="collapse">
                            {jobList.jobs.map((jobData, index) => (
                                <>
                                    <tr
                                        className={`bg-white border-b ${
                                            showCollapse === jobData._id &&
                                            "bg-violet-400 text-white shadow-inner	"
                                        }`}
                                        key={jobData._id}
                                        onClick={() => changeCollapse(jobData._id)}
                                    >
                                        <th scope="row" className="px-6 py-4 font-medium ">
                                            {jobData.job_profile}
                                        </th>
                                        <td className="px-6 py-4">{jobData.job_type}</td>
                                        <td className="px-6 py-4">
                                            {jobData.recruiter_info.firstName}
                                        </td>
                                        <td className="px-6 py-4">{jobData.company_info.name}</td>
                                        <td className="px-6 py-4">
                                            <StatusBadge status={jobData.status} />
                                        </td>
                                        <td className="px-6 py-4">
                                            {dateCustom(jobData.created_at, "DD/MM/yyyy")}
                                        </td>
                                        <td className="px-6 py-4">{jobData._id}</td>
                                    </tr>

                                    {showCollapse === jobData._id && (
                                        <>
                                        <tr
                                            id="accordion-collapse-body-1"
                                            className=""
                                            aria-labelledby="accordion-collapse-heading-1"
                                        >
                                            <td colSpan={7}>
                                                <div className="w-full bg-gray-100 p-4 transition	delay-700 ease-in shadow-lg mb-2 block ">
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr>
                                                                <th>No of openings</th>
                                                                <th>Date of closing</th>
                                                                <th>Joining Type</th>
                                                                <th> Workplace</th>
                                                                <th> Category</th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="">
                                                                <td>{jobData.job_openings}</td>
                                                                <td>
                                                                    {jobData.job_date_of_closing
                                                                        ? jobData.job_date_of_closing
                                                                        : "None"}
                                                                </td>
                                                                <td>{jobData.joining_type}</td>
                                                                <td>{jobData.workplace_type}</td>
                                                                <td>{jobData.category_info?.name}</td>
                                                                <td
                                                                    onClick={() =>
                                                                        setDisplayModal({type:"reject", index})
                                                                    }
                                                                    className={` bg-red-500 text-white font-bold p-2 text-center shadow rounded cursor-pointer`}
                                                                >
                                                                    Reject
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="w-full">
                                                        <thead>
                                                            <tr className='opacity-0 none'>
                                                                <th>No of openings </th>
                                                                <th>Date of closing</th>
                                                                <th>Joining Type</th>
                                                                <th> Workplace</th>
                                                                <th> Category</th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='text-orange-500'>
                                                            <tr className="">
                                                                <td>{jobData.reviewJobs.job_openings}</td>
                                                                <td>
                                                                    {jobData.reviewJobs.job_date_of_closing
                                                                        ? jobData.reviewJobs.job_date_of_closing
                                                                        : "None"}
                                                                </td>
                                                                <td>{jobData.reviewJobs.joining_type}</td>
                                                                <td>{jobData.reviewJobs.workplace_type}</td>
                                                                <td>{jobData.reviewJobs.category_info?.name}</td>
                                                                <td
                                                                    onClick={() =>
                                                                        setDisplayModal({type:"accept", index})
                                                                        
                                                                    }
                                                                    className={`bg-violet-500 text-white font-bold p-2 text-center shadow rounded cursor-pointer`}
                                                                >
                                                                    Accept
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="w-1/2">
                                                        <thead>
                                                            <tr>
                                                                <th>Salary Type</th>
                                                                <th>Range</th>
                                                                <th>Salary Frequency</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="">
                                                                <td>{jobData.job_compensation_type}</td>
                                                                <td>
                                                                    {jobData.salary && jobData.salary}
                                                                    {jobData.job_min_salary &&
                                                                        `${jobData.job_min_salary} - ${jobData.job_max_salary}`}
                                                                    {!(
                                                                        jobData.salary || jobData.job_min_salary
                                                                    ) && "None"}
                                                                </td>
                                                                <td>
                                                                    {jobData.job_salary_frequency
                                                                        ? jobData.job_salary_frequency
                                                                        : "None"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="w-1/2">
                                                        <thead className='opacity-0'>
                                                            <tr>
                                                                <th>Salary Type (Review)</th>
                                                                <th>Range (Review)</th>
                                                                <th>Salary Frequency (Review)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className=" text-orange-500">
                                                                <td>{jobData.reviewJobs.job_compensation_type}</td>
                                                                <td>
                                                                    {jobData.reviewJobs.salary && jobData.reviewJobs.salary}
                                                                    {jobData.reviewJobs.job_min_salary &&
                                                                        `${jobData.reviewJobs.job_min_salary} - ${jobData.reviewJobs.job_max_salary}`}
                                                                    {!(
                                                                        jobData.reviewJobs.salary || jobData.reviewJobs.job_min_salary
                                                                    ) && "None"}
                                                                </td>
                                                                <td>
                                                                    {jobData.reviewJobs.job_salary_frequency
                                                                        ? jobData.reviewJobs.job_salary_frequency
                                                                        : "None"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                    <div className="decription mt-5">
                                                        <th>Description</th>
                                                        <pre className="whitespace-pre-line">
                                                        <div dangerouslySetInnerHTML={{__html:jobData.job_description}} />;

                                                        </pre>
                                                    </div>

                                                    <div className="decription mt-5 text-orange-500">
                                                        <th>Description (Old)</th>
                                                        <pre className="whitespace-pre-line">
                                                        <div dangerouslySetInnerHTML={{__html:jobData.reviewJobs.job_description}} />;

                                                        </pre>
                                                    </div>

                                                    <div className="decription mt-5">
                                                        <th>Skills</th>
                                                        <pre className="whitespace-pre-line">
                                                            {jobData.skills?.length
                                                                ? jobData.skills.join(",")
                                                                : "None"}
                                                        </pre>
                                                    </div>

                                                    <div className="decription mt-5 text-orange-500">
                                                        <th>Skills (old)</th>
                                                        <pre className="whitespace-pre-line">
                                                            {jobData.reviewJobs.skills?.length
                                                                ? jobData.reviewJobs.skills.join(",")
                                                                : "None"}
                                                        </pre>
                                                    </div>

                                                    <div className="decription mt-5">
                                                        <th>Perks</th>
                                                        <pre className="whitespace-pre-line">
                                                            {jobData.perks?.length
                                                                ? jobData.perks.join(",")
                                                                : "None"}
                                                        </pre>
                                                    </div>
                                                    <div className="decription mt-5 text-orange-400">
                                                        <th>Perks (Old)</th>
                                                        <pre className="whitespace-pre-line">
                                                            {jobData.reviewJobs.perks?.length
                                                                ? jobData.reviewJobs.perks.join(",")
                                                                : "None"}
                                                        </pre>
                                                    </div>
                                                    <div className="decription mt-5">
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        {/* New code for comparing jobData.reviewJobs */}
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="w-full bg-gray-100 p-4 transition	delay-700 ease-in shadow-lg mb-2 block ">
                                                    

                                                    
                                                    
                                                    
                                                    
                                                    <div className="decription mt-5">
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </>

                                    )}

                                    {/* New code for comparing jobData.reviewJobs */}
                                    
                                </>
                            ))}
                        </tbody>
                    </table>
                </InfiniteScroll>
                <div className="w-full text-center ">
                    {jobList.hasNext && (
                        <button
                            type="button"
                            className="mx-auto py-2 px-2 shadow bg-violet-600 my-2 rounded text-white"
                            onClick={()=>loadJobList(jobList.jobs.length, 10)}
                        >
                            Load More
                        </button>
                    )}
                </div>
            </div>
        </div>
        {displayModal.type=='reject' && <TextModal description={`Enter Reject Reason for ${jobList.jobs[displayModal.index].job_profile}`} onCancel={resetModalData} onSuccess={updateJob} onReason={(e)=>setRejectreason(e.target.value)}/>}
        {displayModal.type=='accept' && <Modal onCancel={resetModalData} onSuccess={updateJob} description={`Do you want to accept ${jobList.jobs[displayModal.index].job_profile} ?`}/>}
    </div>
);
};

 
export default JobEditReview;