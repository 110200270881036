import { useEffect, useState } from "react";
import axios from "axios";
import PageHeading from "../../components/headings/pageHeading";
import AlertWithClose from "../../components/alert/alertWithClose";
import Multiselect from "multiselect-react-dropdown";

const CustomNotification = () => {
  const [dummyAccounts, set_dummyAccounts] = useState([]);
  const [formData, setForm] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const [colleges, setColleges] = useState([]);
  const [selectedColleges, setSelectedColleges] = useState([]);
  const [allcolleges, setAllColleges] = useState(true);

  const formChanges = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearForm = () => {
    document.getElementById("DummyAccountForm").reset();
    setForm(null);
  };

  const getallColleges = async () => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/colleges`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.data) {
      setColleges(response.data.accounts);
    }
  };
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      console.log(formData, e);
      let send_data = formData;
      formData.allcolleges = allcolleges;
      formData.colleges = selectedColleges;
      const url = `${process.env.REACT_APP_BASE_URL2}/admin/CustomNot`;
      const token = localStorage.getItem("beepsyAuthKey");
      const response = await axios.post(url, send_data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        setAlertData({ message: "Dummy Accounts Added", type: "good" });
        clearForm();
        setSelectedColleges([]);
        setAllColleges(true);
        loadDummyAccounts();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setAlertData({ message: error.response.data.error, type: "bad" });
      } else setAlertData({ message: "Unable to delete brand", type: "bad" });
    }
  };

  const clearAlert = () => {
    setAlertData(null);
  };

  const loadDummyAccounts = async () => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/CustomNot`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.data) {
      set_dummyAccounts(response.data.accounts.reverse());
    }
  };

  const onSelect = (event) => {
    setSelectedColleges(event);
  };
  const onRemove = (event) => {
    setSelectedColleges(event);
  };

  useEffect(loadDummyAccounts, []);
  useEffect(getallColleges, []);

  return (
    <div className="create-brand w-full">
      <PageHeading heading="Custom Notifications" />
      <form onSubmit={submitHandler} id="DummyAccountForm">
        <div className="flex flex-row">
          <div className="px-4 py-3">
            <input
              type="text"
              name="title"
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Title"
              onChange={formChanges}
            />
          </div>
          <div className="px-4 py-3">
            <input
              type="text"
              name="body"
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Body"
              onChange={formChanges}
            />
          </div>
          <div className="px-4 py-3">
            <label>All Colleges</label>
            <input
              type="checkbox"
              name="allcolleges"
              checked={allcolleges}
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="All Colleges"
              onClick={(e) => {
                setAllColleges(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className="flex flex-row">
          {allcolleges ? null : (
            <div className="px-4 py-3">
              <Multiselect
                label="Select Colleges"
                options={colleges} // Options to display in the dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="collegeName" // Property name to display in the dropdown options
              />
            </div>
          )}

          <div className="px-4 py-3">
            <button
              type="submit"
              className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96"
            >
              Send Notifications
            </button>{" "}
          </div>
        </div>
      </form>
      <table className="text-sm text-left text-slate-500 mx-5 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
          <tr>
            <th scope="col" className="px-6 py-3">
              Title
            </th>
            <th scope="col" className="px-6 py-3">
              Body
            </th>
            <th scope="col" className="px-6 py-3">
              All Colleges
            </th>
            <th scope="col" className="px-6 py-3">
              Number of Colleges
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Date and Time
            </th>
          </tr>
        </thead>
        <tbody>
          {dummyAccounts &&
            dummyAccounts.map((account, index) => (
              <tr
                className={`bg-white border-b hover:bg-slate-200 ${
                  index % 2 === 0 ? "" : "bg-slate-100"
                }`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 "
                >
                  {account.title}
                </th>
                <td className="px-6 py-4">{account.body}</td>
                <td className="px-6 py-4">
                  {account.allcolleges ? <>True</> : <>False</>}
                </td>
                <td className="px-6 py-4">{account.colleges.length}</td>
                <td className="px-6 py-4">{account.status}</td>
                <td className="px-6 py-4">{account.timestamp}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {alertData && (
        <AlertWithClose
          clearAlert={clearAlert}
          message={alertData.message}
          time={4}
          type={alertData.type}
        />
      )}
    </div>
  );
};

export default CustomNotification;
