import React, { useEffect, useRef, useState } from 'react';
import PageHeading from '../../components/headings/pageHeading';
import axios from 'axios';
import moment from 'moment';
import Modal from '../../components/modal/modal';

const Course = () => {
    const [course, setCourse] = useState([]);
    const [activeEditIndex, setActiveEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [missingMessage, setMissingMessage] = useState(null);
    const downloadAnchor = useRef(null);
    

    const [waitlistRefreshIndex, setWaitlistRefreshIndex] = useState(null);

    const clearWailtistIndex  = ()=> setWaitlistRefreshIndex(null);

    // const []

    const onChangeBufferCount = (index, count)=>{
      setCourse(prev=>{
        const cou = [...prev];
        cou[index]["waitlistBuffer"] = count;
        cou[index]["waitlistBufferChanged"]  = true;

        return cou;
      })
    }

    const onSubmitWailtlistBuffferCount = async (index)=>{

      const url = `${process.env.REACT_APP_BASE_URL}/course/bufferWaitlist`;
      const token = localStorage.getItem("authKey");
      
      const response = await axios.post(url, 
        {
          courseId: course[index]._id,
          waitlistBuffer: course[index].waitlistBuffer
        },{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
      });

      loadCourse();

    }

    const onSubmitWailtlistRefresh = async ()=>{

      const url = `${process.env.REACT_APP_BASE_URL}/course/clear/${course[waitlistRefreshIndex]._id}`;
      const token = localStorage.getItem("authKey");
      
      const response = await axios.delete(url, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
      });

      loadCourse();
      clearWailtistIndex()

    }

    const onSubmitBypassWaitlist = async (index, status)=>{

      setCourse(prev=>{
        const cou = [...prev];
        cou[index]["hideCheckbox"]  = true;

        return cou;
      })

      const url = `${process.env.REACT_APP_BASE_URL}/course/bypasswaitlist/${course[index]._id}`;
      const token = localStorage.getItem("authKey");
      
      const response = await axios.post(url, {
        bypassWaitlist:status
      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
      });

      loadCourse();
      clearWailtistIndex()

    }

    const loadCourse = async () => {
        try {
          const url = `${process.env.REACT_APP_BASE_URL}/course/admin`;
          const token = localStorage.getItem("authKey");
          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
    
          setCourse(response.data.course);
          console.log(response.data);
        } catch (e) {
          console.log(e);
        }
      };

    const onEdit = (index) => {
        setActiveEditIndex(index);
        setEditData({});
        setMissingMessage(null)
    }

    const onformChange = (e) => {
      if(e.target.name === "registrationStartTime" || e.target.name === "registrationEndTime") {
        setEditData({...editData, [e.target.name]: new Date(e.target.value).toISOString()});
      }else setEditData({...editData, [e.target.name]: e.target.value});
    };

    const onEditSubmit = async () => {

        try {
            const url = `${process.env.REACT_APP_BASE_URL}/course/admin/extend/${course[activeEditIndex]._id}`;
            const token = localStorage.getItem("authKey");
            // handle missing values
            if(!editData.registrationStartTime) {
                setMissingMessage("Start time is missing");
                return;
            }
            if(!editData.registrationEndTime) {
                setMissingMessage("End time is missing");
                return;
            }
            if(!editData.seatCount) {
                setMissingMessage("Seat count is missing");
                return;
            } 
            const response = await axios.put(url, 
              editData,{
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                }
            });
      
            onEdit(null);
            loadCourse();
            
          } catch (e) {
            console.log(e);
          }
    }

    useEffect( loadCourse, []);

    const downloadFile = async (index, endpoint) => {
      try {
          const url = `${process.env.REACT_APP_BASE_URL}/admin/course/${endpoint}/${course[index]._id}`;
          const token = localStorage.getItem("authKey");
  
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const urlF = URL.createObjectURL(blob);
          downloadAnchor.current.href = urlF;
          downloadAnchor.current.download = `${course[index].name}_${endpoint}.csv`;
          downloadAnchor.current.click();
          URL.revokeObjectURL(urlF);
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    return (  
        <div> 
            <PageHeading heading="Course" />

            <div className="flex flex-col">

                {/* edit course start,end and seat count */}
                {missingMessage && <div className="bg-red-500 text-white p-4 mx-4 my-4">{missingMessage}</div>}
               { activeEditIndex!=null && (<div className="flex flex-row justify-between mx-4 my-4 items-end">
                    <div className="flex flex-col">
                        <label className="text-lg font-bold">Edit Course</label>
                        <input type="text" value={course[activeEditIndex].name} className="border border-gray-400 rounded-md p-2 w-64" placeholder="Course Name"  disabled/>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg font-bold">Start Time</label>
                        <input type="datetime-local" name="registrationStartTime"
                        min={moment().format("YYYY-MM-DDTHH:mm")}
                        onChange={onformChange} className="border border-gray-400 rounded-md p-2 w-64" />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg font-bold">End Time</label>
                        <input type="datetime-local" name="registrationEndTime"
                        disabled={editData.registrationStartTime? false:true}
                        min={editData.registrationStartTime? editData.registrationStartTime:moment().format("YYYY-MM-DDTHH:mm")}
                         onChange={onformChange} className="border border-gray-400 rounded-md p-2 w-64" />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg font-bold">Total Seats</label>
                        <input type="number" name="seatCount" onChange={onformChange}  className="border border-gray-400 rounded-md p-2 w-64" />
                    </div>
                    
                    <div><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onEditSubmit}>Change</button> </div>
                </div>)}

                <table className="table-auto w-full bg-white shadow mx-4 my-4">
                <thead>
                    <tr>
                    <th className="px-4 py-2">Course Name</th>
                    <th className="px-4 py-2">Start time</th>
                    <th className="px-4 py-2">End time</th>
                    <th className="px-4 py-2"> Total Seats</th>
                    <th className="px-4 py-2">Wait List Buffer</th>
                    <th className="px-4 py-2">Bypass</th>
                    <th className="px-4 py-2">Wait List count</th>
                    <th className="px-4 py-2">Action</th>
                    <th className="px-4 py-2">Refresh</th>
                </tr>
                </thead>
                <tbody>
                {course.map((course, index) => (   

                    <tr className={`${index==activeEditIndex? 'bg-slate-500':""}`}>
                      <td className="border px-4 py-2 flex">
                    <button  onClick={()=>downloadFile(index, "waitlist")} className=" p-3 bg-violet-500 shadow rounded mr-2 text-white scale-75"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>W
  </button>
  <button  onClick={()=>downloadFile(index, "register")} className=" p-3 bg-violet-500 shadow rounded mr-2 text-white scale-75"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>R
  </button>
                    <div className="">{course.name}</div>
                  </td>
                    
                    <td className={`border px-4 py-2 bg-opacity-60 ${moment(course.registrationStartTime).isAfter(moment())? 'bg-green-600':"bg-red-600"}`}>{ moment(course.registrationStartTime).format("DD MMM hh:mm")}</td>
                    <td className={`border px-4 py-2 bg-opacity-60 ${moment(course.registrationEndTime).isAfter(moment())? 'bg-green-600':"bg-red-600"}`}>{moment(course.registrationEndTime).format("DD MMM hh:mm")}</td>
                    <td className="border px-4 py-2">{course.totalSeatCount}</td>
                    <td className="border px-4 py-2">
                      <div className="flex">
                        <input  value={course.waitlistBuffer} className='border p-1' onChange={(e)=>onChangeBufferCount(index, e.target.value)} />
                       {course.waitlistBufferChanged && <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 text-sm ml-2 rounded"
                     onClick={()=>onSubmitWailtlistBuffferCount(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


                     </button>}
                      </div>
                    </td>

                    <td className="border px-4 py-2">
                      <div className="flex">
                       {!course.hideCheckbox && <input type='checkbox'  checked={!!course.bypassWaitlist} className='border p-1' onChange={(e)=>onSubmitBypassWaitlist(index, e.target.checked)} />}
                       {/* {course.waitlistBufferChanged && <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 text-sm ml-2 rounded"
                     onClick={()=>onSubmitWailtlistBuffferCount(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


                     </button>} */}
                      </div>
                    </td>

                    <td className="border px-4 py-2">{course.waitlistCount}</td>
                    <td className="border px-4 py-2"> 
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                     onClick={()=>onEdit(index)}>Edit</button>
                    </td>
                    <td className="border px-4 py-2"> 
                    <button className="bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-2 rounded"
                     onClick={()=>setWaitlistRefreshIndex(index)}>Waitlist</button>
                    </td>
                    </tr>
                ))}
                </tbody>
                </table>
                </div>

                {/* {deleteRoomIndex != null && ( */}
        { waitlistRefreshIndex!=null && <Modal
          description={`Do you want to refresh ${course[waitlistRefreshIndex].waitlistCount} waitlist users on ${course[waitlistRefreshIndex].name}?`}
          onCancel={clearWailtistIndex}
          onSuccess={onSubmitWailtlistRefresh}
        /> }
      
                <a ref={downloadAnchor}></a>
        </div>
    );
}
 
export default Course;