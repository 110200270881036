import React from 'react'

const StatusBadge = ({ status }) => {
	const getText = status => {
		switch (status) {
			case 0:
				return 'Hold'
			case 1:
				return 'Active'
			case 2:
				return 'Closed'
			case 3:
				return 'Flag'
			default:
				return 'Invalid'
		}
	}

	const getColor = status => {
		switch (status) {
			case 0:
				return 'gray-600'
			case 1:
				return 'green-600'
			case 2:
				return 'yellow-400'
			case 3:
				return 'red-400'
			default:
				return 'Invalid'
		}
	}

	return (
		<div
			className={`statusBadge border font-bold bg-white rounded-full text-center border-${getColor(
				status
			)} text-${getColor(status)} `}
		>
			<div>{getText(status)}</div>
		</div>
	)
}

export default StatusBadge
