import React, { useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import AlertWithClose from '../../components/alert/alertWithClose';
import InputModal from '../../components/modal/inputModal';
import { sendNotificationToUser } from '../../service/service';
import PageHeading from '../../components/headings/pageHeading';


const SearchUser = () => {

    const [formData, setForm] = useState([]);
    const [users, setUsers] =  useState([]);
    const [lastField, setLastField] = useState(null)
    const [alertData, setAlertData] = useState(null);
    const [blockReason, setBlockReason] = useState('');
    const [blockData, setBlockData] =  useState(null);
    const [blockUnblock, setBlockUnblock] = useState(null);

    const onResonChange = (e)=>{
        setBlockReason(e.target.value);
    }

    const clearAlert= ()=>{
        setAlertData(null);
    }

    const formChanges = (field, value) => {
        setForm(prev => ({
            ...prev,
            [field]: value
        }))

        console.log(formData)
    }
/* eslint-disable no-underscore-dangle */

    const onSubmit= async (field, type)=>{

        try{
            if(!formData[field]){
                setAlertData({message: `${field} is mandatory`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/user`;
    
            const token = localStorage.getItem('authKey');

            console.log({
                [field]:formData[field]
            })

            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`,   
            },
            params:{
                [field]:formData[field],
                limit: 200,
                skip: type==='more'?users.length:0
            }   
        } )
    
        if(response.status===200){
            setAlertData({message: 'User list updated', type:'good'});
            if(type==='more')
            setUsers(prev=>[...prev, ...response.data.suggestions]);
            else{
            setLastField(field);
            setUsers(response.data.suggestions);
            }
            console.log(response, users)
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to get users', type:'bad'})
                }
                else
                setAlertData({message:'Unable to get users', type:'bad'})
            }
        
    }

    const loadBlockData = (index, block)=>{
        setBlockData(users[index]);
        setBlockUnblock(block);

    }

    const clearBlockData =  ()=>{
        setBlockData(null)
        setBlockReason("")
        setBlockUnblock(null);
    }

    const blockUser =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/block`;
    
            const token = localStorage.getItem('authKey');

            console.log(blockReason,blockReason === null , blockReason ==='', 'Block reason')
            if(blockUnblock && (blockReason === null || blockReason ==='')) { 
                setAlertData({message: 'Reason is required', type:'bad'}); 
                console.log(blockReason, 'Block reason inside')
                return;}
            const promise = sendNotificationToUser(blockData._id, `You are ${blockUnblock?'blocked':'unblocked'} by EB Community`, blockReason, true)
            const response = await axios.post(url, {reason: blockReason, id: blockData._id, block:blockUnblock},{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
        onSubmit(lastField);
        await promise;
    
        if(response.status===200){
            setAlertData({message: `User ${blockUnblock?'blocked':'unblocked'}`, type:'good'});

            
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to block user', type:'bad'})
                }
                else
                setAlertData({message:'Unable to block user', type:'bad'})
            }
        
            clearBlockData();

    }

    return (
        <div className='w-full'>
            <PageHeading heading='search User' />
            <div className="flex flex-col border-b-2 border-yellow-400">
                <div className="flex flex-row">
                    <div className="basis-1/6 px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Name" id="userName" name="userName" onChange={data => formChanges("name", data.target.value)} /></div>
                    {/* <div className="basis-1/6 px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Phone No" id="userPhone" name="userPhone" onChange={data => formChanges("phoneNo", data.target.value)} /></div> */}
                    <div className="px-4 py-3"><button type="submit" className="bg-violet-400 text-white font-medium  shadow-md hover:shadow-sm py-2 my-1 px-3 rounded w-96 " onClick={()=>onSubmit('name')}>Search By Name</button> </div>

                </div>
                <div className="flex flex-row">    
                     
                    <div className="px-4 py-3"><input type="text" className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300 border-4 " placeholder="User ID" id="userClg" name="userClg" onChange={data => formChanges("id", data.target.value)} /></div>
                    <div className="px-4 py-3"><button type="submit" className="bg-violet-400 text-white font-medium  shadow-md hover:shadow-sm py-2 my-1 px-3 rounded w-96 border-3" onClick={()=>onSubmit('id')}>Search By Id</button> </div>
                </div>


                {/* <div className="flex-1 px-4 py-3"><input type="text" className ="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Name" id="userName" name="userName" onChange={data=>formChanges("email", data.target.value)}/></div> */}
            </div>
            <div className="text-center mx-2">
                
                {users?(<div className='grid grid-cols-3 items-stretch justify-items-stretch ' style={{gridAutoRows: '1fr', gridAutoCols:'1fr'}}>
                {users.map((user, index)=>(
                    
                    <div className="bg-yellow-400 px-4 py-4 mx-2 my-2 h-100 rounded  shadow-sm">
                    

                    <div className="flex border-b border-slate-700">
                    <div className="w-1/6">
                        {user.personalInfo.avatar&&<img className="text-sm rounded-full text-ellipsis whitespace-nowrap overflow-hidden h-10 w-10" src={user.personalInfo.avatar} alt="userprofile" />}
                    </div>
                    <div className="flex flex-col text-left w-4/6">
                    <div className='font-semibold bottom-0 text-ellipsis whitespace-nowrap overflow-hidden ' title={user.personalInfo.name}>{user.personalInfo.name}</div>
                    {user.created_at && <div className="text-sm text-ellipsis whitespace-nowrap overflow-hidden " >{new Date(user.created_at).toLocaleString()}</div>}
                    </div>
                    <div>
                       <div className="flex">
                       {user.blocked?<button type='button' className='bg-green-600 text-white px-2 py-2 rounded shadow-sm text-xs' onClick={()=>loadBlockData(index, false)}>Unblock</button>: <button type='button' className='bg-black text-yellow-400 px-2 py-2 rounded shadow-sm text-xs' onClick={()=>loadBlockData(index, true)}>Ban</button>}
                       <Link target="new" className="ml-2 bg-green-600 text-white px-2 py-2 rounded shadow-sm  text-xs mr-2" to={`/user/role/${user._id}/none`} >Role</Link>
                       </div>
                    </div>
                    
                    
                    </div>
                    
                    
                    
                    <div className="flex pt-1 ">
                        {user.collegeInfo && <div className="flex flex-col text-left">
                            <div className="text-sm">{`User ID: ${user._id}`}</div>
                            
                            <div className="text-sm w-11/12  overflow-hidden font-semibold" title={user.collegeInfo.collegeName}>{user.collegeInfo.collegeName}</div>
                            <div className="text-xs" title={user.collegeInfo.collegeId}>{user.collegeInfo.collegeId}</div>
                            <div className="text-sm">{`${user.collegeInfo.stream} - ${user.collegeInfo.passout}`}</div>
                            <div className="flex">
                            {user.personalInfo && user.personalInfo.role && <div className="bg-green-600 text-white px-2 rounded shadow-sm mr-1 mb-1 ml-auto mr-0 text-xs">{user.personalInfo.role[0].toUpperCase()}</div>}
                            </div>
                        </div> }
                        
                    </div>
                    
                    </div>
                    
                ))}
                </div>):"No result"}
                {users && users.length>199 &&users.length%200===0 && <div className="px-4 pb-5"><button type="submit" className="bg-yellow-400 shadow-lg shadow-yellow-400/50 py-2 my-1 px-3 rounded w-96" onClick={()=>onSubmit(lastField, "more")}>Load More</button> </div>}
            </div>
                    
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            {blockData && <InputModal description={`Do you want to ${blockUnblock?"block":"unblock"} ${blockData.personalInfo.name} ?`} onSuccess={blockUser} onCancel={clearBlockData} onReason={onResonChange} /> }
        </div>
    );
}

export default SearchUser;