import React, { useEffect, useState } from 'react';
import axios from 'axios';

import PageHeading from '../../components/headings/pageHeading';
import Modal from '../../components/modal/modal';
import AlertWithClose from '../../components/alert/alertWithClose';
    


const DummyAccount = () => {

    const [formData, setForm] = useState(null);
    const [dummyAccounts, setDummyAccounts] =  useState([]);
    const [deleteData, setDeleteData] =  useState(null);
    const [alertData, setAlertData] = useState(null)

    // const auth = useContext(AuthContext)
    // console.log(auth)


    const formChanges = (e) => {
        const {name, value} = e.target;

        setForm(prev => ({
            ...prev,
            [name]: value
        }))
    }

    
    const clearForm = () =>{
        document.getElementById('DummyAccountForm').reset();
        setForm(null);
    }


    const loadBrands = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/dummyAccounts`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setDummyAccounts(response.data.dummyAccounts )
            console.log(response);


        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(async()=>{
        await loadBrands()
    },[])


    const submitHandler = async (e)=>{
        // onClick={submitHandler}

        try{
        e.preventDefault();
        console.log(formData, e)

        const url = `${process.env.REACT_APP_BASE_URL}/admin-users/dummyAccounts`;
        const data = formData
        data.phone_number = "+91".concat(data.phone_number)

        const token = localStorage.getItem('authKey');

        const response = await axios.post(url,data, {headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
        
    } )
    

    if(response.status===200){
        setAlertData({message: 'Dummy Account Added', type:'good'})
        clearForm()
        loadBrands()

    }
        }catch(error){
            if(error.name ==='AxiosError'){
                
                setAlertData({message: error.response.data.error, type:'bad'})
            }
            else
            setAlertData({message:'Unable to delete brand', type:'bad'})
        }

    }

    const clearAlert= ()=>{
        setAlertData(null);
    }

    /* eslint-disable no-underscore-dangle */
    const loadDeleteData = (index)=>{
        setDeleteData(dummyAccounts[index]);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
    }

    const deleteBrand =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/dummyAccounts/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.delete(url, {headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setAlertData({message: 'Dummy Account deleted', type:'good'});

            loadBrands()
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    
                    setAlertData({message: error.response.data.error, type:'bad'})
                }
                else
                setAlertData({message:'Unable to delete Dummy Account', type:'bad'})
            }
        
            clearDeleteData();

    }

    return ( 
        <div className="create-brand w-full">
            <PageHeading heading='Dummy Accounts' />
            <form onSubmit={submitHandler} id="DummyAccountForm">
            <div className="flex flex-row">
                    <div className="px-4 py-3"><input type="text" name='phone_number' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Number" onChange={formChanges} /></div>
                    <div className="px-4 py-3"><input type="text" name='otp' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Static OTP" onChange={formChanges} /></div>
                    <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96" >Add Account</button> </div>
                </div>
            </form>

            <table className="text-sm text-left text-slate-500 mx-5 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    OTP
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            {dummyAccounts && dummyAccounts.map((account, index) =>
                                <tr className={`bg-white border-b hover:bg-slate-200 ${index%2===0?'': 'bg-slate-100'}`}>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                        {account._id}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                        {account.phone_number}
                                    </th>
                                    <td className="px-6 py-4">
                                        {account.otp}
                                    </td>
                                    <td className="px-6 py-4 h-10 ">
                                    <button type="submit" className="bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 h-full px-3 p-2 rounded w-24 text-white" onClick={()=>loadDeleteData(index)} >Delete</button>
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>

                    {deleteData && <Modal description={`Do you want to delete ${deleteData.name} ?`} onSuccess={deleteBrand} onCancel={clearDeleteData} /> }
                    { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
        </div>
     );
}
 
export default DummyAccount;