import React, { useEffect, useState } from "react";
import PageHeading from "../../components/headings/pageHeading";
import axios from "axios";
import AlertWithClose from "../../components/alert/alertWithClose";

const Referral = () => {
  const [refCount, setRefCount] = useState(null);
  const [alertData, setAlertData] = useState(null);

  const [formData, setForm] = useState({
    number: "",
    start_time: "",
    end_time: "",
  });

  const formChanges = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loadcOUNT = async () => {
    try {
      if (!formData.number) {
        setAlertData({ message: "Mobile number is required", type: "bad" });
        return;
      }
      const url = `${process.env.REACT_APP_BASE_URL}/admin/get_refferal_count`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          number: formData.number,
          start_time: formData.start_time
            ? new Date(formData.start_time).toISOString()
            : undefined,
          end_time: formData.end_time
            ? new Date(formData.end_time).toISOString()
            : undefined,
        },
      });

      setRefCount(response.data);
      setAlertData({ message: response.data.message, type: "good" });
    } catch (error) {
      if (error?.response?.data?.error)
        setAlertData({ message: error?.response?.data?.error, type: "bad" });
      else setAlertData({ message: "Something went wrong", type: "bad" });
    }
  };

  return (
    <div>
      <PageHeading heading="Referral" />

      <div className="form">
        <div>
          <div className="bg-white m-4">
            <h2 className="font-bold px-4 pt-3">Referral</h2>
            <div className="flex flex-row ">
              <div className="px-4 py-3 flex flex-col">
                <div className="h1 font-semibold mb-1">Phone number</div>
                <input
                  type="text"
                  name="number"
                  className="form-input rounded outline-gray-400 px-4 py-3 outline-2 bg-gray-300"
                  placeholder="Enter mobile number"
                  minLength={10}
                  maxLength={10}
                  value={formData.number}
                  onChange={formChanges}
                />
              </div>

              <div className="px-4 py-3 flex flex-col">
                <div className="h1 font-semibold mb-1">Start date (opt)</div>

                <input
                  type="datetime-local"
                  name="start_time"
                  className="form-input rounded outline-gray-400 px-4 py-3 outline-2 bg-gray-300"
                  placeholder="Start date"
                  max={new Date()}
                  value={formData.start_time}
                  onChange={formChanges}
                />
              </div>

              <div className="px-4 py-3 flex flex-col">
                <div className="h1 font-semibold mb-1">End date (opt)</div>

                <input
                  type="datetime-local"
                  name="end_time"
                  className="form-input rounded outline-gray-400 px-4 py-3 outline-2 bg-gray-300"
                  placeholder="End date"
                  max={new Date()}
                  value={formData.end_time}
                  onChange={formChanges}
                />
              </div>

              <div className="px-4 py-3 self-end	">
                <button
                  type="submit"
                  onClick={loadcOUNT}
                  className="shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-36 bg-violet-500 text-white"
                >
                  Load
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      {refCount && (
        <div className="count px-4">
          <div className="bg-white px-4 py-4">
            <h1 className="font-bold">Count</h1>
            <div className="font-bold text-2xl">{refCount.count}</div>
          </div>
        </div>
      )}
      {alertData && (
        <AlertWithClose
          message={alertData.message}
          type={alertData.type}
          time={4}
          clearAlert={() => setAlertData(null)}
        />
      )}
    </div>
  );
};

export default Referral;
