import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';


const AddUserToChannel = () => {

    const [ data, setData] =  useState(null);
    const [phoneNumbberslist, setPhonenumberslist] =  useState([]);
    const [alertData, setAlertData] = useState(null);
    const { clubId } = useParams();
    const [unableToAddNumbers, setUnabletoAddNumbers] = useState([]);
    const [invlidusersList, setInvalidUsersList] =  useState([]);

    const [isLoading, setIsLoading] =  useState(false);

    const phoneNumberPatterns = new RegExp("^\\+91[6-9]{1}[0-9]{9}$");

    useEffect(()=>{
        setData("hi")
    },[])


    const clearAlert= ()=>{
        setAlertData(null);
    }


    const onUserListChange = (e)=>{
        const {value} = e.target;

        if(value)
        setPhonenumberslist(()=>{
            const tempPhNos = value.split("\n");
            return tempPhNos.filter(phno=> phno)


        })
        else
        setPhonenumberslist([])

        console.log(value, data)
    }

    const onSubmit = async()=>{
        setIsLoading(true);

        setUnabletoAddNumbers([]);
        setInvalidUsersList([]);

        for(let i =0; i< phoneNumbberslist.length; i+=1){
            if(!phoneNumberPatterns.test(phoneNumbberslist[i])) {
                setAlertData({message: `Invalid phone number - ${phoneNumbberslist[i]}`, type: 'bad'})
                setIsLoading(false);
                return;
            }
        }

            const url = `${process.env.REACT_APP_BASE_URL}/clubs/add/private/${clubId}`;
            const token = localStorage.getItem('authKey');
            const payloadData = {
                "userPhoneNos":phoneNumbberslist
            }

            try{
            const response = await axios.post(url, JSON.stringify(payloadData),{ headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });
            if(response.status === 200){
                if(response.data && response.data.response && response.data.response){
                    if(response.data.response.UnableToAdd.length===0)
                    setAlertData({message:"All users added", type: 'good'})
                    const tempNumbers = response.data.response.UnableToAdd;

                    setUnabletoAddNumbers(tempNumbers);
                }
            }
            else setAlertData({message:"Some error occured"})
            }catch(error){
                console.log(error)
                if(error.name === "AxiosError"){
                    if(error.response.data){
                        
                        setAlertData({message:error.response.data.message || error.response.data.code})

                        if(error.response.data.code === "INVALID_USERS")
                        setInvalidUsersList(error.response.data.userList);

                    }

                }
            }

            setIsLoading(false);
            

    }

    
    return ( 
        <div className='w-full'>
            <PageHeading heading="Add user"  className="w-full"/>

            <div className="flex">

            <div className='my-2'>
            
            <label htmlFor="type" className='px-4 py-2 mx-2 text-lg font-bold'> Users phone number</label>
            
            <div className="flex">

            <div className="px-4 ">
                    <textarea name='couponListRaw'
                    className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" 
                    placeholder="Eg) +917811964xxx. Phone numbers sepeerated by line.  " 
                    rows='10'
                    onChange={onUserListChange} 
                    />
                </div>



            <div className="mx-2">
                {phoneNumbberslist.map((phoneNumber)=>
                
                (<div className="flex">
                   <div className='w-48 truncate'>{phoneNumber} </div>
                   <div className=''>{phoneNumberPatterns.test(phoneNumber)?'✅': '❌'}</div>
                    </div>)
                )}
            </div>

            </div>

            <button className='shadow rounded bg-yellow-400 outline-none w-96 py-2 m-4 active:shadow-sm active:bg-yellow-500 disabled:bg-black disabled:text-white' type='submit' disabled={isLoading} onClick={onSubmit}>{ isLoading?"Loading..":"Add users"}</button>
            
            
            </div>


            
        
            </div>

            {unableToAddNumbers.length > 0 && <div className="div mx-4">
                    <div className="font-bold text-lg text-red-600">Unable to Add <span className="font-normal text-base text-black">(Already member) </span></div>
                    {unableToAddNumbers.map(phoneno=> <div> {phoneno}</div>)}
                    { unableToAddNumbers.length !== phoneNumbberslist.length &&  <div className="font-bold text-green-600"> Other users added</div>}
            </div>}

            {invlidusersList.length > 0 && <div className="div mx-4">
                    <div className="font-bold text-lg text-red-600">Invalid Users <span className="font-normal text-base text-black">(Users Not exit in our DB ) </span> </div>
                    <div className="font-normal text-base text-red-600">Remove / check the users below to proceed further </div>
                    {invlidusersList.map(phoneno=> <div> {phoneno}</div>)}
            </div>}

            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}

        </div>
     );
}
 
export default AddUserToChannel;