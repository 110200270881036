import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import env from 'react-dotenv'
import { useNavigate } from 'react-router-dom'

import loginImage from '../../assets/login.webp'
import { AuthContext } from '../../context'

const Login = () => {
	const [formData, setForm] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const [authError, setAuthError] = useState(null)
	const [otpSent, setOtpSent] = useState(false)

	const navigate = useNavigate()

	const auth = useContext(AuthContext)
	console.log(auth)

	const formChanges = (field, value) => {
		setAuthError(null)
		setForm(prev => ({
			...prev,
			[field]: value,
		}))

		console.log(formData)
	}

	const sendOTP = async data => {
		data.preventDefault()
		console.log(formData)
		setIsLoading(true)
		try {
			const url = `${env.REACT_APP_BASE_URL}/admin/send_otp`
			const reqData = JSON.stringify({
				number: `+91${formData.phone}`,
			})

			const response = await axios.post(url, reqData, {
				headers: { 'Content-Type': 'application/json' },
			})

			if (response.data) {
				setOtpSent(true)
			}
		} catch (e) {
			if (e?.response?.data?.error) setAuthError(e?.response?.data?.error)
			else setAuthError('Something went wrong')
			console.log(e)
		}
		setIsLoading(false)
	}

	const Verifyotp = async () => {
		setIsLoading(true)
		try {
			const url = `${env.REACT_APP_BASE_URL}/admin/verify_otp`
			const reqData = JSON.stringify({
				number: `+91${formData.phone}`,
				otp: formData.otp,
			})

			const response = await axios.post(url, reqData, {
				headers: { 'Content-Type': 'application/json' },
			})

			localStorage.setItem('authKey', response.data.token)

			if (response.data.token) {
				window.location.reload()
				navigate('/job')
			}
			console.log(response)
		} catch (e) {
			if (e?.response?.data?.error) setAuthError(e?.response?.data?.error)
			else setAuthError('Something went wrong')
		}
		setIsLoading(false)
	}

	useEffect(() => {
		const authkey = localStorage.getItem('authKey')

		if (authkey) navigate('/post')
	}, [])

	// const signOut = (event)=>{
	//     event.preventDefault()
	//     auth.signOut()
	// }

	return (
		<div>
			<div
				className='flex m-auto w-9/12 '
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
				<div className='flex-1 mx-auto w-100 shadow-md'>
					<div>
						<div className='flex flex-col shadow    pb-10  pt-8  bg-white rounded-tl-lg rounded-bl-lg border-l-2 border-t-2 border-b-2 border-violet-400'>
							<h2 className='px-4 font-bold text-4xl tracking-wider mx-auto'>Beep</h2>
							<span className='px-4 font-semibold text-stone-700 text-lg mx-auto tracking-widest pb-10 pt-3'>
								Make the community fun and safe.
							</span>

							{/* <div className="flex-1 px-4 py-3"><label htmlFor="loginEmail" >Email Address</label></div> */}
							<div className='flex-1 px-4 py-3 mx-auto'>
								<input
									type='text'
									className='form-input rounded outline-violet-400 px-4 py-3 outline-2 w-96 bg-slate-300'
									placeholder='Enter Phone number'
									maxLength='10'
									id='phone'
									name='phone'
									disabled={otpSent}
									onChange={data => formChanges('phone', data.target.value)}
								/>
							</div>

							{/* <div className="flex-1 px-4 py-3"><label htmlFor="loginPassword" >Password</label></div> */}
							{otpSent && (
								<div className='flex-1 px-4 py-3 mx-auto'>
									<input
										placeholder='Enter OTP'
										type='otp'
										className='form-input rounded outline-violet-400 px-4 py-3 outline-2 w-96 bg-slate-300'
										name='otp'
										id='otp'
										maxLength={4}
										minLength={4}
										onChange={data => formChanges('otp', data.target.value)}
									/>
								</div>
							)}

							{/* <div className="flex-1  px-4 py-3"><a href="/" >Forget Password ?</a></div> */}

							<div className='flex-1  px-4 py-3 mx-auto'>
								<button
									type='submit'
									onClick={otpSent ? Verifyotp : sendOTP}
									className='bg-violet-400 font-medium  text-white shadow-lg hover:shadow-sm py-2 my-1 px-3 rounded w-96 text-center'
								>
									{isLoading ? (
										<svg
											role='status'
											className='w-6 h-6 mr-2 text-gray-700 animate-spin  bg-violet-400 out'
											viewBox='0 0 100 101'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
												fill='currentFill'
											/>
											<path
												d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
												fill='#fff'
											/>
										</svg>
									) : (
										<>{otpSent ? 'Login' : 'Send OTP'}</>
									)}
								</button>{' '}
							</div>
							{/* <div className="flex-1  px-4 py-3"><button type="button" onClick={signOut} className="bg-red-500 bg-red-500 shadow-lg shadow-red-500/50 text-white py-2 px-3 rounded ">LogOut</button> </div> */}
							<div
								className={`text-sm text-center font-bold text-red-400 px-4 ${
									authError ? 'visible' : 'invisible'
								}`}
							>
								{authError || 'Nope'}
							</div>
						</div>
					</div>
				</div>
				<div
					className='flex-1 bg-yellow-400 shadow-lg hidden lg:inline-block rounded-r-xl '
					style={{
						backgroundImage: `url(${loginImage})`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				>
					{/* <image src={loginImage} alt="Login"/> */}
				</div>
			</div>
		</div>
	)
}

export default Login
