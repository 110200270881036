import React, { useState } from 'react';
import axios from 'axios';
import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';

const SendUserNotificaion = () => {
    const [formData, setFormData] =  useState({userId:"", title:"", message:""});
    const [notificationData, setNotificationData] = useState(null)

    const onFormDataChange=(e)=>{
        const {name, value} = e.target;

        setFormData(prev=>({
            ...prev,
            [name]:value
        }))
        console.log(formData,e);
    }

    const clearAlert= ()=>{
        setNotificationData(null);
    }

    const submitHandler = async(event)=>{
        event.preventDefault()
        console.log(formData);

        try {
            const url = `${process.env.REACT_APP_BASE_URL}/users/notifications/${formData.userId}`;
            const token = localStorage.getItem('authKey');
            const data = {title:formData.title, message:formData.message, save: true}
            const response = await axios.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            if(response){
                setNotificationData({message: "Notification sent", type:'good'})
            }
        }
        catch (e) {
            console.log(e);
            setNotificationData({message: "Unable to send", type:'bad'})
        }

    }

    return (  
        <div className="userNotification w-full">
            <PageHeading heading='Send Notification' />
            <form onSubmit={submitHandler} id="BrandCreateForm">
            <div className="flex flex-row">
                    <div className="px-4 py-3"><input type="text" name='userId' value={formData.userId} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter User ID" onChange={onFormDataChange} /></div>
                    <div className="px-4 py-3"><input type="text" name='title' value={formData.title} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Notification title" onChange={onFormDataChange} /></div>
                    <div className="px-4 py-3"><input type="text" name='message' value={formData.message} className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Notification body" onChange={onFormDataChange} /></div>
                </div>
                <div className="flex">
                <div className="px-4 py-3"><button type="submit" className="bg-yellow-400 shadow hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96" >Send Notification</button> </div>
                </div>
            </form>
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
        </div>
    );
}
 
export default SendUserNotificaion;