
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import PageHeading from '../../components/headings/pageHeading';
import InputModal from '../../components/modal/inputModal';
import AlertWithClose from '../../components/alert/alertWithClose';

    


const CreateClubs = () => {

    const [formData, setForm] = useState({
        name:"" , image:"" , description:"" , isPublic:"",
        quizPage:true, queriePage:true, resourcePage: true, opportunityPage: true, audioRoomsPage: true
    });
    const [clubs, setClubs] =  useState([]);
    const [blockData, setBlockData] =  useState(null);
    const [alertData, setAlertData] = useState(null);
    const [blockReason, setBlockReason] = useState('');

    const onResonChange = (e)=>{
        setBlockReason(e.target.value);
    }

    // const auth = useContext(AuthContext)
    // console.log(auth)

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setForm(prev => ({
                ...prev, 
            imageUrl: URL.createObjectURL(event.target.files[0])
          }));
        }
       }

    const formChanges = (e) => {
        const {name, value, files} = e.target;

        setForm(prev => ({
            ...prev,
            [name]: value
        }))
        if(name==='image'){
            setForm(prev => ({
                ...prev,
                [name]: files[0]
            }))
            onImageChange(e)
        }
        console.log(e, name, value)
    }

    
    const clearForm = () =>{
        document.getElementById('ClubsCreateForm').reset();
        setForm({
            name:"" , image:"" , description:"" , isPublic:"",
        quizPage:true, queriePage:true, resourcePage: true, opportunityPage: true, audioRoomsPage: true
        });
    }

    const checkboxHandler= (name)=>{
        setForm(prev=>({
            ...prev,
            [name]: !prev[name]? true: null
        }))
   }


    const loadClubs = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/clubs`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setClubs(response.data.clubs )
            console.log(response);
            console.log(clubs)


        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(async()=>{
        await loadClubs()
    },[])


    const submitHandler = async (e)=>{
        // onClick={submitHandler}

        try{
        e.preventDefault();
        console.log(formData, e)

        const url = `${process.env.REACT_APP_BASE_URL}/clubs`;
        const reqFormData = new FormData();

        console.log(formData.name , formData.emoji , formData.description , formData.isPublic)

        

         if(!formData.name)
            {
                setAlertData({message:'Name is required', type:'bad'});
                return;

            }
            // if(!formData.emoji) 
            // {
            //     setAlertData({message:'Emoji is required', type:'bad'});
            //     return;

            // }
            if(!formData.description)
            {
                setAlertData({message:'Description is required', type:'bad'});
                return;

            }
            if (!formData.isPublic)
            {
                setAlertData({message:'Select type of visiblity', type:'bad'});
                return;

            }

        
        if(!formData.imageUrl && !formData.emoji) 
            {
                console.log(formData.imageUrl   ,"one", formData.emoji)
                setAlertData({message: 'Emoji or Image required', type:'bad'})
                return;
            }


        reqFormData.append('name', formData.name);
        reqFormData.append('quizPage', formData.quizPage);
        reqFormData.append('queriePage', formData.queriePage);
        reqFormData.append('resourcePage', formData.resourcePage);
        reqFormData.append('opportunityPage', formData.opportunityPage);
        reqFormData.append('audioRoomsPage', formData.audioRoomsPage);
        if(formData.emoji)
        reqFormData.append('emoji', formData.emoji);
        reqFormData.append('description', formData.description);
        reqFormData.append('isPublic', formData.isPublic === 'public');
        if(formData.imageUrl)
        reqFormData.append('upload',formData.image );

        const token = localStorage.getItem('authKey');

        console.log(reqFormData)

        const response = await axios.post(`${url}/`,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
        
    } )

    if(response.status===200){
        setAlertData({message: 'Clubs Added', type:'good'})
        clearForm()
        loadClubs()

    }
    
        }catch(error){

            
            if(error.name ==='AxiosError'){
                if(error.response.data)
                setAlertData({message: error.response.data.error, type:'bad'})
                else setAlertData({message:'Unable to insert clubs', type:'bad'})
            }
            else
            setAlertData({message:'Unable to insert clubs', type:'bad'})
        }

    }

    const clearAlert= ()=>{
        setAlertData(null);
    }

    /* eslint-disable no-underscore-dangle, no-unused-vars */
    const loadBlockData = (index)=>{
        setBlockData(clubs[index]);
    }

    const clearBlockData =  ()=>{
        setBlockData(null)
        setBlockReason("")
    }

    const blockClubs =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/clubs/block/${blockData._id}`;
    
            const token = localStorage.getItem('authKey');

            console.log(blockReason,blockReason === null , blockReason ==='', 'Block reason')
            if(blockReason === null || blockReason ==='') { 
                setAlertData({message: 'Reason is required', type:'bad'}); 
                console.log(blockReason, 'Block reason inside')
                return;}
    
            const response = await axios.post(url, {reason: blockReason},{headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setAlertData({message: 'Club blocked', type:'good'});

            loadClubs()
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to block club', type:'bad'})
                }
                else
                setAlertData({message:'Unable to block club', type:'bad'})
            }
        
            clearBlockData();

    }

    return ( 
        <div className="create-clubs w-full">
            <PageHeading heading='Clubs' />
            <form onSubmit={submitHandler} id="ClubsCreateForm" className='mb-6 '>
            <div className="flex flex-row">

            <div className="flex flex-col">
                    <label htmlFor="name" className='px-4 py-2  text-lg'>Club Name</label>
                    <div className="px-4 py-3"><input type="text" name='name' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300" placeholder="Enter Club name" onChange={formChanges} /></div>
            </div>

                    <div className="flex flex-col">
                    <label htmlFor="emoji" className='px-4 py-2  text-lg'>Club Emoji</label>
                    <div className="px-4 py-3"><input type="text" name='emoji' className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300 disabled:cursor-not-allowed	" placeholder="Enter Club emoji" onChange={formChanges} disabled={formData.image} /></div>
                    </div> 

                    
            </div>

            <div className="flex">

            <div className="flex flex-col w-96 px-4 mr-8">
                <label htmlFor="tags" className='px-4 py-2  text-lg'> Allowed Pages </label>
                <div className="flex px-4 py-2">
                <div className='px-2'>
                <input type="checkbox" className='accent-violet-400 text-white' id="audioRoomsPage" name="audioRoomsPage" checked={formData.audioRoomsPage} value="audioRoomsPage" onChange={()=>{checkboxHandler("audioRoomsPage")}} />
                <label htmlFor="audioRoomsPage"> Audio Rooms</label>
                </div>

                
                <div className='px-2'>
                <input type="checkbox" className='accent-violet-400 text-white' id="quizPage" name="quizPage" checked={formData.quizPage} value="quizPage"  onChange={()=>{checkboxHandler("quizPage")}}/>
                <label htmlFor="quizPage"> Quiz</label>
                </div>
                
                
                <div className='px-2'>
                <input type="checkbox" className='accent-violet-400 text-white' id="queriePage" name="queriePage" checked={formData.queriePage} value="queriePage" onChange={()=>{checkboxHandler("queriePage")}}/>
                <label htmlFor="queriePage"> Query</label>
                </div>

                <div className='px-2'>
                <input type="checkbox" className='accent-violet-400 text-white' id="resourcePage" name="resourcePage" checked={formData.resourcePage} value="resourcePage" onChange={()=>{checkboxHandler("resourcePage")}}/>
                <label htmlFor="resourcePage"> Resource</label>
                </div>

                <div className='px-2'>
                <input type="checkbox" className='accent-violet-400 text-white' id="opportunityPage" name="opportunityPage" checked={formData.opportunityPage} value="opportunityPage" onChange={()=>{checkboxHandler("opportunityPage")}}/>
                <label htmlFor="opportunityPage"> Opportunity</label>
                </div>

                </div>
            </div>

            <div className="flex flex-col">
                    <label htmlFor="image" className='px-4 py-2  text-lg'> Club Image</label>
                    <div className="px-4 py-3"><input type="file" name='image' className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none disabled:cursor-not-allowed	" placeholder="Enter Clubs name" accept="image/*" onChange={formChanges}  disabled={formData.emoji}/></div>
                    </div> 

            {formData && formData.imageUrl && <img className="mt-2 h-24 px-5" src={formData.imageUrl} alt='Clubs name' />}
                    

            </div>
            <div className="flex">

              <div className="flex flex-col">
                    <label htmlFor="description" className='px-4 py-2  text-lg'> Description</label>  
            <div className="px-4 "><textarea name='description' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3 " placeholder="Enter the description of club" onChange={formChanges} /></div>
            </div>


                    <div className="flex flex-col mt-auto mb-0">
                    <label htmlFor="isPublic" className='px-4 py-2  text-lg'>Club Type</label>
                    <div className="px-4 ">
                        <select name='isPublic' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={formChanges}>
                        <option  disabled selected >Select type </option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        </select>
                        </div>
                    </div>
                    <div className="px-4  mb-0 mt-auto"><button type="submit" className="bg-violet-400 font-medium text-white shadow-md hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96" >Add Clubs</button> </div>
            </div>
                
            </form>


            
            <div className="grid grid-cols-4 gap-2 border-t-2 pt-6 mx-4 border-violet-400 ">
            {clubs && clubs.map((club, index) =>
                                <div className="">
                                <div className={`my-2 py-2 px-3 shadow bg-white border-b rounded `}>
                                    <div className="font-bold text-gray-900 text-md flex  border-b-2 mb-1 pb-1" >
                                    
                                        {club.image_url && <span><img className="h-10 rounded-full mr-2" src={club.image_url} alt="Avt" /></span>}

                                      <div className="flex flex-col">
                                        
                                        <div className="div">
                                        <div>{club.name} {club.emoji} {!club.is_public && <span className='font-bold mx-2 text-red-600'>Pr</span>}</div> 
                                        
                                        </div>


                                      <div className='text-sm  font-medium '>{club._id}</div>
                                        </div>  
                                    
                                        {!club.is_public && <div className='text-sm h-fit text-white ml-auto mr-0 bg-violet-400 shadow rounded p-1'> <Link to={`/club/add/user/${club._id}`} className=''>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" /></svg>

                                            </Link> </div>} 
                                        
                                    </div>
                                    <div className="truncate w-5/6 text-xs">
                                        {club.description}
                                    </div>
                                    
                                    
                                    <div className="flex">
                                    {/* <div className="w-3/4">
                                        {club.created_at? new Date(club.created_at).toLocaleString(): 'N/A'}
                                    </div> */}
                                    {/* <div className='w-1/4'><div className="text-right">
                                        {`${club.isPublic? 'Public': 'Private'}`}
                                    </div></div> */}
                                    </div>
                                    {/* <div className=" h-10 ">
                                        {club.image_url? <img src={club.image_url} alt={club.name} className='h-10'/> : <span>No Image</span>}
                                    </div> */}
                                    
                                    <div className="h-28">
                                    <div className="grid grid-cols-2  gap-1 text-white">
                                        {club.pages && club.pages.Quiz && <Link to={`/club/quiz/${club._id}`} className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Quiz</Link>}
                                        {club.pages && club.pages.Opportunities && <Link to={`/club/opp/${club._id}`} className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Opportunity</Link>}
                                        {club.pages && club.pages.Resources && <Link to={`/club/resource/${club._id}`} className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Resource</Link>}
                                        
                                        {club.pages && club.pages.Queries && <Link to={`/club/query/${club._id}`} className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Query</Link>}
                                        <Link to={`/cometchat/channel/${club._id}`}><div className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Chat</div></Link>
                                        {/* eslint- */}
                                        {club.pages && club.pages['Audio Rooms'] && <Link to={`/club/rooms/${club._id}`}><div className=" bg-slate-400 hover:bg-violet-400 shadow-sm hover:shadow h-8 focus:shadow-none shadow-black-400/20  px-3 text-center mr-2 px-2 py-1 rounded  text-white">Rooms</div></Link>}
                                    {/* <button type="submit" className=" bg-red-600 shadow-sm hover:shadow focus:shadow-none shadow-black-400/20 px-3 px-2 py-1 rounded  text-white inline"
                                     onClick={()=>loadBlockData(index)} 
                                     >Block</button> */}
                                     </div>
                                    </div>

                                </div>
                                </div>
                            )                    
                    }
            </div>
            

                    {blockData && <InputModal description={`Do you want to block ${blockData.name} ?`} onSuccess={blockClubs} onCancel={clearBlockData} onReason={onResonChange} /> }
                    { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
        </div>
     );
}
 
export default CreateClubs;