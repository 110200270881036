import React, { useEffect } from 'react';

const AlertWithClose = ({message, type, time, clearAlert}) => {

    useEffect(()=>{
        setTimeout(clearAlert, time*1000)
    })

    const color = type==='good'? 'violet-500' : 'rose-500';

    return ( 
        <div className={`fixed py-3 px-5 bg-${color} text-white shadow rounded top-20 right-5 z-20`} tabIndex='-1'>
                <div className="flex">
                    <button type='button'className='text-center pr-3 border-r-2 border-black' onClick={clearAlert}>x</button>
                    <span className='px-2'>{message}</span>
                </div>
            </div>
     );
}
 
export default AlertWithClose;