import React from 'react';
import './App.css';
import MainROutes from './routes/mainRoutes';
import  AuthContextComponent from './context';

const App = () =>(
    <AuthContextComponent>
    <MainROutes />
    </AuthContextComponent>
  )


export default App;
