import React, { createContext, useMemo, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut  as firebaseSignOut} from "firebase/auth";

export const AuthContext = createContext();

const AuthContexComponenet = ({children}) => {
    
  const [authUser, setAuthUser] = useState(); 
  const [authLoding, setAuthLOading] =  useState(false);
  

  const firebaseConfig = {
    apiKey: "AIzaSyCBIzX1bzjRoaQNJIybLrbXNqMY4y7evQk",
    authDomain: "mentorboxx-intern.firebaseapp.com",
    projectId: "mentorboxx-intern",
    storageBucket: "mentorboxx-intern.appspot.com",
    messagingSenderId: "382492421412",
    appId: "1:382492421412:web:d01697558a16c344cb97b1",
    measurementId: "G-EQ2SBD3YKK"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


  const auth = getAuth(app);

  console.log(auth);


  const signIn = (email, password)=>{

    setAuthLOading(true)
    signInWithEmailAndPassword(auth, email, password).then((user)=>{
      console.log("signIn", user)
      setAuthUser(user)
      setAuthLOading(false)
    }).catch(err=>{
      console.log(err)
      setAuthLOading(false)
    })
  }

  const signOut = ()=>{
    firebaseSignOut(auth).then(()=>{
      setAuthUser(null)
      console.log(authLoding)
    }).catch(err=>{console.log(err)})
  }

//   useEffect(()=>{
//     signIn('hariprasath2603@gmail.com', 'Hari@1234')
//   }, [])
  
  const authMemo = useMemo(()=>({
    authUser, 
    authLoding,
    signIn,
    signOut
  }),[authUser, authLoding])
  

    return ( 
        <AuthContext.Provider value={authMemo}>
            {children}
        </AuthContext.Provider>
     );
}
 
export default AuthContexComponenet;