import React , {useState, 
    useEffect
} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import fileDownload from 'js-file-download';
import AlertWithClose from '../../components/alert/alertWithClose';
import Modal from '../../components/modal/modal';
import PageHeading from '../../components/headings/pageHeading';

/* eslint-disable  no-nested-ternary */
const CreateOpportunity = () => {
    
    const [opprData, setOpprData] = useState({})
    const [notificationData, setNotificationData] = useState(null)
    const { clubId } = useParams();
    const [opprList, setOpprList] =  useState(null);
    const [deleteData, setDeleteData] =  useState(null);
    const [isLoading, setIsLoading] =  useState(false);
    

    const onImageChange = (event, name) => {
        if (event.target.files && event.target.files[0]) {
            setOpprData(prev => ({
                ...prev, 
            [`${name}Url`]: URL.createObjectURL(event.target.files[0])
          }));
        }
       }

       const onFormDataChange=(e)=>{
        const {name, value, type, files} = e.target;

        if(type==='file'){
            setOpprData(prev=>({
                ...prev,
                [name]:files[0]
            }))    
            onImageChange(e, name);
        }else
        setOpprData(prev=>({
            ...prev,
            [name]:value
        }))

        console.log(opprData,e);
    }

    const clearAlert= ()=>{
        setNotificationData(null);
    }
    
    const loadOppr = async()=>{
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/clubs/admin/opportunities/${clubId}`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } });

            setOpprList(response.data )
            console.log(opprList);


        }
        catch (e) {
            console.log(e);
        }
    }

    const submitHandler = async (e)=>{
        // onClick={submitHandler}
        e.preventDefault();
        console.log(opprData, e)
        setIsLoading(true);
        try{
        /* eslint-disable camelcase */
        const reqFormData = new FormData();
        
        const {
            name,
            type,
            link,
            application_deadline,
            start_date,
            end_date,
            description,
            eligibility,
            benifits,
            image,
            reward_type,
            reward_value
        } = opprData;

        if(!name) {
        setNotificationData({message:"Name is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!type) {
        setNotificationData({message:"Type is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!application_deadline) {
        setNotificationData({message:"Application deadline is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!start_date) {
        setNotificationData({message:"Start Date is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!end_date) {
        setNotificationData({message:"End Date is required", type:'bad'}); setIsLoading(false); return;
        }
        
        if(start_date > end_date) {
        setNotificationData({message:"Start date cannot be greater then end date", type:'bad'}); setIsLoading(false); return;
        }

        if(!description) {
        setNotificationData({message:"Description is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!eligibility) {
        setNotificationData({message:"Eligibility is required", type:'bad'}); setIsLoading(false); return;
        }
        if(reward_type!=='none' && !reward_value) {
        setNotificationData({message:"Reward Value is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!image) {
        setNotificationData({message:"image is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!reward_type) {
        setNotificationData({message:"Reward Type is required", type:'bad'}); setIsLoading(false); return;
        }
        
        let finalData ={}
        if(link){
            finalData ={
                "opportunityName":name,
                "type":type,
                "start_date":start_date,
                "end_date":end_date,
                "reward_type":reward_type,
                "reward_value":reward_value,
                "application_deadline":application_deadline,
                "description":description,
                "eligibility":eligibility,
                "link":link,
                "benifits":benifits,
                "clubId":clubId,
                'upload': image
                }
        }else{
            finalData ={
                "opportunityName":name,
                "type":type,
                "start_date":start_date,
                "end_date":end_date,
                "reward_type":reward_type,
                "reward_value":reward_value,
                "application_deadline":application_deadline,
                "description":description,
                "eligibility":eligibility,
                "benifits":benifits,
                "clubId":clubId,
                'upload': image
                }
        }
        

        Object.keys(finalData).map(key=>reqFormData.append(key, finalData[key]));

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)
        const url = `${process.env.REACT_APP_BASE_URL}/clubs/opportunity`;
        const response = await axios.post(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Opportunity created', type:'good'})
        loadOppr()
        setIsLoading(false)
    }
        }catch(error){

            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    useEffect(async()=>{
        await loadOppr()
    },[])

    /* eslint-disable no-underscore-dangle */
    const loadDeleteData = (index)=>{
        setDeleteData(opprList.opportunities[index]);
        console.log(index);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
    }

    // const downloadApplications = async(index)=>{
    //     try{
    //         const url = `${process.env.REACT_APP_BASE_URL}/clubs/downloadApplications/${opprList.opportunities[index]._id}`;
    
    //         const token = localStorage.getItem('authKey');
        
    //         const response = await axios.get(url,{responseType: "blob",headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     } )
    //     const headerLine = response.headers['Content-Disposition'];
    //     const startFileNameIndex = headerLine.indexOf('"') + 1
    //     const endFileNameIndex = headerLine.lastIndexOf('"');
    //     const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    //     fileDownload(response.data, filename);
       
    //     if(response.status===200){
    //         setNotificationData({message: 'Applications downloaded', type:'good'});

    //     }
    //         }catch(error){
    //             if(error.name ==='AxiosError'){
                    
    //                 setNotificationData({message: error.response.data.error, type:'bad'})
    //             }
    //             else
    //             setNotificationData({message:'Unable to delete brand', type:'bad'})
    //         }
    // }

    const deleteOppr =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/clubs/opportunity/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');
    
            const response = await axios.delete(url, {headers: {
                Authorization: `Bearer ${token}`
            }
        } )
    
        if(response.status===200){
            setNotificationData({message: 'Brand deleted', type:'good'});

            loadOppr()
        }
            }catch(error){
                if(error.name ==='AxiosError'){
                    
                    setNotificationData({message: error.response.data.error, type:'bad'})
                }
                else
                setNotificationData({message:'Unable to delete brand', type:'bad'})
            }
        
            clearDeleteData();

    }

    const loadDataToForm = (index)=>{
     
        //  "opportunityName":name,
        // 'upload': image

        const data = opprList.opportunities[index]
        console.log("data", data, opprList)
            setOpprData({
                ...data,
                name: data.opportunityName,
                imageUrl: data.file,
                type: data.opp_type,
                end_date: data.end_date_new??data.end_date
            })

    }

    const updateHandler = async (e)=>{
        // onClick={submitHandler}
        e.preventDefault();
        console.log(opprData, e)
        setIsLoading(true);
        try{
        /* eslint-disable camelcase */
        const reqFormData = new FormData();
        
        const {
            name,
            type,
            link,
            application_deadline,
            start_date,
            end_date,
            description,
            eligibility,
            benifits,
            image,
            reward_type,
            reward_value,
            // isUpdate,
            _id,
            // index
        } = opprData;

        if(!name) {
        setNotificationData({message:"Name is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!type) {
        setNotificationData({message:"Type is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!application_deadline) {
        setNotificationData({message:"Application deadline is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!start_date) {
        setNotificationData({message:"Start Date is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!end_date) {
        setNotificationData({message:"End Date is required", type:'bad'}); setIsLoading(false); return;
        }
        
        if(start_date > end_date) {
        setNotificationData({message:"Start date cannot be greater then end date", type:'bad'}); setIsLoading(false); return;
        }

        if(!description) {
        setNotificationData({message:"Description is required", type:'bad'}); setIsLoading(false); return;
        }
        if(!eligibility) {
        setNotificationData({message:"Eligibility is required", type:'bad'}); setIsLoading(false); return;
        }
        if(reward_type!=='none' && !reward_value) {
        setNotificationData({message:"Reward Value is required", type:'bad'}); setIsLoading(false); return;
        }
        
        if(!reward_type) {
        setNotificationData({message:"Reward Type is required", type:'bad'}); setIsLoading(false); return;
        }
        
        // const origialData = opprList.opportunities[index];
        const finalData ={
            "opportunityName":name,
            "type":type,
            "start_date":start_date,
            "end_date":end_date,
            "reward_type":reward_type,
            "reward_value":reward_value,
            "application_deadline":application_deadline,
            "description":description,
            "eligibility":eligibility,
            "benifits":benifits,
            "clubId":clubId,
            'upload': image
            }
        if(link) finalData.link= link;


        Object.keys(finalData).map(key=>reqFormData.append(key, finalData[key]));

        console.log(reqFormData);
        const token = localStorage.getItem('authKey');

        console.log(reqFormData)
        const url = `${process.env.REACT_APP_BASE_URL}/clubs/opportunity/admin/${_id}`;
        const response = await axios.put(url,reqFormData, {headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    } )

    console.log(response);

    if(response.status===200){

        setNotificationData({message: 'Opportunity created', type:'good'})
        loadOppr()
        setIsLoading(false)
    }
        }catch(error){

            if(error.name ==='AxiosError'){
                
                setNotificationData({message: error.response.data.error, type:'bad'})
            }
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }


    return ( 
        <div className="coupons w-full relative">
            <PageHeading heading='Club Opportunity' />
            <div>

            {/* Row 1 */}
            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Opportunity Name </label>
            <div className="px-4 "><input type="text" name='name' value={opprData.name} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter opportunity name"  onChange={onFormDataChange}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="type" className='px-4 py-2  text-lg'> Type </label>
            {/* <div className="px-4 ">
                <select name='type' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select type</option>
                <option value="intern">Internship</option>
                <option value="fulltime">Full Time</option>
                <option value="hackathon">Hackathon</option>
                </select>
                </div> */}
                <div className="px-4 "><input type="text" name='type' value={opprData.type} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 disabled:bg-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter Opportunity Type"  onChange={onFormDataChange} disabled={opprData.reward_type==='none'}/></div>
            </div>


            <div className="flex flex-col">
            <label htmlFor="link" className='px-4 py-2  text-lg'> Application / Hacakathon Link </label>
            <div className="px-4 "><input type="text" name='link' value={opprData.link} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter Link"  onChange={onFormDataChange}/></div>
            </div>

            {/* <div className="flex flex-col">
            <label htmlFor="cost" className='px-4 py-2  text-lg'> Cost </label>
            <div className="px-4 "><input type='number' name='cost' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="No of EB coins"  onChange={onFormDataChange}/></div>
            </div> */}

            </div>
            {/* Row 2 */}
            <div className="flex">
                
            <div className="flex flex-col">
            <label htmlFor="reward_type" className='px-4 py-2  text-lg'>Reward / stipend Type </label>
            <div className="px-4 ">
                <select name='reward_type' value={opprData.reward_type} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select type </option>
                <option value="money">Money</option>
                <option value="others">Others</option>
                <option value="none">None</option>
                </select>
                </div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="reward_value" className='px-4 py-2  text-lg'> Reward </label>
            <div className="px-4 "><input type="text" name='reward_value' value={opprData.reward_value} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 disabled:bg-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="2000/Month"  onChange={onFormDataChange} disabled={opprData.reward_type==='none'}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="application_deadline" className='px-4 py-2  text-lg'>Application / Registaration Deadline </label>
            <div className="px-4 "><input type='datetime-local' name='application_deadline'value={opprData.application_deadline} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"  onChange={onFormDataChange}/></div>
            </div>

            </div>
            {/* Row 3 */}
            <div className="flex">
            
            <div className="flex flex-col">
            <label htmlFor="start_date" className='px-4 py-2  text-lg'> Start Date </label>
            <div className="px-4 "><input type='datetime-local' name='start_date' value={opprData.start_date} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"   onChange={onFormDataChange}/></div>
            </div>

            <div className="flex flex-col">
            <label htmlFor="end_date" className='px-4 py-2  text-lg'> End Date </label>
            <div className="px-4 "><input type='datetime-local' name='end_date' value={opprData.end_date} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3"   onChange={onFormDataChange}/></div>
            </div>

            {/* <div className="flex flex-col">
            <label htmlFor="name" className='px-4 py-2  text-lg'> Cost </label>
            <div className="px-4 "><input type="text" name='Brand Id' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="No of EB coins"  /></div>
            </div> */}

            </div>

            {/* row 4 */}
            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="description" className='px-4 py-2  text-lg'> Description </label>
            <div className="px-4 "><textarea name='description'  value={opprData.description}className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter the description" onChange={onFormDataChange} /></div>
            </div>

            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="eligibility" className='px-4 py-2  text-lg'> Eligiblity </label>
            <div className="px-4 "><textarea name='eligibility' value={opprData.eligibility}className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter the eligibility" onChange={onFormDataChange} /></div>
            </div>

            <div className="flex">
            <div className="flex flex-col">
            <label htmlFor="benifits" className='px-4 py-2  text-lg'> Benifits </label>
            <div className="px-4 "><textarea name='benifits' value={opprData.benifits} className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" placeholder="Enter the benifits " onChange={onFormDataChange} /></div>
            </div>

            </div>

            </div>
            </div>

            {/* Row 5 */}
            <div className="flex">
            <div className="px-4 py-3"><input type="file" name='image' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none px-4 py-3" accept="image/*" onChange={onFormDataChange} /></div>
            {opprData && opprData.imageUrl && <div className="px-10 w-full py-3 w-96"><img className="h-24" src={opprData.imageUrl} alt='Brand name' /></div>}
            </div>

            <div className="flex">
            <div className="px-4 py-3 grid"><button type="submit" className="bg-yellow-400 shadow-lg disabled:shadow-none hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-96 border-4 border-yellow-500 mr-0 ml-auto" onClick={opprData._id?updateHandler: submitHandler } disabled={isLoading}>
            {isLoading?
                (<svg role="status" className="w-6 h-6 mx-auto text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>)
                : (opprData._id?"Update opportunity":"Create Opportunity")}
            </button> </div>
            </div>

            
            
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}
            </div>

            {opprList &&  opprList.opportunities &&  <div className=" ml-4 overflow-x-scroll w-5/6"> 
            <table className="table-fixed text-sm text-left text-slate-500  overflow-x-scroll	">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                            <tr>
                            <th scope="col" className="px-6 py-3">
                                    Edit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Start Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    End Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Deadline
                                </th>
                                <th scope="col" className="px-6 py-3 w-2/12">
                                    Description
                                </th>
                                <th scope="col" className="px-6 py-3 w-2/12">
                                    Eligibility
                                </th>
                                <th scope="col" className="px-6 py-3 w-2/12">
                                    Benifits
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Rewards
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    Download
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>


                            {opprList.opportunities.map((oppIndi, index) =>
                                <tr className={`bg-white border-b hover:bg-slate-200 ${index%2===0?'': 'bg-slate-100'}`}>
                                    <th scope="row" className="px-6 py-4 truncate" title={oppIndi._id}>
                                    <button type="submit" className="bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-1 p-2 rounded  text-white" onClick={()=>loadDataToForm(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>

                                    </button>
                                    </th>
                                    <th scope="row" className="px-6 py-4  ">
                                        {oppIndi.opportunityName}
                                    </th>
                                    <td className="px-6 py-4">
                                        {oppIndi.opp_type}
                                    </td>
                                    <td className="px-6 py-4 h-10 ">
                                        {oppIndi.file?
                                        
                                        <img src={oppIndi.file} alt={oppIndi.opportunityName} className='h-10'/> 
                                        
                                        : <span>No Image</span>}
                                    </td>
                                    <td className="px-6 py-4">
                                        {new Date(oppIndi.created_at).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4">
                                        {new Date(oppIndi.start_date).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4">
                                    {new Date(oppIndi.end_date_new).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4">
                                    {new Date(oppIndi.application_deadline).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 w-2/12">
                                        <div className="truncate text-xs " title={oppIndi.description}  onDoubleClick={()=>navigator.clipboard.writeText(oppIndi.description)}>
                                        {`${oppIndi.description.toString().slice(0,20)}...`}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 w-2/12">
                                    <div className="truncate text-xs " title={oppIndi.eligibility} onDoubleClick={()=>navigator.clipboard.writeText(oppIndi.eligibility)}>
                                        {`${oppIndi.eligibility.toString().slice(0,20)}...`}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 w-2/12">
                                    <div className="truncate text-xs " title={oppIndi.benifits} onDoubleClick={()=>navigator.clipboard.writeText(oppIndi.benifits)}>
                                        {oppIndi.benifits ? `${oppIndi.benifits.toString().slice(0,20)}...` : <span className='text-xs'>Not available</span>}
                                        </div>
                                    </td>

                                    <td className="px-6 py-4">
                                    <div className="text-xs">
                                        {oppIndi.reward_type}
                                        </div>
                                        {oppIndi.reward_value}
                                    </td>
                                    
                                    
                                
                                    <td className="px-6 py-4 h-10 ">
                                    <button type="submit" className="bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-3 p-2 rounded w-24 text-white" onClick={()=>loadDeleteData(index)}>Delete</button>
                                    </td>

                                    {/* <th scope="row" className="px-6 py-4 truncate" title={oppIndi._id}>
                                    <button type="submit" className="bg-green-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 px-1 p-2 rounded  text-white" onClick={()=>downloadApplications(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>
                                    </button>
                                    </th> */}
                                </tr>
                            )}

                        </tbody>
                    </table> </div>}

                    {deleteData && <Modal description={`Do you want to delete ${deleteData.opportunityName} ?`} onSuccess={deleteOppr} onCancel={clearDeleteData} /> }
                    
        

            </div> 
    );
}
 
export default CreateOpportunity;