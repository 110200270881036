import React, { useEffect, useState } from 'react';
// import env from 'react-dotenv';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import PageHeading from '../../components/headings/pageHeading';
import PostModal from '../../components/modal/postModal';
// import Modal from '../../components/modal/modal';
import InputModal from '../../components/modal/inputModal';
import AlertWithClose from '../../components/alert/alertWithClose';
import { sendNotificationToUser } from '../../service/service';


const PostList = () => {

    const [postData,setPostList] =  useState({post:[]});
    const [zoomView, setZoomView] = useState(null);
    const navigate = useNavigate();
    const [deleteData, setDeleteData] =  useState(null);
    const [deleteReason, setDeleteReason] =  useState(null);
    const [alertData, setAlertData] = useState(null);

    useEffect(()=>{
        const authkey = localStorage.getItem('authKey');

        if(!authkey) navigate("/login");
    }, [])

    const onResonChange = (e)=>{
        setDeleteReason(e.target.value);
    }

    const clearAlert= ()=>{
        setAlertData(null);
    }

    const loadPost = async()=>{
        
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/posts`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
           setPostList(prev=>{
            
            const tempost = [...prev.post, ...response.data.posts]
             return {post: tempost, hasNext: response.data.next}
            
           })
        console.log(response);

        }
        catch(e){
            console.log(e);
        }
    }

    const loadPostOnClick = async()=>{
        
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/posts`;
            const token = localStorage.getItem('authKey');
            const response = await axios.get(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, params:{skip:postData.post.length}});
            
           setPostList(prev=>{
            
            const tempost = [...prev.post, ...response.data.posts]
             return {post: tempost, hasNext: response.data.next}
            
           })
        console.log(response);

        }
        catch(e){
            console.log(e);
        }
    }

    useEffect(async()=>{
        loadPost();
     },[])

     const clearZoomView = ()=>{
        setZoomView(null);
    }    
    /* eslint no-underscore-dangle: 0,jsx-a11y/no-noninteractive-element-interactions:0, jsx-a11y/click-events-have-key-events:0 */

    const loadDeleteData = (index)=>{
        setDeleteData(postData.post[index]);
    }

    const clearDeleteData =  ()=>{
        setDeleteData(null)
        setDeleteReason("")
    }

    const deletePost =async ()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/wall/admin/post/${deleteData.userInfo._id}/${deleteData._id}`;
    
            const token = localStorage.getItem('authKey');

            console.log(deleteReason,deleteReason === null , deleteReason ==='', 'Delete reason')
            if(deleteReason === null || deleteReason ==='') { 
                setAlertData({message: 'Reason is required', type:'bad'}); 
                console.log(deleteReason, 'Delete reason inside')
                return;}
    
            const response = await axios.delete(url,{headers: {
                Authorization: `Bearer ${token}`
            }
        } )

        
        const notificationResponse = await sendNotificationToUser(deleteData.userInfo._id, `Your post has been removed by EB Community`, `"${deleteData.title.slice(0,10)}${deleteData.title.length>10?"...":""}" post deleted, ${deleteReason}`, true);
        console.log(deleteData.userInfo._id, `Your post has been removed by EB Community`, `"${deleteData.title.slice(0,10)}${deleteData.title.length>10?"...":""}" post deleted, ${deleteReason}`)

        if(notificationResponse){
            setAlertData({message: "Notification sent to user", type:'good'})
        }
        if(response.status===200){
            setAlertData({message: 'Post deleted', type:'good'});

            
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setAlertData({message: error.response.data.error, type:'bad'})
                    else setAlertData({message:'Unable to delete user', type:'bad'})
                }
                else
                setAlertData({message:'Unable to delete user', type:'bad'})
            }
        
            clearDeleteData();

    }

    
    return ( 
        <div className="postList">
            
            <PageHeading heading="Post" />
            <div className="flex flex-wrap items-stretch self-stretch">
                    {postData.post && postData.post.map((data, index)=>
                    <div className="md:w-1/2 lg:w-1/4 self-stretch items-stretch h-100">
                        <div  className=" px-4 bg-yellow-400 shadow rounded py-3 mx-2 my-2 items-stretch" key={data._id}>
                            <div className="flex border-b-1 border-black" style={{borderBottomWidth :'1px'}}>
                            <img src={data.userInfo&& data.userInfo.personalInfo && data.userInfo.personalInfo.avatar} alt="Avatar" className="h-8 mr-2 rounded-full my-auto" />
                                <div className="flex flex-col w-11/12">
                                    <div className="font-bold">{data.userInfo&& data.userInfo.personalInfo && data.userInfo.personalInfo.name}</div>
                                    <div className="text-xs truncate w-11/12" title={data.userInfo.collegeInfo&& data.userInfo.collegeInfo.collegeName && data.userInfo.collegeInfo.collegeName}>{data.userInfo.collegeInfo&& data.userInfo.collegeInfo.collegeName && data.userInfo.collegeInfo.collegeName}</div>
                                </div>
                            </div>
                            <div className="flex text-xs">
                                <div className='w-1/2 truncate text-left'title={data._id} >{data._id}</div>
                                <div className='ml-1 text-right w-1/2 truncate'title={new Date(data.timeStamp).toLocaleString()}>{new Date(data.timeStamp).toLocaleString()}</div>
                            </div>
                           <div className="font-semibold">{data.title}</div>
                           {data.format==="Image" && <img className="max-h-52" src={data.file} alt={index} onClick={()=>setZoomView(data)}/>}
                           {data.format==="Video" && <div className="flex flex-col">
                               <img className="max-h-52" src={data.thumbnail} alt={index} onClick={()=>setZoomView(data)}/>
                               <button type='button' className='bg-black px-1 py-1 rounded shadow text-white' onClick={()=>setZoomView(data)}>Play video</button>
                           </div>
                           }
                           
                           {/* eslint-disable jsx-a11y/media-has-caption */}
                           {data.format==='Audio' && <audio controls><source src={data.file} /></audio>}
                           
                           <div className="flex text-white mt-2">
                               <div className='px-1 text-sm  py-1 mr-1 bg-green-600 rounded-lg shadow'>{`${data.Likes} 👍`}</div>
                               <div className='px-1 text-sm  py-1 mr-1 bg-teal-600 rounded-lg shadow'>{`${data.Shares} 🔁`}</div>
                               <div className='px-1 text-sm  py-1 mr-1 bg-emerald-600 rounded-lg shadow'>{`${data.Comments} ✉`}</div>
                            
                               <button className="ml-auto mr-0 bg-black rounded shadow px-1" type="button" onClick={()=>loadDeleteData(index)}>Del</button>
                           </div>
                        </div>
                        </div>                  
                    ) }
            </div>
           {postData.hasNext &&<div className="w-full"><button type='button' className='bg-black px-8 py-4 rounded shadow text-white mt-3 mb-5 mx-auto text-center grid' onClick={loadPostOnClick}>LoadMore</button></div> }   
            {zoomView && <PostModal description={zoomView.title} url={zoomView.file} type={zoomView.format} onCancel={clearZoomView} /> }
            { alertData && <AlertWithClose clearAlert={clearAlert} message={alertData.message} time={4} type={alertData.type} />}
            {deleteData && <InputModal description={`Do you want to delete ${deleteData.title.slice(0,30)} ?`} onSuccess={deletePost} onCancel={clearDeleteData} onReason={onResonChange} /> }
         </div>
     );
}
 
export default PostList;