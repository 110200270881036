import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import PageHeading from "../../components/headings/pageHeading";
import Modal from "../../components/modal/modal";
import AlertWithClose from "../../components/alert/alertWithClose";

const CreateBrand = () => {
  const [formData, setForm] = useState({ name: "" });
  const [brands, setBrands] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [alertData, setAlertData] = useState(null);

  const imageSelectRef = useRef();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        imageUrl: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };

  const formChanges = (e) => {
    const { name, value, files } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "image") {
      setForm((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      onImageChange(e);
    }
    console.log(e, name, value);
  };

  const clearForm = () => {
    imageSelectRef.current.value = "";
    setForm({ name: "" });
  };

  const loadBrands = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/brands`;
      const token = localStorage.getItem("authKey");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setBrands(response.data.brands);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    await loadBrands();
  }, []);

  const submitHandler = async (e) => {
    // onClick={submitHandler}

    try {
      if (!formData.name || !formData.image) {
        setAlertData({ message: "Name and Image required", type: "bad" });
        return;
      }
      const url = `${process.env.REACT_APP_BASE_URL}/admin/create_brand_merch`;
      const reqFormData = new FormData();

      reqFormData.append("brand_name", formData.name);
      reqFormData.append("upload", formData.image);

      const token = localStorage.getItem("authKey");

      console.log(reqFormData);

      const response = await axios.post(url, reqFormData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setAlertData({ message: "Brand Added", type: "good" });
        clearForm();
        loadBrands();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setAlertData({ message: error.response.data.error, type: "bad" });
      } else setAlertData({ message: "Unable to Create brand", type: "bad" });
    }
  };

  const clearAlert = () => {
    setAlertData(null);
  };

  const clearDeleteData = () => {
    setDeleteData(null);
  };

  const deleteBrand = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/admin-users/brand/${deleteData._id}`;

      const token = localStorage.getItem("authKey");

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setAlertData({ message: "Brand deleted", type: "good" });

        loadBrands();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setAlertData({ message: error.response.data.error, type: "bad" });
      } else setAlertData({ message: "Unable to delete brand", type: "bad" });
    }

    clearDeleteData();
  };

  return (
    <div className="create-brand w-full">
      <PageHeading heading="Brands" />
      <div id="BrandCreateForm">
        <div className="bg-white m-4">
          <h2 className="font-bold px-4 pt-3">Create Brand</h2>
          <div className="flex flex-row ">
            <div className="px-4 py-3">
              <input
                type="text"
                name="name"
                className="form-input rounded outline-gray-400 px-4 py-3 outline-2 w-96 bg-gray-300"
                placeholder="Enter Brand name"
                value={formData.name}
                onChange={formChanges}
              />
            </div>
            <div className="px-4 py-3">
              <lable
                for="image"
                onClick={() => {
                  imageSelectRef.current.click();
                }}
              >
                {formData && formData.imageUrl ? (
                  <img
                    className="h-24 px-5 shadow border"
                    src={formData.imageUrl}
                    alt="Brand name"
                  />
                ) : (
                  <button className="shadow p-2 bg-violet-400 text-white rounded">
                    Select Image
                  </button>
                )}
              </lable>
              <input
                type="file"
                name="image"
                id="image"
                ref={imageSelectRef}
                className="block form-control w-full h-full w-96 text-base font-normal bg-gray-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none hidden"
                placeholder="Enter Brand name"
                accept="image/*"
                onChange={formChanges}
              />
            </div>
            <div className="px-4 py-3">
              <button
                type="submit"
                onClick={submitHandler}
                className="shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96 bg-violet-500 text-white"
              >
                Add Brand
              </button>{" "}
            </div>
          </div>
        </div>
      </div>

      <table className="text-sm text-left text-gray-500 w-full grow mx-4 inline-block ">
        <thead className="text-xs text-white uppercase bg-gray-700 ">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            {/* <th scope='col' className='px-6 py-3'>
							Created At
						</th> */}
            <th scope="col" className="px-6 py-3">
              Image
            </th>
            {/* <th scope='col' className='px-6 py-3'>
							Actions
						</th> */}
          </tr>
        </thead>
        <tbody>
          {brands &&
            brands.map((referal, index) => (
              <tr
                className={`bg-white border-b hover:bg-gray-200 ${
                  index % 2 === 0 ? "" : "bg-gray-100"
                }`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 "
                >
                  {referal._id}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 "
                >
                  {referal.brand_name}
                </th>
                {/* <td className='px-6 py-4'>{referal.created_at}</td> */}
                <td className="px-6 py-4 h-10 ">
                  {referal.image ? (
                    <img
                      src={referal.image}
                      alt={referal.name}
                      className="h-10"
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                {/* <td className='px-6 py-4 h-10 '>
									<button
										type='submit'
										className='bg-red-600 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/20 h-full px-3 p-2 rounded w-24 text-white'
										onClick={() => loadDeleteData(index)}
									>
										Delete
									</button>
								</td> */}
              </tr>
            ))}
        </tbody>
      </table>

      {deleteData && (
        <Modal
          description={`Do you want to delete ${deleteData.name} ?`}
          onSuccess={deleteBrand}
          onCancel={clearDeleteData}
        />
      )}
      {alertData && (
        <AlertWithClose
          clearAlert={clearAlert}
          message={alertData.message}
          time={4}
          type={alertData.type}
        />
      )}
    </div>
  );
};

export default CreateBrand;
