import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import env from 'react-dotenv';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';


import PageHeading from '../../components/headings/pageHeading';


const InternPanel = () => {
    const navigate = useNavigate();
    const [internData,setInternData] = useState([])

    useEffect(()=>{
        const authkey = localStorage.getItem('authKey');

        if(!authkey) navigate("/login");
    }, [])

    /* eslint no-underscore-dangle: 0 */
    const loadInternInfo = async()=>{
        const url = `${process.env.REACT_APP_BASE_URL}/intern-panel/allInterns`;
        const token = localStorage.getItem('authKey');
        const response = await axios.get(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
        const interns = [...response.data.interns]
        const tempData = [] 
        for(let index=0; index<interns.length; index+=1){
            tempData.push({
                id: index,
                intern_name:interns[index].internName,
                intern_number:interns[index].number,
                stipend:interns[index].stipend,
                paid:interns[index].ProgramInfo.paid,
                target:interns[index].ProgramInfo.target,
                manager:interns[index].ManagerInfo.managerName,
                program_name:interns[index].ProgramInfo.program_name,
                program_id: interns[index].ProgramInfo._id
            })
        }
        setInternData(tempData)
        
    }

    const columns  = [
        { field: 'intern_name', headerName: 'Intern Name', width: 200 },
        { field: 'intern_number', headerName: 'Intern Number', width: 200 },
        { field: 'stipend', headerName: 'Pts/Money Earned', width: 200 },
        { field: 'paid', headerName: 'Paid', width: 200 },
        { field: 'target', headerName: 'Target', width: 200 },
        { field: 'manager', headerName: 'Manager', width: 200 },
        { field: 'program_name', headerName: 'Program Name', width: 200 },
        { field: 'program_id', headerName: 'Program Id', width: 200 }
      ];

    useEffect(async()=>{
        loadInternInfo();
     },[])
    
    return ( 
        <div className="coupons w-full">
            <PageHeading heading="Intern Panel" />
                <div style={{height:"90vh"}} className='m-3'>
                    <DataGrid  rows={internData} columns={columns} className='bg-white w-100'/>
                </div>
            </div>
     );
}
 
export default InternPanel;