import { useEffect, useState } from "react";
import axios from "axios";
import PageHeading from "../../components/headings/pageHeading";
import AlertWithClose from "../../components/alert/alertWithClose";

const BeepsyDummyAccount = () => {
  const [dummyAccounts, set_dummyAccounts] = useState([]);
  const [formData, setForm] = useState(null);
  const [alertData, setAlertData] = useState(null);

  const formChanges = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearForm = () => {
    document.getElementById("DummyAccountForm").reset();
    setForm(null);
  };
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      console.log(formData, e);
      let send_data = formData;
      send_data["account_count"] = parseInt(send_data["account_count"]);
      const url = `${process.env.REACT_APP_BASE_URL2}/admin/dummy`;
      const token = localStorage.getItem("beepsyAuthKey");
      const response = await axios.post(url, send_data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        setAlertData({ message: "Dummy Accounts Added", type: "good" });
        clearForm();
        loadDummyAccounts();
      }
    } catch (error) {
      if (error.name === "AxiosError") {
        setAlertData({ message: error.response.data.error, type: "bad" });
      } else setAlertData({ message: "Unable to delete brand", type: "bad" });
    }
  };

  const clearAlert = () => {
    setAlertData(null);
  };

  const loadDummyAccounts = async () => {
    const url = `${process.env.REACT_APP_BASE_URL2}/admin/dummy`;
    const token = localStorage.getItem("beepsyAuthKey");
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.data) {
      set_dummyAccounts(response.data.accounts);
    }
  };

  useEffect(loadDummyAccounts, []);

  return (
    <div className="create-brand w-full">
      <PageHeading heading="Dummy Accounts" />
      <form onSubmit={submitHandler} id="DummyAccountForm">
        <div className="flex flex-row">
          <div className="px-4 py-3">
            <input
              type="text"
              name="campusId"
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Enter College Id"
              onChange={formChanges}
            />
          </div>
          <div className="px-4 py-3">
            <input
              type="number"
              name="account_count"
              className="form-input rounded outline-slate-400 px-4 py-3 outline-2 w-96 bg-slate-300"
              placeholder="Enter Number of Accounts"
              onChange={formChanges}
            />
          </div>
          <div className="px-4 py-3">
            <button
              type="submit"
              className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50  px-3 p-2 rounded w-96"
            >
              Add Account
            </button>{" "}
          </div>
        </div>
      </form>
      <table className="text-sm text-left text-slate-500 mx-5 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
          <tr>
            <th scope="col" className="px-6 py-3">
              Phone Number
            </th>
            <th scope="col" className="px-6 py-3">
              OTP
            </th>
            <th scope="col" className="px-6 py-3">
              College Id
            </th>
            <th scope="col" className="px-6 py-3">
              College Name
            </th>
          </tr>
        </thead>
        <tbody>
          {dummyAccounts &&
            dummyAccounts.map((account, index) => (
              <tr
                className={`bg-white border-b hover:bg-slate-200 ${
                  index % 2 === 0 ? "" : "bg-slate-100"
                }`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 "
                >
                  {account.number}
                </th>
                <td className="px-6 py-4">{account.otp}</td>
                <td className="px-6 py-4">{account.collegeInfo._id}</td>
                <td className="px-6 py-4">{account.collegeInfo.collegeName}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {alertData && (
        <AlertWithClose
          clearAlert={clearAlert}
          message={alertData.message}
          time={4}
          type={alertData.type}
        />
      )}
    </div>
  );
};

export default BeepsyDummyAccount;
