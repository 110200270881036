import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PageHeading from '../../components/headings/pageHeading';
import TextModal from '../../components/modal/textModal';
import Modal from '../../components/modal/modal'

const GroupMessages = () => {

    const [messages, setMessages] = useState(null);
    const { guid } = useParams();
    const [groupData,setGroupData] =  useState(null);
    const [displayMsg, setDisplayMsg] =  useState(null);
    const [deleteMsg, setDeleteMsg] =  useState(null);
    const [banUser, setBanUser] = useState(null);

    const loadMsgs = async()=>{
        
        try{
            const url = `${process.env.REACT_APP_BASE_URL}/cometchat/groups/messages`;
            const token = localStorage.getItem('authKey');
            const data = JSON.stringify({guid, limit: 300})
            const response = await axios.post(url, data, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
        setMessages(response.data.data)
        setGroupData(response.data.data[0].data.entities.receiver.entity)
        
        console.log(response)

        }
        catch(e){
            console.log(e);
        }
    }

    useEffect(async()=>{
        loadMsgs();

     },[])

     const clearMsg = ()=>{
         setDisplayMsg(null);
     }

     const clearDeleteMsg = ()=>{
        setDeleteMsg(null);
        clearMsg()
    }
    const clearbanUser = ()=>{
        setBanUser(null);
        clearMsg()
    }

    const initiateDeleteMsg= (id, index, sender)=>{
        setDeleteMsg({id, index, sender})
        clearMsg()
    }

    const initiateBanUser = (id, index) =>{
        setBanUser({id, index})
        clearMsg()
    }

    const deleteSelectedMsg = async()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/cometchat/message/${deleteMsg.sender}/${deleteMsg.id}`;
            const token = localStorage.getItem('authKey');
            await axios.delete(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
            loadMsgs()
            clearDeleteMsg();
        }
        catch(e){
            console.log(e);
        }

    }

    const banSelectedUser = async()=>{

        try{
            const url = `${process.env.REACT_APP_BASE_URL}/cometchat/user/${banUser.id}`;
            const token = localStorage.getItem('authKey');
            await axios.delete(url, {headers:{'Content-Type': 'application/json', Authorization: `Bearer ${token}`}});
            
            clearbanUser();
        }
        catch(e){
            console.log(e);
        }

    }




/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
    return (<div>
        <PageHeading heading={groupData ?`${groupData.name} - Group Messages`: 'Group Messages'} />
        <div className="flex flex-wrap">
            {messages && messages.map((msg, index)=>
            ( <div key={msg.id} className="px-2 w-1/5">
            <div className="bg-yellow-400 px-2 py-2 mx-auto my-1 shadow rounded" role='button' onClick={()=>setDisplayMsg(msg.data.text)}>
                <div className="font-bold">{msg.data.entities.sender.entity.name}</div>
                <div className="truncate"> {msg.data.text} </div>
                <button className='bg-slate-900 text-white rounded shadow hover:shadow-2xl hover:cursor-pointer px-1 py-1 mr-1' type='button'onClick={()=>initiateBanUser(msg.sender, index)}>Ban</button>
                <button className='bg-slate-900 text-white rounded shadow hover:shadow-2xl hover:cursor-pointer px-1 py-1 mr-auto' type='button' onClick={()=>initiateDeleteMsg(msg.id, index, msg.sender)}>Delete message</button>
            </div></div>)
            )}
        </div>
        {displayMsg && <TextModal description={displayMsg} onCancel={clearMsg} />}
        {banUser && <Modal description={`Do you want to Ban  ${messages[banUser.index].data.entities.sender.entity.name}?`} onCancel={clearbanUser} onSuccess={banSelectedUser} />}
        {deleteMsg && <Modal description={`Do you want to delete  ${messages[deleteMsg.index].data.text}?`} onCancel={clearDeleteMsg} onSuccess={deleteSelectedMsg} />}
    </div>  );
}
 
export default GroupMessages;