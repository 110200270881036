import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import PageHeading from '../../components/headings/pageHeading';
import AlertWithClose from '../../components/alert/alertWithClose';

/* eslint-disable no-underscore-dangle */
const ChangeUserRole = () => {

    const [formData, setFormData] = useState(null);
    const {userId, role} = useParams();
    const [users, setUsers] =  useState([]);
    const [notificationData, setNotificationData] = useState(null);
    
    const clearAlert= ()=>{
        setNotificationData(null);
        console.group(role)
    }
    console.log(userId, role, "params")

    const loadUser= async()=>{
        try{
            if(!userId){
                setNotificationData({message: `Invalid URL`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/admin-users/user`;
    
            const token = localStorage.getItem('authKey');

            const response = await axios.get(url,{headers: {
                Authorization: `Bearer ${token}`,   
            },
            params:{
               id:userId,
                limit: 200,
            }   
        } )
    
        if(response.status===200){
            setNotificationData({message: 'User list updated', type:'good'});

            
            setUsers(response.data.suggestions);
            
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setNotificationData({message: error.response.data.error, type:'bad'})
                    else setNotificationData({message:'Unable to get users', type:'bad'})
                }
                else
                setNotificationData({message:'Unable to get users', type:'bad'})
            }
     
    }

    const submitHandler= async()=>{
        try{
            if(!userId){
                setNotificationData({message: `Invalid URL`, type:'bad'});
            }
            const url = `${process.env.REACT_APP_BASE_URL}/users/role/${userId}`;
    
            const token = localStorage.getItem('authKey');
            
            const response = await axios.put(url,{role:formData.role==='none'?null:formData.role},{headers: {
                Authorization: `Bearer ${token}`,   
            },
             
        } )
    
        if(response.status===200){
            setNotificationData({message: 'User list updated', type:'good'});

            await loadUser()
            
        }
            }catch(error){
                console.log(error)
                if(error.name ==='AxiosError'){
                    
                    if(error.response.data)
                    setNotificationData({message: error.response.data.error, type:'bad'})
                    else setNotificationData({message:'Unable to get users', type:'bad'})
                }
                else
                setNotificationData({message:'Unable to get users', type:'bad'})
            }
     
    }

    useEffect(async()=>{
        loadUser()
    },[])

    const onFormDataChange=(e)=>{
        const {name, value} = e.target;

        setFormData(prev=>({
            ...prev,
            [name]:value
        }))

        

        
    }

    return ( 
        <div className="change-role w-full">
            <PageHeading heading="Change Role" />

            <div className="flex">

            <div className="flex flex-col">
            <label htmlFor="role" className='px-4 py-2  text-lg'> Role </label>
            
            <div className="flex">
            
            <div className="px-4 ">
                <select name='role' className="block form-control w-full h-full w-96 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none active:outline-none px-4 py-3 focus:bg-slate-300"  onChange={onFormDataChange}>
                <option  disabled selected >Select role</option>
                <option value="none">Remove</option>
                <option value="mentor">Mentor</option>
                <option value="community">Community</option>
                <option value="sub_admin">Sub Admin</option>
                </select>
                </div>
            
            <div className="px-4"><button type="submit" className="bg-yellow-400 shadow-lg hover:shadow focus:shadow-sm shadow-black-400/50 h-full px-3 p-2 rounded w-96 border-4 border-yellow-500" onClick={submitHandler} disabled={!formData || !formData.role} >Change Role</button> </div>
            </div>
            
            </div>
            </div>
            
            
            <div className="user-list">
            {users?(<div className='grid grid-cols-3 items-stretch justify-items-stretch' style={{gridAutoRows: '1fr', gridAutoCols:'1fr'}}>
                {users.map((user)=>(
                    
                    <div className="bg-yellow-400 px-4 py-4 mx-2 my-2 h-fit">
                    

                    <div className="flex">
                    <div className="w-1/6">
                        {user.personalInfo.avatar&&<img className="text-sm text-ellipsis whitespace-nowrap overflow-hidden h-10 w-10" src={user.personalInfo.avatar} alt="userprofile" />}
                    </div>
                    <div className="flex flex-col text-left w-4/6">
                    <div className='font-semibold bottom-0 text-ellipsis whitespace-nowrap overflow-hidden ' title={user.personalInfo.name}>{user.personalInfo.name}</div>
                    {user.created_at && <div className="text-sm text-ellipsis whitespace-nowrap overflow-hidden " >{new Date(user.created_at).toLocaleString()}</div>}
                    </div>
                    {/* <div className='1/6'>{user.blocked?<button type='button' className='bg-green-600 text-white px-2 py-2 rounded shadow-sm' onClick={()=>loadBlockData(index, false)}>Unblock</button>: <button type='button' className='bg-black text-yellow-400 px-2 py-2 rounded shadow-sm' onClick={()=>loadBlockData(index, true)}>Ban</button>}</div> */}
                    </div>
                    
                    
                    
                    <div className="flex pt-1">
                        {user.collegeInfo && <div className="flex flex-col text-left">
                            <div className="text-sm text-ellipsis w-11/12 whitespace-nowrap overflow-hidden font-semibold" title={user.collegeInfo.collegeName}>{user.collegeInfo.collegeName}</div>
                            <div className="text-sm">{`${user.collegeInfo.stream} - ${user.collegeInfo.passout}`}</div>
                            <div className="text-sm">{user._id}</div>
                            
                        </div> }
                    </div>
                    {user.personalInfo && user.personalInfo.role && <div className="text-sm bg-green-600 px-2 py-2 inline-block text-white shadow rounded">{user.personalInfo.role}</div>}
                    </div>
                    
                ))}
                </div>):"No result"}
            </div>
            { notificationData && <AlertWithClose clearAlert={clearAlert} message={notificationData.message} time={4} type={notificationData.type} />}


            
        </div>
     );
}
 
export default ChangeUserRole;